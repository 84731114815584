
interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
    height: number;
    width: number;
    varient: 'red' | 'blue' | 'green' | 'yellow' | 'senior' | 'midlevel' | 'junior'
}

const Badge = (props: BadgeProps) => {

    const badgeColor = {
        'red': '#E57041',
        'blue': '#41BFE5',
        'green': '#41E5A3',
        'yellow': '#E5D141',
        'senior': '#E57041',
        "midlevel": '#84ACE2',
        "junior": '#DF9C28'
    }

    const { height, width, varient } = props
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 8C0 3.58172 3.58172 0 8 0H24V16C24 20.4183 20.4183 24 16 24H8C3.58172 24 0 20.4183 0 16V8Z" fill={badgeColor[varient]} />
            <path d="M12 15C14.7614 15 17 12.7614 17 10C17 7.23858 14.7614 5 12 5C9.23858 5 7 7.23858 7 10C7 12.7614 9.23858 15 12 15Z" stroke="#FFFEF8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="#FFFEF8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 13.9995V18.9998L11.9996 17.4998L9 18.9998V13.9999" stroke="#FFFEF8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default Badge