import { FC } from "react";

type addProps = {
  className?: string
  stroke?: string
}
export const Add: FC<addProps> = ({ className, stroke }) => {
  return (
    <>
      <svg
        className={className}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 8H13.5"
          className={stroke}
          stroke="#67823A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 2.5V13.5"
          className={stroke}
          stroke="#67823A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
}