import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";

import Login from "./pages/Login";
import ComponentList from "./pages/ComponentList";
import AuthGuard from "./guards/Auth";
import Dashboard from "./pages/dashboard/Dashboard";
import Management from "./pages/dashboard/Management";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import SuperAdmin from "./pages/SuperAdmin";
import Otp from "./pages/Otp";
import OnboardPassword from "./pages/Onboard";
import { useUserSettingsStore } from "./store/userSettings.state";
import AntiAuthGuard from "./guards/AntiAuth";
import Estimation from "./pages/dashboard/Estimation";
import Preferences from "./pages/dashboard/Preferences";

function App() {
  const { setEfficiency, setExperience } = useUserSettingsStore();

  useEffect(() => {
    const userSettings = localStorage.getItem("userSettings");

    if (!userSettings) {
      localStorage.setItem(
        "userSettings",
        JSON.stringify({
          experience: true,
          efficiency: true,
        })
      );
    }

    const { experience = true, efficiency = true } = JSON.parse(
      userSettings ?? "{}"
    );

    setExperience(experience);
    setEfficiency(efficiency);
  }, []);

  return (
    <>
      <Routes>
        <Route path="/component" element={<ComponentList />} />
        <Route path="/component/:dateHash" element={<ComponentList />} />
        <Route element={<AntiAuthGuard />}>
          <Route path="/" element={<Login />} />
          <Route path="/onboard" element={<OnboardPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/otp" element={<Otp />} />
        </Route>
        <Route element={<AuthGuard role={"Super_Admin"} />}>
          <Route path="/super-admin" element={<SuperAdmin />} />
        </Route>
        <Route element={<AuthGuard role={"Admin"} />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/:dateHash" element={<Estimation />} />
          <Route path="/dashboard/management" element={<Management />} />
          <Route path="/dashboard/preferences" element={<Preferences />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
