import { FC } from "react";
import Button from "../../Button/index";

interface DeleteShiftProps {
  closeModal: () => void;
  // onSuccess: () => Promise<void>;
  deleteShift: () => Promise<void>;
}

const DeleteShift: FC<DeleteShiftProps> = ({
  // onSuccess,
  closeModal,
  deleteShift,
}) => {
  return (
    <div className="bg-white flex flex-col gap-6 outer-shadow px-6 py-6 mx-auto rounded-xl">
      <div className="font-bold text-sm text-black2 text-center">
        Are you sure you want to delete this shift ?
      </div>
      <div className="flex items-center justify-center gap-8 max-w-md mx-auto w-full">
        <Button
          onClick={closeModal}
          className="flex justify-center px-6 !bg-transparent !border-2 border-secondary text-center !text-secondary !rounded-xl !h-11"
        >
          Cancel
        </Button>
        <Button
          onClick={async () => {
            closeModal();
            await deleteShift();
            // await onSuccess();
          }}
          className="flex justify-center whitespace-nowrap px-6 text-center !rounded-xl !h-11"
        >
          Delete Shift
        </Button>
      </div>
    </div>
  );
};

export default DeleteShift;
