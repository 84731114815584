import axios from "axios"

const API_URL = import.meta.env.VITE_API_URL

export const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.request.use((config) => {
  const token = localStorage.getItem("token")
  if (token) {
    config.headers["Authorization"] = token ?? ""
  }

  return config
})
