import {
  HiChevronRight,
  HiChevronLeft,
  HiOutlineCalendar,
} from "react-icons/hi";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

import Button from "../Button/index";
import Calendar from "../Calendar";
import DateCircle from "./DateCircle";
import { TIME_ZONE } from "../../constants";
import useMonthCalendar from "../../hooks/useMonthCalendar";
interface RoundedCalanderProp {
  showFooter?: boolean;
  initial: {
    selectedDates?: Date[];
    disabledDates?: Date[];
    yellowDates?: Date[];
    month?: number;
    year?: number;
  };
  disableYellowDates?: boolean;
  onChange?: (dates: Date[]) => void;
  onSubmit?: (dates: Date[]) => void;
  onCancel?: () => void;
  onRemove?: (date: Date) => void;
}

interface InnerRoundedCalendarProps {
  showFooter?: boolean;
  initial: {
    selectedDates?: Date[];
    disabledDates?: Date[];
    yellowDates?: Date[];
  };
  disableYellowDates?: boolean;
  onChange?: (dates: Date[]) => void;
  onSubmit?: (dates: Date[]) => void;
  onCancel?: () => void;
  onRemove?: (date: Date) => void;
  month: number;
  year: number;
  calenderBack: () => void;
  calenderNext: () => void;
}

const RoundedCalendar = (props: RoundedCalanderProp) => {
  const { month, year, calenderBack, calenderNext } = useMonthCalendar(
    props.initial.month && props.initial.year
      ? { initialMonth: props.initial.month, initialYear: props.initial.year }
      : {}
  );

  return (
    <InnerRoundedCalendar
      {...props}
      month={month}
      year={year}
      calenderBack={calenderBack}
      calenderNext={calenderNext}
    />
  );
};

export const InnerRoundedCalendar = (props: InnerRoundedCalendarProps) => {
  const {
    showFooter,
    initial,
    onChange,
    onSubmit,
    onCancel,
    onRemove,
    disableYellowDates,
    month,
    year,
    calenderBack,
    calenderNext,
  } = props;

  const [selectedDates, setSelectedDates] = useState<Date[]>(
    initial?.selectedDates ?? []
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disabledDates] = useState<Date[]>(initial?.disabledDates ?? []);
  const [yellowDates] = useState<Date[]>(initial?.yellowDates ?? []);

  const calendarData = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  useEffect(() => {
    onChange?.(selectedDates);
  }, [selectedDates]);

  return (
    <div className="outer-shadow bg-white rounded-lg p-4 max-w-xl mx-auto">
      <div className="flex justify-between items-center w-full pb-4 border-primary">
        <div className="flex gap-3">
          <button
            className="bg-primary border-2 border-primary h-9 w-9 rounded-full"
            onClick={calenderBack}
          >
            <HiChevronLeft size={25} className="text-secondary m-auto" />
          </button>
        </div>
        <div className="flex justify-center items-center gap-x-2">
          <button className="bg-secondary border-2 border-secondary h-9 w-9 rounded-full ">
            <HiOutlineCalendar size={20} className="text text-white m-auto" />
          </button>
          <div className="text-base font-semibold text-black1">
            {DateTime.fromObject({ year, month }, { zone: TIME_ZONE }).toFormat(
              "MMM yyyy"
            )}
          </div>
        </div>
        <div className="flex gap-3">
          <button
            className="bg-primary border-2 border-primary h-9 w-9 rounded-full "
            onClick={calenderNext}
          >
            <HiChevronRight size={25} className="text-secondary m-auto" />
          </button>
        </div>
      </div>
      <div className="grid grid-cols-7">
        {calendarData.map((item, index) => {
          return (
            <div
              className={`col-span-1  font-semibold text-center p-4 bg-green3 ${
                index == 0 ? "rounded-tl-xl" : ""
              }  ${index == calendarData.length - 1 ? "rounded-tr-xl" : ""} `}
              key={index}
            >
              {item}
            </div>
          );
        })}
      </div>
      <Calendar
        month={month}
        year={year}
        className="bg-green3 rounded-b-xl gap-3"
        resetDates={() => {
          setSelectedDates([]);
        }}
        onDayRender={({ day: date, isCtrlPressed }) => {
          const dateTime = DateTime.fromJSDate(date).setZone(TIME_ZONE);

          const day = dateTime.toFormat("d");

          const found = selectedDates.find((item) =>
            dateTime.equals(DateTime.fromJSDate(item).setZone(TIME_ZONE))
          );
          const disabled = disabledDates.find((item) =>
            dateTime.equals(DateTime.fromJSDate(item).setZone(TIME_ZONE))
          );
          const yellow = yellowDates.find((item) =>
            dateTime.equals(DateTime.fromJSDate(item).setZone(TIME_ZONE))
          );

          return (
            <DateCircle
              name={day}
              variant={found ? "filled" : yellow ? "yellow" : "primary"}
              isDisabled={!!disabled}
              key={date.toString()}
              onClick={() => {
                if (disableYellowDates && yellow) {
                  return;
                }

                if (found) {
                  onRemove?.(date);
                  setSelectedDates(
                    selectedDates.filter(
                      (item) =>
                        !dateTime.equals(
                          DateTime.fromJSDate(item).setZone(TIME_ZONE)
                        )
                    )
                  );
                  return;
                }

                if (isCtrlPressed && selectedDates.length > 0) {
                  let modifier = 0;
                  if (
                    selectedDates.find(
                      (date_) => date_.getTime() < date.getTime()
                    )
                  ) {
                    modifier = -1;
                  } else if (
                    selectedDates.find(
                      (date_) => date_.getTime() > date.getTime()
                    )
                  ) {
                    modifier = 1;
                  }
                  const datesToAdd = [date];
                  let dayCount = 1;
                  while (
                    !selectedDates.find((date_) =>
                      DateTime.fromJSDate(date_)
                        .setZone(TIME_ZONE)
                        .equals(
                          DateTime.fromJSDate(date)
                            .setZone(TIME_ZONE)
                            .plus({ day: modifier * dayCount })
                        )
                    )
                  ) {
                    datesToAdd.push(
                      DateTime.fromJSDate(date)
                        .setZone(TIME_ZONE)
                        .plus({ day: modifier * dayCount })
                        .toJSDate()
                    );
                    dayCount++;
                  }
                  setSelectedDates([...selectedDates, ...datesToAdd]);
                  return;
                }

                setSelectedDates([...selectedDates, date]);
              }}
            />
          );
        }}
      />
      {showFooter ? (
        <div className="flex items-center justify-center gap-4 mt-8">
          <Button
            disabled={isLoading}
            className="flex justify-center !bg-transparent !border-2 border-secondary text-center !text-secondary !rounded-xl h-10"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            className="flex justify-center text-center !rounded-xl"
            onClick={() => {
              setIsLoading(true);
              onSubmit?.(selectedDates);
            }}
          >
            {isLoading ? "Processing..." : "Submit"}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default RoundedCalendar;
