import React, { FC } from 'react'
interface editroundI {
    className?: string;
    bgColor?: string;
    textColor?: string;
}
const CalenderX: FC<editroundI> = ({ bgColor = "#D1DBAF", className, textColor = "#67823A" }) => {
    return (
        <svg width="32" height="32" className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill={bgColor} />
            <path d="M21 10.5H11C10.7239 10.5 10.5 10.7239 10.5 11V21C10.5 21.2761 10.7239 21.5 11 21.5H21C21.2761 21.5 21.5 21.2761 21.5 21V11C21.5 10.7239 21.2761 10.5 21 10.5Z" stroke={textColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19 9.5V11.5" stroke={textColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 9.5V11.5" stroke={textColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.5 13.5H21.5" stroke={textColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.5 16L14.5 19" stroke={textColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.5 19L14.5 16" stroke={textColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default CalenderX