import { FC } from "react";
interface monitorProps {
  className?: string;
  fill?: string;
}
const Monitor: FC<monitorProps> = ({ className, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
    >
      <path
        d="M15.25 0.125H2.75C2.25272 0.125 1.77581 0.322544 1.42417 0.674175C1.07254 1.02581 0.875 1.50272 0.875 2V10.75C0.875 11.2473 1.07254 11.7242 1.42417 12.0758C1.77581 12.4275 2.25272 12.625 2.75 12.625H15.25C15.7473 12.625 16.2242 12.4275 16.5758 12.0758C16.9275 11.7242 17.125 11.2473 17.125 10.75V2C17.125 1.50272 16.9275 1.02581 16.5758 0.674175C16.2242 0.322544 15.7473 0.125 15.25 0.125ZM15.875 10.75C15.875 10.9158 15.8092 11.0747 15.6919 11.1919C15.5747 11.3092 15.4158 11.375 15.25 11.375H2.75C2.58424 11.375 2.42527 11.3092 2.30806 11.1919C2.19085 11.0747 2.125 10.9158 2.125 10.75V2C2.125 1.83424 2.19085 1.67527 2.30806 1.55806C2.42527 1.44085 2.58424 1.375 2.75 1.375H15.25C15.4158 1.375 15.5747 1.44085 15.6919 1.55806C15.8092 1.67527 15.875 1.83424 15.875 2V10.75ZM12.125 14.5C12.125 14.6658 12.0592 14.8247 11.9419 14.9419C11.8247 15.0592 11.6658 15.125 11.5 15.125H6.5C6.33424 15.125 6.17527 15.0592 6.05806 14.9419C5.94085 14.8247 5.875 14.6658 5.875 14.5C5.875 14.3342 5.94085 14.1753 6.05806 14.0581C6.17527 13.9408 6.33424 13.875 6.5 13.875H11.5C11.6658 13.875 11.8247 13.9408 11.9419 14.0581C12.0592 14.1753 12.125 14.3342 12.125 14.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default Monitor;
