const RoundedCancel = ({ active }: { active: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className="group/cancel"
    >
      <path
        d="M15.8333 7.99998C15.8333 4.31808 12.8485 1.33331 9.16667 1.33331C5.48477 1.33331 2.5 4.31808 2.5 7.99998C2.5 11.6818 5.48477 14.6666 9.16667 14.6666C12.8485 14.6666 15.8333 11.6818 15.8333 7.99998Z"
        fill={active ? "#FE7862" : "#E0E0E0"}
        className={!active ? "group-hover/cancel:fill-[#FE7862]" : ""}
      />
      <path
        d="M11.1637 10L7.16406 6M7.16449 10L11.1641 6"
        stroke={active ? "#FFFFFF" : "#4F4F4F"}
        className={!active ? "group-hover/cancel:stroke-[#FFFFFF]" : ""}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RoundedCancel;
