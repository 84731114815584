import Slider from "react-slick";

const ComponentList = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    centerPadding: "0px",
    centerMode: true,
    swipe: false,
    variableWidth: false,
  };

  return (
    <div className="m-5">
      <Slider {...settings}>
        <div className="h-30 w-30 text-center bg-green-400">
          <h3 className="text-black">1</h3>
        </div>
        <div className="h-30 w-30 text-center bg-green-400">
          <h3 className="text-black">2</h3>
        </div>
      </Slider>
    </div>
  );
};

export default ComponentList;
