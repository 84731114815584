import { create } from "zustand"

interface Options {
  blur?: boolean
}

// TODO : Replace with Library

interface ModalState {
  useOutsideClick?: boolean
  blur?: boolean
  openModal: (modal: JSX.Element, options?: Options) => void
  modalsJsx: JSX.Element[]
  closeModal: () => void
  setOutsideClick: (value: boolean) => void
}

export const useModal = create<ModalState>((set) => ({
  useOutsideClick: true,
  blur: false,
  openModal: (modal, options) =>
    set((state) => ({
      modalsJsx: [...state.modalsJsx, modal],
      blur: options?.blur ?? false,
    })),
  modalsJsx: [],
  closeModal: () =>
    set((state) => ({
      modalsJsx: state.modalsJsx.slice(0, -1),
    })),
  setOutsideClick: (value) =>
    set({
      useOutsideClick: value,
    }),
}))
