import React from 'react'

const Colon = ({ className }: { className?: string }) => {
    return (
        <svg className={className} width="5" height="31" viewBox="0 0 5 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.323 16.2971C1.57154 16.2971 0.958503 15.6907 0.958503 14.9392C0.958503 14.1943 1.57813 13.5747 2.323 13.5747C3.06129 13.5747 3.68751 14.2009 3.68751 14.9392C3.68751 15.6841 3.06788 16.2971 2.323 16.2971Z" fill="#2C5339" />
            <path d="M2.323 27.2385C1.57154 27.2385 0.958503 26.6321 0.958503 25.8806C0.958503 25.1357 1.57813 24.5161 2.323 24.5161C3.06129 24.5161 3.68751 25.1423 3.68751 25.8806C3.68751 26.6255 3.06788 27.2385 2.323 27.2385Z" fill="#2C5339" />
        </svg>

    )
}

export default Colon