import { HiOutlineClock, HiOutlineTrash } from "react-icons/hi";
import Efficiency from "../Icons/Efficiency";
import Experience from "../Icons/Experience";
import { BsTag, BsTagFill } from "react-icons/bs";
import { useState } from "react";

interface DoctorShiftCardProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  icon?: React.ReactNode;
  image: string;
  disabled?: boolean;
  variant: "time" | "experience" | "group";
  selected?: boolean;
  time?: {
    fromTime: string;
    toTime: string;
  };
  showDeleteIcon?: boolean;
  onRemove?: () => void;
  experienceLevel?: number;
  efficiencyScore?: number;
  onClick?: () => void;
  tags?: any;
}

const DoctorCardVar = (props: DoctorShiftCardProps) => {
  const {
    name,
    icon,
    image,
    selected,
    time,
    variant = "time",
    experienceLevel,
    efficiencyScore,
    onRemove,
    disabled,
    showDeleteIcon = false,
    onClick,
    tags,
    ...otherProps
  } = props;

  const [showTags, setShowTags] = useState(false);

  function showTagsList() {
    setShowTags(!showTags);
  }

  return (
    <div
      className={`relative outer-shadow p-4  rounded-xl  ${
        onClick ? "cursor-pointer" : ""
      } `}
      onClick={onClick}
    >
      <div className="flex items-center gap-3">
        <div className="relative  w-16">
          <div className="w-full">
            <img src={image} alt="" className="h-12 w-12 rounded-full" />
          </div>
          {selected && (
            <svg
              width="32"
              height="32"
              className="absolute shadow-xl  top-0 bottom-0 right-0 left-0 m-auto"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="32" height="32" rx="16" fill="#67823A" />
              <path
                d="M21.5 12.5005L14.5 19.5002L11 16.0005"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
        <div className="w-full">
          <div className="font-semibold text-sm text-black1">{name}</div>
          <div className="flex justify-between w-full flex-wrap items-center mt-2 pr-4">
            {variant === "time" ? (
              <div className="flex items-center">
                <div className="">
                  <HiOutlineClock className="h-5 w-5 text-black2" />
                </div>
                <div className="text-xs text-black2 font-semibold ml-1">
                  {time?.fromTime} - {time?.toTime}
                </div>
              </div>
            ) : null}

            {variant === "experience" ? (
              <div className="flex items-center">
                <Efficiency height={16} width={17} />
                <div className="text-xs text-black2 font-semibold ml-2 ">
                  {efficiencyScore}/ 10
                </div>
              </div>
            ) : null}

            {variant === "experience" ? (
              <div className="flex items-center">
                <Experience height={16} width={18} />
                <div className="text-xs text-black2 font-semibold ml-2">
                  {experienceLevel}
                </div>
              </div>
            ) : null}

            {tags ? (
              <div
                className="flex items-center space-x-2 uppercase cursor-pointer"
                onClick={() => showTagsList()}
              >
                <BsTag className="rotate-90" />
                <div className="text-xs text-black2 font-semibold bg-primary w-5 h-5 flex items-center justify-center rounded-full">
                  {tags.length}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="absolute top-0 right-0">{icon ? icon : null}</div>
        {showDeleteIcon ? (
          <div
            onClick={() => {
              if (disabled) return;

              onRemove?.();
            }}
            className="absolute bottom-0 right-0 cursor-pointer p-1 bg-red-500 rounded-l-lg rounded-tr-lg"
          >
            <HiOutlineTrash className="text-white" />
          </div>
        ) : null}
      </div>
      {showTags && (
        <div className="flex justify-between  w-full border-t-2 pt-4  flex-wrap items-center mt-2">
          <ul className="w-full text-sm font-semibold text-gray-900 bg-white border border-primary rounded-lg mr-4">
            {tags &&
              tags.map((item: any) => (
                <li className="w-full px-3 divide-x-2 divide-primary flex items-center uppercase space-x-2 py-2 border-b border-primary rounded-t-lg ">
                  <BsTagFill className="rotate-90 mr-1 fill-secondary" />
                  <div className="pl-3">{item.value}</div>
                </li>
              ))}
            {tags.length == 0 && (
              <li className="w-full px-4 flex items-center justify-center uppercase gap-x-2 py-2 border-b border-gray-200 rounded-t-lg ">
                No Tags Assigned
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DoctorCardVar;
