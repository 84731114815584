import {
  API_DATE_FORMAT,
  API_TIME_FORMAT,
  DISPLAY_DATE_FORMAT,
  DISPLAY_DATE_FORMAT_LONG,
  TIME_ZONE,
} from "../constants";
import { DateTime } from "luxon";

export const formatTime = (date: string) => {
  const dateTime = DateTime.fromISO(date).setZone(TIME_ZONE);

  if (dateTime.minute === 0) {
    return dateTime.toFormat("ha");
  }

  return dateTime.toFormat("h:mma");
};

export const formatTimeWSpace = (date: string) => {
  const dateTime = DateTime.fromISO(date).setZone(TIME_ZONE);

  if (dateTime.minute === 0) {
    return dateTime.toFormat("h a");
  }

  return DateTime.fromISO(date).setZone(TIME_ZONE).toFormat("h:mm a");
};

export const formatDateLong = (date: string) => {
  return DateTime.fromISO(date)
    .setZone(TIME_ZONE)
    .toFormat(DISPLAY_DATE_FORMAT_LONG);
};

export const formatTimeAPI = (date: string) => {
  return DateTime.fromISO(date).setZone(TIME_ZONE).toFormat(API_TIME_FORMAT);
};

export const formatDateAPI = (date: Date) => {
  return DateTime.fromJSDate(date).setZone(TIME_ZONE).toFormat(API_DATE_FORMAT);
};

export const formatDisplayDate = (date: Date | string) => {
  if (typeof date === "string") {
    return DateTime.fromISO(date)
      .setZone(TIME_ZONE)
      .toFormat(DISPLAY_DATE_FORMAT);
  }
  return DateTime.fromJSDate(date)
    .setZone(TIME_ZONE)
    .toFormat(DISPLAY_DATE_FORMAT);
};

export const addDay = (date: Date, days: number) => {
  return DateTime.fromJSDate(date).setZone(TIME_ZONE).plus({ days }).toJSDate();
};
