export default function AddCancel({
  type,
  stroke,
}: {
  type: "add" | "cancel";
  stroke: string;
}) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={`transition-transform ${
          type === "cancel" ? "rotate-45" : ""
        }`}
      >
        <g id={`${type === "add" ? "Add" : "Remove"} Icon Big`}>
          <path
            id="Vector"
            d="M12 18.75V5.25M5.25 12H18.75"
            stroke={stroke}
            strokeWidth="1.09195"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </>
  );
}
