export const getRedirectUrl = (role: string) => {
  switch (role) {
    case "Super_Admin":
      return "/super-admin";
    case "Admin":
      return "/dashboard";
    default:
      return "/";
  }
};
