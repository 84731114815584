const UploadFile = () => {
  return (
    <svg
      width="138"
      height={108}
      viewBox="0 0 138 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M66.9759 75C84.3348 75 98.479 60.9142 98.479 43.6137C98.4206 26.2548 84.3348 12.1689 66.9759 12.1689C49.5585 12.1689 35.4727 26.2548 35.4727 43.5552C35.4727 60.9142 49.5585 75 66.9759 75Z"
          fill="#D1DBAF"
        />
        <g filter="url(#filter0_d_4639_93210)">
          <path
            d="M115.267 65.4444C115.267 70.5172 111.165 74.6196 106.048 74.6196C105.96 74.6196 105.21 74.6196 99.696 74.6196C95.8583 74.6196 89.7268 74.6196 79.9341 74.6196H75.2583C69.1268 74.7519 64.2305 69.8556 64.2305 63.9446C64.2305 57.9896 69.1709 53.0492 75.3906 53.3579C80.7281 36.6839 105.166 39.0218 107.239 56.2693C111.827 56.8427 115.267 60.7245 115.267 65.4444Z"
            fill="url(#paint0_linear_4639_93210)"
          />
        </g>
        <path
          d="M106.045 74.6194C111.118 74.6194 115.265 70.517 115.265 65.4442C115.265 60.3714 111.118 56.269 106.045 56.269C100.973 56.269 96.8262 60.3714 96.8262 65.4442C96.8262 70.517 100.973 74.6194 106.045 74.6194Z"
          fill="url(#paint1_linear_4639_93210)"
        />
        <path
          d="M91.0032 74.7076C100.046 74.7076 107.413 67.3852 107.413 58.3423C107.413 49.2995 100.046 41.9771 91.0032 41.9771C81.9603 41.9771 74.5938 49.2995 74.5938 58.3423C74.5938 67.3852 81.9162 74.7076 91.0032 74.7076Z"
          fill="url(#paint2_linear_4639_93210)"
        />
        <g filter="url(#filter1_d_4639_93210)">
          <path
            d="M66.4763 31.1148C66.4763 35.4629 62.96 38.9792 58.5741 38.9792C58.4984 38.9792 57.8557 38.9792 53.1295 38.9792C49.84 38.9792 44.5845 38.9792 36.1907 38.9792H32.1829C26.9273 39.0926 22.7305 34.8957 22.7305 29.8292C22.7305 24.7249 26.9652 20.4902 32.2963 20.7549C36.8713 6.46284 57.8179 8.46675 59.5949 23.2503C63.5271 23.7419 66.4763 27.0691 66.4763 31.1148Z"
            fill="url(#paint3_linear_4639_93210)"
          />
        </g>
        <path
          d="M58.5741 38.9793C62.9222 38.9793 66.4763 35.463 66.4763 31.1149C66.4763 26.7668 62.9222 23.2505 58.5741 23.2505C54.226 23.2505 50.6719 26.7668 50.6719 31.1149C50.6719 35.463 54.226 38.9793 58.5741 38.9793Z"
          fill="url(#paint4_linear_4639_93210)"
        />
        <path
          d="M45.6824 39.0548C53.4334 39.0548 59.7476 32.7784 59.7476 25.0274C59.7476 17.2764 53.4334 11 45.6824 11C37.9314 11 31.6172 17.2764 31.6172 25.0274C31.6172 32.7784 37.8936 39.0548 45.6824 39.0548Z"
          fill="url(#paint5_linear_4639_93210)"
        />
        <g filter="url(#filter2_d_4639_93210)">
          <path
            d="M93.3255 32.3389C93.3255 35.0565 91.1278 37.2542 88.3866 37.2542C88.3394 37.2542 87.9376 37.2542 84.9837 37.2542C82.9278 37.2542 79.6431 37.2542 74.397 37.2542H71.8921C68.6074 37.3251 65.9844 34.702 65.9844 31.5355C65.9844 28.3453 68.6311 25.6986 71.963 25.864C74.8224 16.9315 87.914 18.1839 89.0247 27.4237C91.4823 27.7309 93.3255 29.8104 93.3255 32.3389Z"
            fill="url(#paint6_linear_4639_93210)"
          />
        </g>
        <path
          d="M88.3862 37.2543C91.1037 37.2543 93.325 35.0567 93.325 32.3391C93.325 29.6215 91.1037 27.4238 88.3862 27.4238C85.6686 27.4238 83.4473 29.6215 83.4473 32.3391C83.4473 35.0567 85.6686 37.2543 88.3862 37.2543Z"
          fill="url(#paint7_linear_4639_93210)"
        />
        <path
          d="M80.7908 37.5342C85.6351 37.5342 89.5815 33.6115 89.5815 28.7671C89.5815 23.9228 85.6351 20 80.7908 20C75.9464 20 72 23.9228 72 28.7671C72 33.6115 75.9228 37.5342 80.7908 37.5342Z"
          fill="url(#paint8_linear_4639_93210)"
        />
        <path
          d="M99.2381 32.6257C100.562 32.6257 101.634 31.5528 101.634 30.2294C101.634 28.9059 100.562 27.833 99.2381 27.833C97.9147 27.833 96.8418 28.9059 96.8418 30.2294C96.8418 31.5528 97.9147 32.6257 99.2381 32.6257Z"
          fill="#D1DBAF"
        />
        <path
          d="M102.746 23.274C103.65 23.274 104.382 22.5413 104.382 21.6375C104.382 20.7337 103.65 20.001 102.746 20.001C101.842 20.001 101.109 20.7337 101.109 21.6375C101.109 22.5413 101.842 23.274 102.746 23.274Z"
          fill="#D1DBAF"
        />
        <path
          d="M32.7834 64.6547C34.462 64.6547 35.8227 63.294 35.8227 61.6154C35.8227 59.9369 34.462 58.5762 32.7834 58.5762C31.1049 58.5762 29.7441 59.9369 29.7441 61.6154C29.7441 63.294 31.1049 64.6547 32.7834 64.6547Z"
          fill="#D1DBAF"
        />
        <g filter="url(#filter3_d_4639_93210)">
          <path
            d="M84.1202 32.301L84.5279 65.1219C84.5279 66.2432 83.6106 67.1095 82.4894 67.1095H50.4839C49.3627 67.1095 48.4453 66.1922 48.4453 65.1219V22.9236C48.4453 21.8024 49.3627 20.936 50.4839 20.936H73.6726L84.1202 32.301Z"
            fill="url(#paint9_linear_4639_93210)"
          />
        </g>
        <path
          d="M63.7522 60.0957H63.0964C62.9539 60.0957 62.8398 59.9629 62.8398 59.8035C62.8398 59.6441 62.9539 59.5112 63.0964 59.5112H63.7522C63.8947 59.5112 64.0088 59.6441 64.0088 59.8035C64.0088 59.9894 63.8947 60.0957 63.7522 60.0957Z"
          fill="#D1DBAF"
        />
        <path
          d="M60.0867 60.0957H53.9036C53.6729 60.0957 53.4883 59.9629 53.4883 59.8035C53.4883 59.6441 53.6729 59.5112 53.9036 59.5112H60.0867C60.3174 59.5112 60.502 59.6441 60.502 59.8035C60.502 59.9894 60.3174 60.0957 60.0867 60.0957Z"
          fill="#D1DBAF"
        />
        <path
          d="M69.203 27.9497H54.1389C53.7886 27.9497 53.4883 27.68 53.4883 27.3652C53.4883 27.0505 53.7886 26.7808 54.1389 26.7808H69.203C69.5533 26.7808 69.8536 27.0505 69.8536 27.3652C69.8536 27.68 69.5533 27.9497 69.203 27.9497Z"
          fill="#D1DBAF"
        />
        <path
          d="M59.9051 31.4565H54.1349C53.7867 31.4565 53.4883 31.1868 53.4883 30.8721C53.4883 30.5574 53.7867 30.2876 54.1349 30.2876H59.8553C60.2035 30.2876 60.502 30.5574 60.502 30.8721C60.502 31.1868 60.2035 31.4565 59.9051 31.4565Z"
          fill="#D1DBAF"
        />
        <path
          d="M73.7988 20.936V30.2667C73.7988 31.5771 74.9279 32.6255 76.2109 32.6255L84.1013 32.3333"
          fill="#D1DBAF"
        />
        <path
          d="M61.2437 37.6019C62.7221 36.6542 64.4659 36.1235 66.3233 36.1235C71.4029 36.1235 75.5348 40.2175 75.5348 45.2971C75.5348 50.3766 71.4029 54.4706 66.3233 54.4706C61.2437 54.4706 57.1119 50.3766 57.1119 45.2971C57.036 42.075 58.704 39.2698 61.2437 37.6019Z"
          fill="#D1DBAF"
        />
        <path
          d="M66.9861 41.1046L69.621 43.9846C69.931 44.3234 69.931 44.9164 69.621 45.2552C69.311 45.594 68.7686 45.594 68.4198 45.2552L67.2186 43.9846V48.6435C67.2186 49.1517 66.8311 49.5752 66.3661 49.5752C65.9011 49.5752 65.5136 49.1517 65.5136 48.6435V43.9846L64.3123 45.2552C64.0023 45.594 63.4598 45.594 63.1111 45.2552C62.9173 45.0434 62.8398 44.8317 62.8398 44.6199C62.8398 44.4081 62.9173 44.1117 63.1111 43.9846L65.7461 41.1046C65.8623 40.9775 66.1336 40.8081 66.3273 40.8081C66.5211 40.8081 66.8698 40.8928 66.9861 41.1046Z"
          fill="#FFFEF8"
        />
        <g filter="url(#filter4_d_4639_93210)">
          <path
            d="M57.0814 51.6265C57.0814 54.3441 54.8837 56.5418 52.1425 56.5418C52.0952 56.5418 51.6935 56.5418 48.7396 56.5418C46.6837 56.5418 43.399 56.5418 38.1529 56.5418H35.648C32.3633 56.6127 29.7402 53.9896 29.7402 50.8231C29.7402 47.6329 32.3869 44.9862 35.7189 45.1516C38.5783 36.2191 51.6699 37.4715 52.7805 46.7113C55.2381 47.0185 57.0814 49.098 57.0814 51.6265Z"
            fill="url(#paint10_linear_4639_93210)"
          />
        </g>
        <path
          d="M52.144 56.5415C54.8615 56.5415 57.0829 54.3438 57.0829 51.6262C57.0829 48.9086 54.8615 46.7109 52.144 46.7109C49.4264 46.7109 47.2051 48.9086 47.2051 51.6262C47.2051 54.3438 49.4264 56.5415 52.144 56.5415Z"
          fill="url(#paint11_linear_4639_93210)"
        />
        <path
          d="M44.0857 56.5889C48.93 56.5889 52.8764 52.6662 52.8764 47.8218C52.8764 42.9774 48.93 39.0547 44.0857 39.0547C39.2413 39.0547 35.2949 42.9774 35.2949 47.8218C35.2949 52.6662 39.2177 56.5889 44.0857 56.5889Z"
          fill="url(#paint12_linear_4639_93210)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4639_93210"
          x="42.2305"
          y="31.0171"
          width="95.0371"
          height="76.605"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.399108 0 0 0 0 0.495833 0 0 0 0 0.0702431 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4639_93210"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4639_93210"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_4639_93210"
          x="0.730469"
          y="0.0341797"
          width="87.7461"
          height="71.9473"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.399108 0 0 0 0 0.495833 0 0 0 0 0.0702431 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4639_93210"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4639_93210"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_4639_93210"
          x="43.9844"
          y="8.78857"
          width="71.3418"
          height="61.4668"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.399108 0 0 0 0 0.495833 0 0 0 0 0.0702431 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4639_93210"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4639_93210"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_4639_93210"
          x="26.4453"
          y="9.93604"
          width="80.082"
          height="90.1733"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.399108 0 0 0 0 0.495833 0 0 0 0 0.0702431 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4639_93210"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4639_93210"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_4639_93210"
          x="7.74023"
          y="28.0762"
          width="71.3418"
          height="61.4668"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.399108 0 0 0 0 0.495833 0 0 0 0 0.0702431 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4639_93210"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4639_93210"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_4639_93210"
          x1="89.7489"
          y1="55.4908"
          x2="89.7322"
          y2="74.9737"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.9964" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4639_93210"
          x1="95.8451"
          y1="54.2936"
          x2="104.018"
          y2="63.2322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D1DBAF" />
          <stop offset="0.9942" stopColor="#D1DBAF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4639_93210"
          x1="90.9925"
          y1="41.22"
          x2="90.9925"
          y2="75.0605"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCFFF3" />
          <stop offset="0.9964" stopColor="#ECF0F5" />
          <stop offset="0.9965" stopColor="#FBFFEC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4639_93210"
          x1="44.6034"
          y1="22.5831"
          x2="44.5891"
          y2="39.2827"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.9964" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4639_93210"
          x1="49.8309"
          y1="21.5573"
          x2="56.8362"
          y2="29.2189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCCBE1" />
          <stop offset="0.9942" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_4639_93210"
          x1="30.3001"
          y1="18.5906"
          x2="41.341"
          y2="22.9112"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2E8F0" />
          <stop offset="0.9942" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_4639_93210"
          x1="79.6549"
          y1="27.0066"
          x2="79.646"
          y2="37.4439"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.9964" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_4639_93210"
          x1="82.9217"
          y1="26.3656"
          x2="87.3"
          y2="31.1541"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCCBE1" />
          <stop offset="0.9942" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_4639_93210"
          x1="71.1768"
          y1="24.7441"
          x2="78.0774"
          y2="27.4445"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2E8F0" />
          <stop offset="0.9942" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_4639_93210"
          x1="66.4749"
          y1="19.868"
          x2="66.4749"
          y2="67.6073"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCFFF3" />
          <stop offset="0.9964" stopColor="#ECF0F5" />
          <stop offset="0.9965" stopColor="#FBFFEC" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_4639_93210"
          x1="43.4108"
          y1="46.2942"
          x2="43.4019"
          y2="56.7315"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.9964" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_4639_93210"
          x1="46.6795"
          y1="45.6527"
          x2="51.0578"
          y2="50.4412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCCBE1" />
          <stop offset="0.9942" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_4639_93210"
          x1="34.4718"
          y1="43.7988"
          x2="41.3723"
          y2="46.4992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2E8F0" />
          <stop offset="0.9942" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default UploadFile
