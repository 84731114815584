import React from "react";

const RoundedSeniorityTag = ({
  className,
  fill = "white",
  stroke = "junior",
  onClick,
}: {
  className?: string;
  fill?: "white" | "senior" | "midlevel" | "junior";
  stroke: "senior" | "midlevel" | "junior";
  onClick?: () => void;
}) => {
  const iconColor = {
    senior: "stroke-[#E57041] ",
    midlevel: "stroke-[#84ACE2] ",
    junior: "stroke-[#DF9C28] ",
  };
  const fillColor = {
    white: "fill-white",
    senior: "fill-[#E57041] ",
    midlevel: "fill-[#84ACE2] ",
    junior: "fill-[#DF9C28] ",
  };
  return (
    <svg
      className={className}
      onClick={onClick ? () => onClick() : undefined}
      width="48"
      height="46"
      viewBox="0 0 48 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="45"
        rx="11.5"
        className={fillColor[fill]}
        fill="white"
      />
      <rect x="0.5" y="0.5" width="47" height="45" rx="11.5" stroke="#E0E0E0" />
      <path
        d="M13 20C13 15.5817 16.5817 12 21 12H27C31.4183 12 35 15.5817 35 20V26C35 30.4183 31.4183 34 27 34H21C16.5817 34 13 30.4183 13 26V20Z"
        fill="white"
      />
      <path
        d="M24 26C26.7614 26 29 23.7614 29 21C29 18.2386 26.7614 16 24 16C21.2386 16 19 18.2386 19 21C19 23.7614 21.2386 26 24 26Z"
        className={iconColor[stroke]}
        stroke="#E57041"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 24C25.6569 24 27 22.6569 27 21C27 19.3431 25.6569 18 24 18C22.3431 18 21 19.3431 21 21C21 22.6569 22.3431 24 24 24Z"
        className={iconColor[stroke]}
        stroke="#E57041"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27 25V30.0003L23.9996 28.5003L21 30.0003V25.0004"
        className={iconColor[stroke]}
        stroke="#E57041"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RoundedSeniorityTag;
