import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import NavTab from "../NavTabs";
import { useSeniority } from "../../store/seniority.state";
import { useEstimationContext } from "../../hooks/context/useEstimationContext";
import { useRunOnceExt } from "../../hooks/runOnce";
import { useLocation } from "../../store/location.store";
import { InfinitySpin } from "react-loader-spinner";

export const TopBar = ({
  type = "general",
  globalLoading,
}: {
  type?: "estimation" | "general";
  globalLoading: boolean;
}) => {
  const navigate = useNavigate();
  const { seniorities, activeId, setActiveId } = useSeniority();
  const { setDoctorFilterSeniority } = useEstimationContext();
  const { activeId: activeLocationId } = useLocation();
  const { resetStates } = useEstimationContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { dateHash } = useParams<{ dateHash: string }>();

  useRunOnceExt(() => {
    if (searchParams.get("seniority")) {
      setActiveId(Number(searchParams.get("seniority")));
      setDoctorFilterSeniority(Number(searchParams.get("seniority")));
    } else {
      setSearchParams({
        locationId: activeLocationId,
        seniority: activeId.toString(),
      });
    }
  });

  return (
    <div className="w-full bg-white items-center flex justify-between px-10">
      <div
        className="flex items-center bg-gray2 border-gray cursor-pointer rounded h-[25px] w-[25px]"
        onClick={() => {
          navigate(
            `/dashboard?locationId=${searchParams.get(
              "locationId"
            )}&seniority=${searchParams.get("seniority")}&dateHash=${
              dateHash ?? ""
            }`
          );
        }}
      >
        <IoChevronBackOutline size={20} className="text-black mx-auto" />
      </div>
      <NavTab
        tabs={seniorities}
        tabType="neumorphism"
        activeId={activeId}
        onChange={(id) => {
          setSearchParams({
            locationId: activeLocationId,
            seniority: id.toString(),
          });
          if (type === "estimation") {
            resetStates(id);
          }
          setActiveId(id);
        }}
      />
      {globalLoading ? (
        <div className=" text-secondary flex text-base font-medium items-center gap-2">
          <InfinitySpin width="100" color="#67823A" />
          Syncing...
        </div>
      ) : (
        <div className=" text-secondary flex text-base font-medium items-center gap-2">
          Synced.
        </div>
      )}
    </div>
  );
};
