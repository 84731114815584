import { useState } from "react"
import { useModal } from "../../hooks/useModal"
import Button from "../Button/index"
import Typography from "../Typography/index"

interface DeleteModalProps {
  message: string
  onConfirm: (closeModal: () => void) => void
  confirmTxt?: string
  cancelTxt?: string
  isLoading?: boolean
}

const ConfirmationModal = (props: DeleteModalProps) => {
  const {
    message,
    onConfirm,
    confirmTxt = "Confirm",
    cancelTxt = "Close",
  } = props
  const { closeModal } = useModal()
  const [isLoading, setLoading] = useState<boolean>(false)

  const close = () => {
    setLoading(false)
    closeModal()
  }

  return (
    <div className="outer-shadow bg-white z-50 w-full max-w-sm px-8 py-6 mx-auto rounded-xl">
      <Typography
        tag="div"
        className="font-semibold text-base text-black3 mb-8"
      >
        {message}
      </Typography>
      <div className="flex items-center justify-center gap-8">
        <Button
          disabled={isLoading}
          className="flex justify-center !bg-transparent !border-2 border-secondary text-center !text-secondary !rounded-xl h-10"
          onClick={() => closeModal()}
        >
          {cancelTxt}
        </Button>
        <Button
          disabled={isLoading}
          className="flex justify-center text-center !rounded-xl"
          onClick={() => {
            setLoading(true)
            onConfirm?.(close)
          }}
        >
          {confirmTxt}
        </Button>
      </div>
    </div>
  )
}

export default ConfirmationModal
