import React, { FC } from "react";

type OuterWindowProps = {
  className?: string;
  headerClassName?: string;
  titleClassName?: string;
  title?: string;
  headerChildNode?: React.ReactNode;
  bodyClass?: string;
  bodyChildNode?: React.ReactNode;
};

const OuterWindow: FC<OuterWindowProps> = ({
  bodyChildNode,
  bodyClass,
  headerChildNode,
  titleClassName,
  title,
  className,
  headerClassName,
}) => {
  return (
    <div className={className}>
      <div
        className={`rounded-t-xl flex justify-center items-center ${headerClassName}`}
      >
        <div className={`${titleClassName} my-[14px] font-medium text-sm`}>{title}</div>
        {headerChildNode}
      </div>
      <div
        className={`${bodyClass} pt-6 px-4 pb-4 bg-gray6 rounded-b-xl overflow-y-scroll small-scrollbar`}
      >
        {bodyChildNode}
      </div>
    </div>
  );
};

export default OuterWindow;
