import auth from "./authApi"
import location from "./locationApiNonRtk"
import doctor from "./doctorApiNonRtk"
import leaveQuota from "./leaveQuotaApiNonRtk"
import group from "./groupApiNonRtk"
import rules from "./rulesApi"

const api = {
  auth,
  location,
  doctor,
  group,
  leaveQuota,
  rules,
}

export default api
