import React, { FC } from "react";
type EditUnderlineProps = {
  className?: string;
  fill?: string;
  stroke?: string;
};
const EditUnderline: FC<EditUnderlineProps> = (props) => {
  const { className, fill = "white", stroke = "#838383" } = props;
  return (
    <svg
      className={className}
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.486328" width="18.5132" height="18" rx="4" fill={fill} />
      <path
        d="M9.74293 14H14.9995M12.3712 4.36289C12.6036 4.13054 12.9187 4 13.2473 4C13.41 4 13.5712 4.03205 13.7215 4.09431C13.8718 4.15658 14.0084 4.24784 14.1234 4.36289C14.2385 4.47794 14.3298 4.61453 14.392 4.76485C14.4543 4.91517 14.4863 5.07629 14.4863 5.23899C14.4863 5.4017 14.4543 5.56281 14.392 5.71314C14.3298 5.86346 14.2385 6.00004 14.1234 6.11509L6.8226 13.4159L4.48633 14L5.0704 11.6637L12.3712 4.36289Z"
        stroke={stroke}
        strokeWidth="1.54321"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditUnderline;
