import { InfinitySpin } from "react-loader-spinner"

const GuacLoader = () => {
  return (
    <div className="h-screen absolute w-screen top-0 left-0 flex flex-col justify-center items-center">
      <div className="flex items-center justify-center flex-col gap-y-3">
        <svg
          width={64}
          height={64}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2791_48071)">
            <path
              d="M0.107002 49.0043C0.0368561 48.3718 0 47.731 0 47.0795V39.8153C3.172 39.5692 6.34876 39.3302 9.52076 39.2743C12.002 39.2315 14.4702 39.4277 16.9015 39.9377C18.9928 40.3764 21.2826 40.9043 23.0886 42.0956C24.7982 43.2191 25.6709 45.0001 26.144 46.9344C22.0078 49.292 17.2439 50.0268 12.5394 50.1671C8.34255 50.2895 4.18852 49.8912 0.10819 49.0031L0.107002 49.0043Z"
              fill="#67823A"
            />
            <path
              d="M26.7302 51.6972C26.9608 55.7966 26.9478 59.9007 26.7207 64H16.9205C9.84654 64 3.78549 59.6617 1.26263 53.496C5.18008 54.2022 9.17124 54.4971 13.1481 54.3306C17.8122 54.1345 22.4977 53.3783 26.7124 51.3619C26.7183 51.4725 26.7242 51.5866 26.7302 51.696V51.6972Z"
              fill="#67823A"
            />
            <path
              d="M38.9188 15.2465C38.498 10.177 38.3446 5.08733 38.4302 0H47.0759C48.8842 0 50.6248 0.28296 52.2584 0.810834C52.3939 5.20741 52.4367 9.61349 51.9944 13.9946C51.5771 18.1451 50.8222 24.0314 45.5719 24.4308C43.5972 24.5818 41.639 23.6081 40.4751 22.015C39.0995 20.1294 39.1031 17.4769 38.9188 15.2477V15.2465Z"
              fill="#67823A"
            />
            <path
              d="M64 16.9229V35.087C58.5691 35.0133 53.1406 35.213 47.7334 35.7231C43.432 36.1285 39.3088 37.2401 37.1319 41.3418C35.1215 45.1273 35.7813 49.7878 38.6977 52.923C41.8626 56.3244 47.1437 56.7453 51.4916 57.1198C54.4912 57.3742 57.5027 57.417 60.5118 57.3683C57.4194 61.3999 52.5509 64 47.076 64H30.9104C31.1648 59.4893 31.1862 54.9489 30.8367 50.4512C30.5085 46.2663 29.5681 41.8174 26.0751 39.1067C22.2219 36.1166 16.4129 35.2998 11.6751 35.112C7.80995 34.9586 3.88894 35.2808 -0.00115967 35.6089V25.6399C2.27441 27.2212 5.05288 28.2496 7.37719 28.636C12.2898 29.4492 17.3188 29.2958 22.3515 28.1913C24.5034 27.7182 26.4377 26.3771 27.5803 24.4237C28.6883 22.5262 28.9523 20.0176 28.8334 17.8562C28.5266 12.4371 21.1947 12.8854 17.326 13.1434C14.6521 13.3241 14.6307 17.509 17.326 17.3283C19.101 17.2118 20.8844 17.2082 22.6558 17.3961C23.0791 17.4424 24.2644 17.4234 24.5937 17.792C24.7685 17.9881 24.6425 18.9738 24.6211 19.3982C24.5533 20.8261 24.3203 22.3574 23.0957 23.2669C21.8711 24.1788 20.2186 24.5045 18.7515 24.778C14.9077 25.4937 11.7607 25.1989 8.21418 24.52C5.79951 24.0551 0.995144 22.2599 2.92381e-05 19.6312V16.9229C2.92381e-05 7.5769 7.57455 0 16.9205 0H34.2465C34.1514 5.6152 34.3475 11.234 34.873 16.8278C35.2784 21.1292 36.3924 25.2524 40.4918 27.4293C44.2773 29.4373 48.9378 28.7798 52.0729 25.8635C55.4744 22.6986 55.8953 17.4139 56.2698 13.0696C56.5587 9.67413 56.5765 6.2691 56.5004 2.86407C61.023 5.90054 64.0012 11.0651 64.0012 16.9229H64Z"
              fill="#67823A"
            />
            <path
              d="M64 39.2719V47.0795C64 49.2136 63.6041 51.2549 62.8824 53.1346C58.7748 53.2487 54.66 53.257 50.5678 52.8457C46.4173 52.4284 40.531 51.6734 40.1315 46.4232C39.9805 44.4484 40.9519 42.4903 42.5474 41.3264C44.433 39.9508 47.0855 39.9544 49.3111 39.7701C54.1987 39.3647 59.0993 39.2054 63.9988 39.2731L64 39.2719Z"
              fill="#67823A"
            />
          </g>
          <defs>
            <clipPath id="clip0_2791_48071">
              <rect width="64" height={64} fill="white" />
            </clipPath>
          </defs>
        </svg>
        <InfinitySpin width="62%" color="#67823A" />
      </div>
    </div>
  )
}

export default GuacLoader
