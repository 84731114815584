const SlotPill = ({
  label,
  isActive,
  type = "full",
  onClick,
}: {
  label: { label: string; colorCode: string; key: string };
  type?: "full" | "outline";
  isActive: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      key={label.key}
      className={`rounded-md text-xs font-bold ${
        isActive
          ? type === "full"
            ? "text-white p-1"
            : `border-2 p-0.5`
          : "text-gray3 p-1"
      }`}
      onClick={onClick}
      style={{
        backgroundColor: isActive
          ? type === "full"
            ? label.colorCode
            : "white"
          : "#E8EDF1",
        color: isActive
          ? type === "full"
            ? "white"
            : label.colorCode
          : "#A0ABBB",
        ...(isActive && type === "outline"
          ? { borderColor: label.colorCode }
          : {}),
      }}
    >
      {label.label}
    </div>
  );
};

export default SlotPill;
