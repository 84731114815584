import { useEffect, useState } from "react";
import UserManagementTab from "../../components/ManagementTabItems/UserManagementTab";
import NavTab, { INavTab } from "../../components/NavTabs/index";
import LeaveManagementTabItem from "../../components/ManagementTabItems/LeaveManagementTabItem";
import RulesManagementTab from "../../components/ManagementTabItems/RulesManagementTab";
import GroupManagementTab from "../../components/ManagementTabItems/GroupManagementTab";
import { useSeniority } from "../../store/seniority.state";
import ManagementLayout from "../../layouts/Management";
import { TopBarManagement } from "../../components/NavBar/TopBarManagement";

const Management = () => {
  const { seniorities, setDisabled } = useSeniority();

  useEffect(() => {
    for (const seniority of seniorities) {
      setDisabled(seniority.id, false);
    }
  }, []);

  const managementTab: INavTab[] = [
    {
      id: 0,
      icon: null,
      label: ({ isActive }) => (
        <div
          className={`font-medium ${
            isActive ? "text-white" : "text-black3"
          } font-base`}
        >
          User Management
        </div>
      ),
      children: <UserManagementTab />,
    },
    {
      id: 1,
      icon: null,
      label: ({ isActive }) => (
        <div
          className={`font-medium ${
            isActive ? "text-white" : "text-black3"
          } font-base`}
        >
          Group Management
        </div>
      ),
      children: <GroupManagementTab />,
    },
    {
      id: 2,
      icon: null,
      label: ({ isActive }) => (
        <div
          className={`font-medium ${
            isActive ? "text-white" : "text-black3"
          } font-base`}
        >
          Leave Management
        </div>
      ),
      children: <LeaveManagementTabItem />,
    },
    {
      id: 3,
      icon: null,
      label: ({ isActive }) => (
        <div
          className={`font-medium ${
            isActive ? "text-white" : "text-black3"
          } font-base`}
        >
          Rules Management
        </div>
      ),
      children: <RulesManagementTab />,
    },
  ];

  const [activeManagementTab, setActiveManagementTab] = useState(
    managementTab[0].id ?? 0,
  );

  const handleManagementActiveTab = (tabId: INavTab["id"]) => {
    setActiveManagementTab(tabId);
  };

  return (
    <ManagementLayout>
      <div className="mx-auto flex flex-col w-full items-center">
        <TopBarManagement />
        <div className="w-full px-20 relative z-[5] bg-white">
          <NavTab
            tabs={managementTab}
            tabType={"flat"}
            activeId={activeManagementTab}
            onChange={handleManagementActiveTab}
          />
        </div>
        <div className="w-full px-20 py-4 flex-grow overflow-auto relative z-[5] bg-white">
          {managementTab[activeManagementTab].children}
        </div>
      </div>
    </ManagementLayout>
  );
};

export default Management;
