export const sortRequests = (
  requestA: {
    status: "completed" | "rejected" | "pending";
  },
  requestB: {
    status: "completed" | "rejected" | "pending";
  },
) => {
  if (requestA.status === "completed") {
    return -1;
  }
  if (requestB.status === "completed") {
    return 1;
  }
  if (requestA.status === "pending" && requestB.status === "rejected") {
    return -1;
  }
  if (requestA.status === "rejected" && requestB.status === "pending") {
    return 1;
  }
  return -1;
};
