import { motion } from "framer-motion";
import { FC, PropsWithChildren } from "react";
import DropDownCarrot from "../Icons/DropDownCarrot";

type ShiftDropdownProps = {
  shifts: {
    from: { value: string; label: string };
    to: { value: string; label: string };
  }[];
  activeShiftIndex: number | undefined;
  setActiveShiftIndex: (index: number) => void;
  type: "am" | "pm";
};

const ShiftDropdown: FC<PropsWithChildren<ShiftDropdownProps>> = ({
  shifts,
  type,
  activeShiftIndex,
  setActiveShiftIndex,
}: ShiftDropdownProps) => {
  const getShiftClass = (index: number) =>
    `text-[10px] font-medium text-black bg-white rounded ${
      type === "am" ? "hover:bg-green2" : "hover:bg-blue2"
    } text-center transition-colors py-1 ${
      activeShiftIndex === index
        ? type === "am"
          ? "!bg-green2"
          : "!bg-blue2"
        : ""
    }`;

  return (
    <motion.div
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -50, opacity: 0 }}
      transition={{ type: "interia" }}
      key="shift-dropdown"
      className={`relative w-[100%] ${
        type === "am" ? "bg-green4" : "bg-blue1"
      } grid grid-cols-2 gap-1 p-2 rounded-lg mt-3`}
    >
      {shifts.map((shift, index) => (
        <div
          key={index}
          className={getShiftClass(index)}
          onClick={() => {
            setActiveShiftIndex(index);
          }}
        >{`${shift.from.label} - ${shift.to.label}`}</div>
      ))}
      <div
        className={`absolute -top-2 ${
          type === "am" ? "left-7" : "right-24 2xl:right-40"
        }`}
      >
        <DropDownCarrot type={type} />
      </div>
    </motion.div>
  );
};

export default ShiftDropdown;
