export const formatFileSize = (bytes: number) => {
  const kb = Math.round(bytes / 1024);

  if (kb < 1024) {
    return `${kb} KB`;
  } else if (kb >= 1024) {
    const mb = Math.round(kb / 1024);
    return `${mb} MB`;
  }
};
