import React, { FC } from "react"

interface Props extends React.ButtonHTMLAttributes<HTMLElement> {
  tag: keyof HTMLElementTagNameMap
}

const Typography: FC<Props> = (props) => {
  const { tag, children } = props

  const tagClasses: Partial<Record<typeof tag, typeof props.className>> = {
    h1: "text-5xl font-semibold",
    h2: "text-4xl font-semibold",
    h3: "text-3xl font-semibold",
    h4: "text-2xl font-semibold",
    h5: "text-xl font-semibold",
    h6: "text-lg font-semibold",
    p: "text-[#191D23CC] font-normal",
  }

  return React.createElement(
    tag,
    {
      ...props,
      style: {
        lineHeight: "normal",
      },
      className: `${tagClasses[tag] ?? ""} ${props.className ?? ""}`,
    },
    children
  )
}

export default Typography
