import { ReactNode } from "react";

interface CardWithIconProps extends React.HTMLAttributes<HTMLDivElement> {
  name: React.ReactNode
  icon: { position: string; content: ReactNode }
  className?: string
}
const CardWithIcon = (props: CardWithIconProps) => {
  const { name, icon, className, ...otherProps } = props

  return (
    <div {...otherProps} className={`py-2  ${className}`}>
      {name}
      {icon ? icon.content : null}
    </div>
  )
}

export default CardWithIcon
