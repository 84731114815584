import { FC } from "react";
import Button from "../Button/index";
import api from "../../store/api";
import { MutatingDots } from "react-loader-spinner";

interface DeleteGroupSubGroupProps {
  group: any;
  subGroup: any;
  closeModal: () => void;
  onSuccess?: (isGroup: boolean) => void;
}

const DeleteGroupSubGroup: FC<DeleteGroupSubGroupProps> = (props) => {
  const { onSuccess, closeModal, group, subGroup } = props;

  const { fetchFn: deleteGroup, isLoading } = api.group.useDeleteGroup();
  const { fetchFn: deleteSubGroup, isLoading: isLoadingDeleteSubGroup } =
    api.group.useDeleteSubGroup();

  return (
    <div className="bg-white flex flex-col gap-6 outer-shadow px-6 py-6 mx-auto rounded-xl">
      <div className="text-xl uppercase text-black1 font-semibold text-center ">
        {subGroup ? `Sub Group - ${subGroup.title}` : `Group - ${group.title}`}
      </div>
      <div className="font-bold text-sm text-black2 text-center">
        Are you sure you want to delete this {subGroup ? `Sub Group` : `Group`}
      </div>
      <div className="flex items-center justify-center gap-8 max-w-md mx-auto w-full">
        <Button
          onClick={closeModal}
          disabled={isLoading || isLoadingDeleteSubGroup}
          className="flex justify-center px-6 !bg-transparent !border-2 border-secondary text-center !text-secondary !rounded-xl !h-11"
        >
          Cancel
        </Button>
        <Button
          onClick={async () => {
            if (subGroup) {
              await deleteSubGroup({ data: { subGroupId: subGroup.id } });
              onSuccess?.(false);
            } else {
              await deleteGroup({ data: { groupId: group.id } });
              onSuccess?.(true);
            }
          }}
          className="flex justify-center whitespace-nowrap px-6 text-center !rounded-xl !h-11"
          disabled={isLoading || isLoadingDeleteSubGroup}
        >
          {isLoading ? (
            <MutatingDots />
          ) : (
            `Delete ${subGroup ? `Sub Group` : `Group`}`
          )}
        </Button>
      </div>
    </div>
  );
};

export default DeleteGroupSubGroup;
