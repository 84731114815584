import { FC, PropsWithChildren } from "react";
import guacbackground from "../assets/images/GuacBackground.jpg";

const DefaultLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <div
        className="flex h-screen flex-row w-full bg-no-repeat bg-cover"
        style={{
          backgroundImage: `url(${guacbackground})`,
        }}
      >
        <div
          className={`flex flex-col w-full  items-center !p-8 justify-center`}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;
