import ResetPasswordComponent from '../components/ResetPassword/index'
import Default from '../layouts/Default'

const ResetPassword = () => {
    return (
        <Default>
            <ResetPasswordComponent />
        </Default>
    )
}

export default ResetPassword