import { useEffect, useState } from "react";

/**
 * @description To only be used for internal states, non dom manipulation and non external comms
 * @param value
 * @param callback
 */
export const useRunOnce = (callback: () => any) => {
  const [ran, setRan] = useState(false);
  if (!ran) {
    callback();
    setRan(true);
  }
};

export const useRunOnceExt = (callback: () => any) => {
  useEffect(() => {
    callback();
  }, []);
};
