import Login from "../components/Login"
import Default from "../layouts/Default"

const LoginPage = () => {
  return (
    <>
      <Default>
        <Login />
      </Default>
    </>
  )
}

export default LoginPage
