export default function DropDownCarrot({ type }: { type: "am" | "pm" }) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="12"
        viewBox="0 0 18 12"
        fill="none"
      >
        <path
          id="Polygon 2"
          d="M9 0L17.6603 11.25H0.339746L9 0Z"
          fill={`${type === 'am' ? '#3C4B35' : '#1B4B77'}`}
        />
      </svg>
    </>
  );
}
