import { useEffect, useState } from "react"
import Button from "../Button/index"
import Input from "../Input/index"
import { GrClose } from "react-icons/gr"
import LeaveQuotaCard from "../Cards/LeaveQuotaCard"
import api from "../../store/api"
import { useFormik } from "formik"
import { z } from "zod"
import { toast } from "react-toastify"
import { useModal } from "../../hooks/useModal"
import ConfirmationModal from "./Confirmation"
import Typography from "../Typography"

interface LeaveQuotaModalProps {
  subGroupId: string
}

const LeaveQuotaModal = (props: LeaveQuotaModalProps) => {
  const { subGroupId } = props

  const [editingQuotaId, setEditingQuotaId] = useState<string>("")

  const { fetchFn: createLeaveQuota, isLoading: isCreateLeaveQuotaLoading } =
    api.leaveQuota.useCreateLeaveQuota()

  const { fetchFn: updateQuota, isLoading: isUpdateQuotaLoading } =
    api.leaveQuota.useUpdateLeaveQuota()

  const {
    fetchFn: getLeaveQuotaBySubGroupId,
    data: quotas,
    isLoading: isQuotaLoading,
  } = api.leaveQuota.useGetLeaveQuotaBySubGroupId()

  useEffect(() => {
    getLeaveQuotaBySubGroupId({
      data: {
        subGroupId,
      },
    })
  }, [])

  const formik = useFormik<{
    label: string
    leaveDays: number
    subGroups: string[]
  }>({
    initialValues: {
      label: "",
      leaveDays: 0,
      subGroups: [subGroupId],
    },
    validate: (values) => {
      const schema = z.object({
        label: z.string().nonempty("Name Of Quota is required"),
        leaveDays: z.number().nonnegative("Number Of Leave is required").min(1),
      })

      const result = schema.safeParse(values)

      if (!result.success) {
        return Object.fromEntries(
          result.error.errors.map((err) => [err.path[0], err.message])
        )
      }

      return {}
    },
    onSubmit: async (values) => {
      if (editingQuotaId) {
        const { label, leaveDays } = values

        const updateQuotaRes = await updateQuota({
          data: {
            leaveQuotaId: editingQuotaId,
            leaveDays,
          },
        })

        const { isSuccess, isError, error } = updateQuotaRes.unwrap()

        if (isSuccess) {
          toast.success("Leave Quota updated successfully")
          getLeaveQuotaBySubGroupId({
            data: {
              subGroupId,
            },
          })
        }

        if (isError) {
          toast.error(error?.response?.data?.message)
        }

        return
      }

      const createQuota = await createLeaveQuota({
        data: {
          ...values,
          subGroups: [subGroupId],
        },
      })

      const { isSuccess, isError, error } = createQuota.unwrap()

      if (isSuccess) {
        toast.success("Leave Quota created successfully")

        getLeaveQuotaBySubGroupId({
          data: {
            subGroupId,
          },
        })
      }

      if (isError) {
        toast.error(error?.response?.data?.message)
      }

      formik.resetForm()
    },
  })

  const { openModal, closeModal } = useModal()

  const { fetchFn: deleteQuota, isLoading: isQuotaDeleteLoading } =
    api.leaveQuota.DeleteLeaveQuota()

  // const formik = useFormik()

  return (
    <div className="flex bg-white flex-col gap-6 outer-shadow w-screen max-w-screen-2xl px-8 py-6 mx-auto h-[80vh] rounded-xl">
      <div className="flex items-center justify-between">
        <div className="text-base text-black1 font-semibold">
          Add New User In Sub Group
        </div>

        <Button
          className="ml-auto !w-fit px-0 h-fit bg-transparent "
          onClick={closeModal}
        >
          <GrClose className="text-black2" size={25} />
        </Button>
      </div>
      <div className="grid grid-cols-3 gap-x-4 h-full">
        <div className="col-span-1 h-full">
          <form className="h-full " onSubmit={formik.handleSubmit}>
            <div className="flex flex-col justify-between  w-full outer-shadow max-w-md h-full px-8 py-6 mr-auto rounded-xl">
              <div className="space-y-4">
                <div className="space-y-3">
                  <div className="text-base text-black3 font-semibold">
                    Name Of Quota
                  </div>
                  <Input
                    type="text"
                    name="label"
                    disabled={!!editingQuotaId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errorMsg={formik.touched.label ? formik.errors.label : ""}
                    value={formik.values.label}
                    placeholder="Name Of Quota"
                    className="!text-sm  placeholder:text-sm !border-2 !h-11  !rounded-2xl !placeholder:text-sm"
                  />
                </div>
                <div className="space-y-3">
                  <div className="text-base text-black3 font-semibold">
                    Number Of Leave
                  </div>
                  <Input
                    type="number"
                    name="leaveDays"
                    defaultValue={1}
                    onChange={(e) => {
                      formik.handleChange(e)
                      formik.setFieldValue("leaveDays", Number(e.target.value))
                    }}
                    onBlur={formik.handleBlur}
                    errorMsg={formik.touched.leaveDays ? formik.errors.leaveDays : ""}
                    value={formik.values.leaveDays}
                    placeholder="Number Of Leave"
                    className="!text-sm  placeholder:text-sm !border-2 !h-11 !rounded-2xl !placeholder:text-sm"
                  />
                </div>
              </div>
              <div className="flex items-end   justify-center gap-2 ">
                {editingQuotaId ? (
                  <Button
                    className="flex whitespace-nowrap px-3 justify-center !bg-transparent !border-2 border-secondary text-center !text-secondary !rounded-xl h-10"
                    onClick={() => {
                      setEditingQuotaId("")
                      formik.resetForm()
                    }}
                  >
                    Cancel Editing
                  </Button>
                ) : null}
                <Button
                  type="submit"
                  disabled={
                    isCreateLeaveQuotaLoading ||
                    isQuotaLoading ||
                    isQuotaDeleteLoading ||
                    isUpdateQuotaLoading
                  }
                  className="flex justify-center text-center !rounded-xl !h-11 px-3"
                >
                  {isCreateLeaveQuotaLoading
                    ? "Creating..."
                    : !isUpdateQuotaLoading
                      ? "Submit"
                      : null}
                  {isUpdateQuotaLoading ? "Updating..." : null}
                </Button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-span-2 h-full">
          <div className="rounded-lg outer-shadow p-5 h-full">
            <div className="text-base text-black3 font-semibold">
              Name Of Quota
            </div>
            <div className="grid grid-cols-2 overflow-auto max-h-[60vh] pr-4 gap-4 mt-4">
              {!isQuotaLoading ? (
                <>
                  {/* // <div className="col-span-1"> */}
                  {quotas?.quotas?.map((quota: any) => (
                    <LeaveQuotaCard
                      className="col-span-1"
                      key={quota.id}
                      quota={quota}
                      onEdit={() => {
                        setEditingQuotaId(quota._id)
                        formik.setFieldValue("label", quota.label)
                        formik.setFieldValue("leaveDays", quota.leaveDays)
                      }}
                      onRemove={() => {
                        openModal(
                          <ConfirmationModal
                            message={`Do you want to remove leave quota ${quota.label}?`}
                            onConfirm={async (close) => {
                              const delQuota = await deleteQuota({
                                data: {
                                  leaveQuotaId: quota._id,
                                },
                              })

                              const { isSuccess, isError, error } =
                                delQuota.unwrap()

                              if (isSuccess) {
                                toast.success(
                                  "Leave Quota deleted successfully"
                                )
                                getLeaveQuotaBySubGroupId({
                                  data: {
                                    subGroupId,
                                  },
                                })
                              }

                              if (isError) {
                                toast.error(error?.response?.data?.message)
                              }

                              close()
                            }}
                          />
                        )
                      }}
                    />
                  ))}
                  {/* // </div> */}
                </>
              ) : (
                <div className="flex justify-center items-center h-full">
                  Loading...
                </div>
              )}
              {!isQuotaLoading && !quotas?.quotas?.length ? (
                <Typography tag="p" className="font-semibold">
                  No Quota Found
                </Typography>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeaveQuotaModal
