import { motion } from "framer-motion";
import { useSeniority } from "../../../store/seniority.state";
import DoctorInfoWindowDetails from "../../Estimation/UserDataWindowComponents/DoctorInfoWindowDetails";

export const borderColors = {
  "3": "border-[#DF9C28]",
  "2": "border-[#84ACE2]",
  "1": "border-[#E57041]",
};

const DROPDOWN_LENGTH = 220;

const UserProfile = ({
  email,
  experience,
  efficiency,
  phoneNumber,
  groups,
  subGroups,
  tags,
  type,
}: {
  email: string;
  experience: number;
  efficiency: number;
  phoneNumber?: string;
  groups: any[];
  subGroups: any[];
  tags: any[];
  type: "top" | "bottom";
}) => {
  const { activeId: activeSeniorityId } = useSeniority();
  return (
    <motion.div
      key="user-profile-card"
      initial={{ y: type === "top" ? -1 * DROPDOWN_LENGTH : DROPDOWN_LENGTH }}
      animate={{ y: 0 }}
      exit={{ y: type === "top" ? -1 * DROPDOWN_LENGTH : DROPDOWN_LENGTH }}
      transition={{ type: "interia", duration: 0.4 }}
      className={`col-span-2 row-span-3 h-full w-full flex justify-center items-center shadow-1 rounded-xl group bg-white border-l-[4px] ${
        borderColors[activeSeniorityId.toString() as "1" | "2" | "3"]
      } font-bold relative p-4 z-[2]`}
    >
      <DoctorInfoWindowDetails
        type="user-management"
        email={email}
        phone={phoneNumber ?? "No Phone Number Added"}
        experience={experience}
        effeciency={efficiency}
        groups={groups}
        subGroups={subGroups}
        tags={tags}
      />
    </motion.div>
  );
};

export default UserProfile;
