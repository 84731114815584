import React, { FC } from "react";
interface pillProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  name: string;
  children?: React.ReactNode;
  className: string;
  isActive?: boolean;
}
const TagS: FC<pillProps> = (props) => {
  const { name, children, className, isActive, ...nativeprops } = props;
  return (
    <button
      {...nativeprops}
      className={` rounded-full px-9 py-2  font-semibold ${className} ${
        isActive ? "bg-secondary text-white" : "bg-[#83838314] text-black2"
      }`}
    >
      {name}
      {children}
    </button>
  );
};

export default TagS;
