import { useState } from "react";
import Button from "../Button/index";
import Input from "../Input/index";
import Typography from "../Typography/index";
import { useModal } from "../../hooks/useModal";
import api from "../../store/api";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { z } from "zod";
import TagInput from "../TagInput/TagInput";
import NavTab from "../NavTabs";
import { useSeniority } from "../../store/seniority.state";
import Slider from "rc-slider";
import { useUserSettingsStore } from "../../store/userSettings.state";
import { doctorApi } from "../../store/doctorApi";

interface EditModalProps {
  onSubmit?: (doctor: any) => void;
  doctor: any;
}

export interface EditDoctorForm {
  doctorId: string;
  name: string;
  nickName?: string;
  phoneNumber?: string;
  experience: number;
  seniority: number;
  efficiencyScore: number;
  tags: string[];
}

const EditDoctor = (props: EditModalProps) => {
  const { onSubmit, doctor } = props;
  const { seniorities } = useSeniority();
  const [selectedSeniority, setSelectedSeniority] = useState<number>(
    doctor.seniority.id
  );
  const settings = useUserSettingsStore();

  const { closeModal } = useModal();

  const formik = useFormik<EditDoctorForm>({
    initialValues: {
      doctorId: doctor._id,
      name: doctor.user.name,
      nickName: doctor.user?.nickName ?? "",
      phoneNumber: doctor.user?.phoneNumber ?? "",
      experience: doctor.experience,
      seniority: doctor.seniority.id,
      efficiencyScore: doctor.efficiencyScore,
      tags: doctor.tags ?? [],
    },
    validate: (values) => {
      const schema = z.object({
        doctorId: z.string().nonempty("Doctor id is required"),
        name: z.string().nonempty("Name is required"),
        nickName: z.string().nonempty("Nick Name is required"),
        phoneNumber: z.string().optional(),
        experience: z
          .number({
            required_error: "Experience is required",
            invalid_type_error: "Experience must be a number",
          })
          .min(0)
          .max(100),
        seniority: z.number({ required_error: "Seniority is required" }),
        efficiencyScore: z
          .number({
            required_error: "Efficiency score is required",
            invalid_type_error: "Efficiency score must be a number",
          })
          .min(0)
          .max(10),
        tags: z.array(z.string()).default([]),
      });

      const result = schema.safeParse(values);
      if (!result.success) {
        return Object.fromEntries(
          result.error.errors.map((err) => [err.path[0], err.message])
        );
      }
      return {};
    },
    onSubmit: async (values) => {
      const addDoc = await updateDoctor({
        // only send values that have changed
        data: {
          ...Object.fromEntries(
            Object.entries(values).filter(([key, value]) => {
              if (!(key === "phoneNumber" && value === "")) {
                return [key, value];
              }
            })
          ),
        },
      });
      const { isSuccess, data, isError, error } = addDoc.unwrap();
      if (isSuccess) {
        toast.success("Updated doctor successfully");
        onSubmit?.(data);
        doctorApi.util.invalidateTags(["Doctor"]);
        closeModal();
      }

      if (isError) {
        toast.error(error?.response?.data?.message ?? "Something went wrong");
      }
    },
  });

  const { fetchFn: updateDoctor, isLoading } = api.doctor.useUpdateDoctor();

  return (
    <form
      onSubmit={formik.handleSubmit}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
        if (e.key === "Escape") {
          closeModal();
        }
      }}
      className="outer-shadow bg-white w-full max-w-2xl px-8 py-6 mx-auto rounded-xl"
    >
      <div className="col-span-1 grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <Typography
            tag="div"
            className="font-semibold text-base text-black3 mb-2"
          >
            Doctor Name
          </Typography>
          <Input
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            onBlur={formik.handleBlur}
            type="text"
            placeholder="Name"
            className="!text-base !placeholder:text-sm"
            errorMsg={formik.touched.name ? formik.errors.name : ""}
          />
        </div>
        <div className="col-span-1">
          <Typography
            tag="div"
            className="font-semibold text-base text-black3 mb-2"
          >
            Nick Name
          </Typography>
          <Input
            name="nickName"
            onChange={formik.handleChange}
            value={formik.values.nickName}
            onBlur={formik.handleBlur}
            type="text"
            placeholder="Nick Name"
            className="!text-base !placeholder:text-sm"
            errorMsg={formik.touched.nickName ? formik.errors.nickName : ""}
          />
        </div>
        <div className="col-span-1">
          <Typography
            tag="div"
            className="font-semibold text-base text-black3 mb-2"
          >
            Phone Number
          </Typography>
          <Input
            name="phoneNumber"
            onChange={formik.handleChange}
            value={formik.values.phoneNumber}
            onBlur={formik.handleBlur}
            errorMsg={
              formik.touched.phoneNumber ? formik.errors.phoneNumber : ""
            }
            type="tel"
            placeholder=""
          />
        </div>
        {settings.experience ? (
          <div className="col-span-1">
            <Typography
              tag="div"
              className="font-semibold text-base text-black3 mb-2"
            >
              Experience ({formik.values.experience} years)
            </Typography>
            <Slider
              min={0}
              max={100}
              styles={{
                track: {
                  background: "#67823A",
                },
                handle: {
                  background: "#67823A",
                },
              }}
              value={formik.values.experience}
              onChange={(val) => {
                formik.setFieldValue("experience", val);
              }}
            />
          </div>
        ) : null}
        {settings.efficiency ? (
          <div className="col-span-1">
            <Typography
              tag="div"
              className="font-semibold text-base text-black3 mb-2"
            >
              Efficiency Score ({formik.values.efficiencyScore} out of 10)
            </Typography>
            <Slider
              min={0}
              max={10}
              styles={{
                track: {
                  background: "#67823A",
                },
                handle: {
                  background: "#67823A",
                },
              }}
              value={formik.values.efficiencyScore}
              onChange={(val) => {
                formik.setFieldValue("efficiencyScore", val);
              }}
            />
          </div>
        ) : null}
      </div>

      <div className="col-span-2">
        <NavTab
          tabs={seniorities}
          tabType="neumorphism"
          activeId={selectedSeniority}
          onChange={(id) => {
            setSelectedSeniority(id);
            formik.setFieldValue("seniority", id);
          }}
        />
      </div>

      <div className="col-span-2 mb-8">
        <Typography
          tag="div"
          className="font-semibold text-base text-black3 mb-2"
        >
          Tags
        </Typography>
        <TagInput
          onChange={(tags) => formik.setFieldValue("tags", tags)}
          initialTags={doctor.tags}
        />
      </div>

      <div className="flex items-center justify-center gap-8">
        <Button
          disabled={isLoading}
          className="flex justify-center !bg-transparent !border-2 border-secondary text-center !text-secondary !rounded-xl h-10"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          type="submit"
          className="flex justify-center text-center !rounded-xl"
        >
          {isLoading ? "Saving..." : "Save"}
        </Button>
      </div>
    </form>
  );
};

export default EditDoctor;
