import { create } from "zustand"

export interface UserSettingsState {
  experience: boolean
  setExperience: (experience: boolean) => void
  efficiency: boolean
  setEfficiency: (efficiency: boolean) => void
}

export const useUserSettingsStore = create<UserSettingsState>((set) => ({
  experience: false,
  setExperience: (experience) => set({ experience }),
  efficiency: false,
  setEfficiency: (efficiency) => set({ efficiency }),
}))
