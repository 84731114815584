import { motion } from "framer-motion";
import EditTwo from "../Icons/EditTwo";
import { useEstimationContext } from "../../hooks/context/useEstimationContext";

type TimeBarProps = {
  type: "inFocus" | "dayBefore" | "dayAfter";
  shiftType: "am" | "pm" | undefined;
  setShiftType: (shiftType: "am" | "pm" | undefined) => void;
  selectedShift: { from: string; to: string } | undefined;
  setSeletectedShiftIndex: (selectedShiftIndex: number | undefined) => void;
  date: Date;
};

const TimeBar = ({
  type,
  shiftType,
  setShiftType,
  setSeletectedShiftIndex,
  date,
  selectedShift,
}: TimeBarProps) => {
  const { setResponsiveWindow, setMonitorActiveId } = useEstimationContext();

  return (
    <motion.div
      key="add-list"
      className="mt-3 w-[100%]"
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -50, opacity: 0 }}
      transition={{ type: "interia" }}
    >
      <div className="flex justify-between w-[100%]">
        <div
          className={`cursor-pointer rounded-xl bg-white ${
            type === "inFocus" ? "px-[18px] 2xl:px-[25px] py-[7px]" : "px-[15px] 2xl:px-[20px] py-[5px]"
          } text-sm font-medium text-black3 ${
            shiftType === "am"
              ? "border-green4 border-solid border !bg-green2 !text-black"
              : ""
          } transition-colors duration-700`}
          onClick={() => {
            if (shiftType !== "pm") {
              setShiftType(shiftType === "am" ? undefined : "am");
              if (shiftType === "am") {
                setSeletectedShiftIndex(undefined);
              }
            }
          }}
        >
          AM
        </div>
        <div
          className={`cursor-pointer rounded-xl bg-white ${
            type === "inFocus" ? "px-[18px] 2xl:px-[25px] py-[7px]" : "px-[15px] 2xl:px-[20px] py-[5px]"
          } text-sm font-medium text-black3 ${
            shiftType === "pm"
              ? "border-blue1 border-solid border !bg-blue2 !text-black"
              : ""
          }`}
          onClick={() => {
            if (shiftType !== "am") {
              setShiftType(shiftType === "pm" ? undefined : "pm");
              if (shiftType === "pm") {
                setSeletectedShiftIndex(undefined);
              }
            }
          }}
        >
          PM
        </div>
        <div
          className={`rounded-xl bg-white ${
            type === "inFocus" ? "px-[15px] 2xl:px-[18px] py-[7px]" : "px-[12px] 2xl:px-[16px] py-[5px]"
          }   text-sm font-medium text-black3 cursor-pointer`}
          onClick={() => {
            setMonitorActiveId(undefined);
            setResponsiveWindow({
              type: "createShift",
              props: { date, time: selectedShift },
            });
          }}
        >
          <EditTwo />
        </div>
      </div>
    </motion.div>
  );
};

export default TimeBar;
