import React, { useRef, useState } from "react";
import { useOutsideClick } from "rooks";

interface ContextMenuProps extends React.HTMLAttributes<HTMLDivElement> {
  menu: React.ReactNode;
  loc: "right" | "left";
  disabled?: boolean;
  onToggle?: (isOpen: boolean) => void;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

const ContextMenu = (props: ContextMenuProps) => {
  const { disabled, menu, children, onToggle, className, setOpen, isOpen } =
    props;
  const menuWrapperRef = useRef(null);

  useOutsideClick(menuWrapperRef, (e) => {
    setOpen(false);
    onToggle?.(false);
  });

  return (
    <div
      ref={menuWrapperRef}
      className={`relative ${className}`}
      onClick={(e) => {
        if (disabled) {
          return;
        }
        e.stopPropagation();
        setOpen(true);
        onToggle?.(true);
      }}
    >
      {isOpen ? (
        <div
          className={`absolute z-[99] top-[80%] drop-shadow-md ${
            props.loc === "right" ? "right-0" : "left-0"
          }`}
        >
          {menu}
        </div>
      ) : null}
      {children}
    </div>
  );
};

export default ContextMenu;
