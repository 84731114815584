export function getSeniority(seniority: number) {
  switch (seniority) {
    case 1:
      return "senior";
      break;
    case 2:
      return "midlevel";
      break;
    case 3:
      return "junior";
      break;
    default:
      return "blue";
  }
}
