import React, { FC } from 'react'
interface noteRoundI {
    className?: string
}
const NoteRound: FC<noteRoundI> = ({ className }) => {
    return (
        <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="#D1DBAF" />
            <path d="M14 16.0005H18" stroke="#67823A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 18.0005H18" stroke="#67823A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.5 10.5005H20.5C20.6326 10.5005 20.7598 10.5532 20.8536 10.6469C20.9473 10.7407 21 10.8679 21 11.0005V20.5005C21 20.8983 20.842 21.2798 20.5607 21.5611C20.2794 21.8425 19.8978 22.0005 19.5 22.0005H12.5C12.1022 22.0005 11.7206 21.8425 11.4393 21.5611C11.158 21.2798 11 20.8983 11 20.5005V11.0005C11 10.8679 11.0527 10.7407 11.1464 10.6469C11.2402 10.5532 11.3674 10.5005 11.5 10.5005Z" stroke="#67823A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 9.50049V11.5005" stroke="#67823A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 9.50049V11.5005" stroke="#67823A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19 9.50049V11.5005" stroke="#67823A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default NoteRound