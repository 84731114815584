import { MutableRefObject, useEffect } from "react";

const useRightClick = (
  ref:
    | MutableRefObject<HTMLElement | null>
    | MutableRefObject<(HTMLDivElement | null)[]>,
  rightClickHandler: (i?: number) => void
) => {
  useEffect(() => {
    const handleContextMenu = (i?: number) => (e: Event) => {
      e.preventDefault();
      rightClickHandler(i);
    };

    if (Array.isArray(ref.current)) {
      ref.current?.forEach((divElement, index) => {
        divElement?.addEventListener("contextmenu", handleContextMenu(index));
      });
    } else {
      ref.current?.addEventListener("contextmenu", handleContextMenu());
    }

    const cleanUpCopy = ref.current;

    return () => {
      if (Array.isArray(cleanUpCopy)) {
        cleanUpCopy.forEach((divElement, index) =>
          divElement?.removeEventListener(
            "contextmenu",
            handleContextMenu(index)
          )
        );
      } else {
        cleanUpCopy?.removeEventListener("contextmenu", handleContextMenu());
      }
    };
  }, [ref, rightClickHandler]);
};

export default useRightClick;
