import { useState } from "react";
import Plus from "../Icons/Plus";
import { Minus } from "../Icons/Minus";
import { Check } from "../Icons/Check";
import AddCancel from "../Icons/AddCancel";
import { motion } from "framer-motion";
import { useCreateShiftMutation } from "../../store/shiftsApi";
import { useLocation } from "../../store/location.store";
import { useSeniority } from "../../store/seniority.state";
import { RotatingSquare } from "react-loader-spinner";
import useHandleSuccessErrors from "../../hooks/handleSuccessErrors";
import { formatDateAPI } from "../../utils/formatTime";

type AddShiftBarProps = {
  from: string;
  to: string;
  date: Date;
  resetSelectedShift: () => void;
  resetAddShift: () => Promise<void>;
};

const AddShiftBar = ({
  from,
  to,
  date,
  resetSelectedShift,
  resetAddShift,
}: AddShiftBarProps) => {
  const [
    createShift,
    {
      isLoading: isLoadingCreateShift,
      isSuccess: isCreateShiftSuccess,
      isError: isCreateShiftError,
      error: createShiftError,
    },
  ] = useCreateShiftMutation();

  const [slotsCount, setSlotCount] = useState<number>(1);

  const { activeId: activeLocationId } = useLocation();
  const { activeId: activeSeniorityId } = useSeniority();

  useHandleSuccessErrors({
    isSuccess: isCreateShiftSuccess,
    isError: isCreateShiftError,
    error: createShiftError,
    successMessage: "Shift created successfully !",
  });

  const createShiftHandler = async () => {
    if (!isLoadingCreateShift) {
      await createShift({
        time: { from, to },
        totalDoctorsRequired: slotsCount,
        locationId: activeLocationId,
        seniority: activeSeniorityId,
        date: formatDateAPI(date),
      });
      await resetAddShift();
    }
  };

  return (
    <motion.div
      key="add-shift-bar"
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -50, opacity: 0 }}
      transition={{ type: "interia" }}
      className="mt-2"
    >
      <div className="rounded-lg flex cursor-pointer">
        <div
          className="rounded-l-lg bg-green1 px-3 py-1.5"
          onClick={() => {
            if (slotsCount > 1) {
              setSlotCount(slotsCount - 1);
            }
          }}
        >
          <Minus stroke={"stroke-white"} />
        </div>
        <div className="text-sm font-semibold grow bg-white border-green1 border items-center flex justify-center">
          Work Slot : {slotsCount}
        </div>
        <div
          className="rounded-r-lg bg-green1 px-3 py-1.5 cursor-pointer"
          onClick={() => {
            if (slotsCount < 8) {
              setSlotCount(slotsCount + 1);
            }
          }}
        >
          {" "}
          <Plus color={"white"} />
        </div>
      </div>
      <div className="flex gap-4 mt-2">
        {Array(slotsCount)
          .fill(0)
          .map((_, index) => (
            <div
              key={index}
              className={`h-[3px] flex-grow rounded-sm bg-[#BDBDBD]`}
            />
          ))}
      </div>
      <div className="flex justify-between mt-2">
        <div
          className="rounded-xl bg-orange1 hover:bg-[#70470B] px-4 py-1.5 w-[43%] flex justify-center svg-hover-white"
          onClick={() => {
            if (!isLoadingCreateShift) {
              resetSelectedShift();
            }
          }}
        >
          <AddCancel type="cancel" stroke="#DF9C28" />
        </div>
        <div
          className="rounded-xl bg-green1 hover:bg-white px-4 py-1.5 w-[43%] flex justify-center items-center svg-hover-black-2"
          onClick={createShiftHandler}
        >
          {isLoadingCreateShift ? (
            <RotatingSquare height={"10px"} width={"10px"} />
          ) : (
            <Check stroke="white" />
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default AddShiftBar;
