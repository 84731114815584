const ChatBubble = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.666778"
      height="12.083301"
      viewBox="0 0 12.666778 12.083301"
      fill="none"
      version="1.1"
      id="svg2"
    >
      <defs id="defs2" />
      <path
        d="m 7.5995487,11.227832 c 2.4401203,-0.1622 4.3837203,-2.1334 4.5437203,-4.6081 0.0313,-0.48428 0.0313,-0.98583 0,-1.47011 -0.16,-2.47469 -2.1036,-4.44591996 -4.5437203,-4.60811996 -0.83247,-0.05533 -1.70158,-0.05522 -2.53236,0 -2.44009,0.1622 -4.38374996,2.13342996 -4.54367996,4.60811996 -0.0313,0.48428 -0.0313,0.98583 0,1.47011 0.05825,0.90131 0.45686,1.73583 0.92613996,2.4405 0.27248,0.4933 0.09265,1.109 -0.19116,1.6469 -0.20463,0.3878 -0.30694996,0.5817 -0.2248,0.7217 0.08216,0.1401 0.26567,0.1446 0.63268,0.1535 0.72581,0.0177 1.21523,-0.1881 1.60373,-0.4746 0.22034,-0.1625 0.33051,-0.2437 0.40644,-0.2531 0.07593,-0.0093 0.22536,0.0523 0.52417,0.1753 0.26856,0.1106 0.58039,0.1789 0.86648,0.1979 0.83078,0.0552 1.69989,0.0554 2.53236,0 z"
        stroke="#4f4f4f"
        stroke-linejoin="round"
        id="path1"
      />
      <path
        d="m 6.3307687,6.041732 h 0.00519 m 2.32552,0 h 0.00525 m -4.66669,0 h 0.00523"
        stroke="#4f4f4f"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        id="path2"
      />
    </svg>
  );
};

export default ChatBubble;
