/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, {
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  useEffect,
  useState,
} from "react";
import Button from "./Button";
import { HiArrowLeft, HiArrowRight, HiPlus } from "react-icons/hi";

export interface TableHeadOptions<T> {
  colSpan?: number;
  title: React.ReactNode;
  key: keyof T;
  sortable?: boolean;
  multiSelectTrigger?: boolean;
  render?: (
    data: TableHeadOptions<T>["key"],
    parent: TableProps<T>["data"][number],
    index: number
  ) =>
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactFragment
    | T[keyof T]
    | null
    | undefined
    | JSX.Element;
}

export interface TablePagination {
  total: number;
  currentPage: number;
  onChange?: (page: number) => void;
}

export interface ActionBtnProps<T> {
  key: string | number;
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>, rowData: T) => void;
}

interface TableProps<T> extends React.HTMLAttributes<HTMLTableElement> {
  data: T[];
  head: TableHeadOptions<T>[];
  actionBtns?: ActionBtnProps<T>[];
  showHeader?: boolean;
  skeleton?: boolean;
  pagination?: TablePagination;
  showAddBtn?: boolean;
  multiSelect?: boolean;
  multiSelected?: T[];
  classNames?: {
    table?: React.HTMLAttributes<HTMLTableElement>["className"];
    thead?: React.HTMLAttributes<HTMLTableSectionElement>["className"];
    tbody?: React.HTMLAttributes<HTMLTableSectionElement>["className"];
    tr?: React.HTMLAttributes<HTMLTableRowElement>["className"];
    th?: React.HTMLAttributes<HTMLTableCellElement>["className"];
    td?: React.HTMLAttributes<HTMLTableCellElement>["className"];
    tfoot?: React.HTMLAttributes<HTMLTableSectionElement>["className"];
  };
  onAddRow?: () => void;
  setMultiSelect?: (data: T[]) => void;
  activeTr?: boolean;
}

const Table = <T extends object>(props: TableProps<T>) => {
  const {
    data,
    head,
    actionBtns,
    showHeader = true,
    classNames,
    showAddBtn = false,
    pagination,
    onAddRow,
    activeTr,
    skeleton = false,
    setMultiSelect,
    multiSelected,
    multiSelect = false,
    ...otherProps
  } = props;

  if (skeleton) {
    return (
      <table
        {...otherProps}
        className={`${otherProps.className ?? ""} ${classNames?.table ?? ""}`}
      >
        {showHeader ? (
          <thead className={`${classNames?.thead ?? ""} drop-shadow`}></thead>
        ) : null}
        <thead className={`${classNames?.tbody ?? ""}`}>
          <tr className={`${classNames?.tr ?? ""}`}>
            {head.map((item, i) => (
              <td
                colSpan={item.colSpan}
                key={i}
                className={`bg-white px-6 py-4 !text-center text-base font-bold leading-6 text-[#424242] first:rounded-tl-2xl last:rounded-tr-2xl ${
                  classNames?.td ?? ""
                }`}
              >
                {item.title}
              </td>
            ))}
            {actionBtns?.length ? (
              <td
                className={`bg-white px-6 py-4 !text-center text-base font-bold leading-6 text-[#424242] first:rounded-tl-2xl last:rounded-tr-2xl ${
                  classNames?.td ?? ""
                }`}
              >
                Actions
              </td>
            ) : null}
          </tr>
        </thead>
        <tbody className={`${classNames?.tbody ?? ""} !divide-y-2`}>
          {[...Array(5).keys()].map((_, i) => (
            <tr
              key={i}
              className={`border bg-white text-center ${classNames?.tr ?? ""} ${
                activeTr ? "border border-secondary rounded-lg" : ""
              }`}
            >
              {head.map((headItem, j) => (
                <td
                  key={j}
                  className={`px-6 py-4 text-base items-center font-bold text-[#727272] ${
                    classNames?.td ?? ""
                  }`}
                >
                  <div className="animate-pulse bg-slate-300 rounded">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </td>
              ))}
              {actionBtns?.length ? (
                <td
                  className={`px-6 py-4 text-base items-center font-bold text-[#727272] ${
                    classNames?.td ?? ""
                  }`}
                >
                  <div className="animate-pulse">Loading...</div>
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  return (
    <table
      {...otherProps}
      className={`${otherProps.className ?? ""} ${classNames?.table ?? ""}`}
    >
      {showHeader ? (
        <thead className={`${classNames?.thead ?? ""} drop-shadow`}>
          <tr className={`${classNames?.tr ?? ""}`}>
            {head.map((item, i) => (
              // add skeleton here
              <th
                colSpan={item.colSpan}
                key={i}
                className={`${
                  classNames?.th ?? ""
                } bg-white px-6 py-4 !text-center text-base font-bold leading-6 text-[#424242] first:rounded-tl-2xl last:rounded-tr-2xl`}
              >
                {item.title}
              </th>
            ))}
            {actionBtns?.length ? (
              <th
                className={`bg-white px-6 py-4 !text-center text-base font-bold leading-6 text-[#424242] first:rounded-tl-2xl last:rounded-tr-2xl ${
                  classNames?.th ?? ""
                }`}
              >
                Action
              </th>
            ) : null}
          </tr>
        </thead>
      ) : null}
      <tbody
        className={`${
          classNames?.tbody ?? ""
        } drop-shadow rounded-b-2xl divide-y-2 divide-primary`}
      >
        {data?.length
          ? data.map((item, i) => (
              <tr
                key={i}
                className={`bg-white text-center ${
                  multiSelected?.includes(item) ? "!bg-[#D1DBAF]" : ""
                } ${classNames?.tr ?? ""} ${
                  activeTr ? "border border-secondary rounded-lg" : ""
                }`}
              >
                {head.map((headItem, j) => (
                  // add skeleton here
                  <td
                    colSpan={headItem.colSpan}
                    key={j}
                    className={`${
                      headItem.multiSelectTrigger ? "hover:cursor-pointer" : ""
                    } px-6 py-4 text-base items-center font-bold text-[#727272] ${
                      classNames?.td ?? ""
                    }`}
                    onClick={() => {
                      if (!multiSelect) {
                        return;
                      }
                      if (headItem.multiSelectTrigger) {
                        if (multiSelected.includes(item)) {
                          setMultiSelect(
                            multiSelected.filter((i) => i !== item)
                          );
                        } else {
                          setMultiSelect([...multiSelected, item]);
                        }
                      }
                    }}
                  >
                    {/* @ts-ignore */}
                    {headItem.render
                      ? headItem.render(item[headItem.key], item, i)
                      : item[headItem.key]}
                  </td>
                ))}
                {actionBtns?.length ? (
                  <td
                    className={`px-6 py-4 text-base font-bold text-[#727272] ${
                      classNames?.td ?? ""
                    }`}
                  >
                    <div className="flex gap-x-1 items-center">
                      {actionBtns.map((btn, k) => (
                        <button key={k} onClick={(e) => btn.onClick?.(e, item)}>
                          {btn.children}
                        </button>
                      ))}
                    </div>
                  </td>
                ) : null}
              </tr>
            ))
          : null}
        {!data.length ? (
          <tr className="border bg-white text-center">
            <td
              colSpan={head.length + (actionBtns?.length ? 1 : 0)}
              className={`px-6 py-4 text-base items-center font-bold text-[#727272] ${
                classNames?.td ?? ""
              }`}
            >
              No data.
            </td>
          </tr>
        ) : null}
      </tbody>
      <>
        {pagination ? (
          <tfoot className={`${classNames?.tfoot ?? ""}`}>
            <tr className={`rounded-b-2xl bg-white ${classNames?.tr ?? ""}`}>
              <td
                colSpan={head.length + (actionBtns?.length ? 1 : 0)}
                className={`px-6 py-4 text-base font-bold text-[#727272] ${
                  classNames?.td ?? ""
                }`}
              >
                <div className="flex items-center justify-center">
                  {/* button arrow left and text {page}/{totalPage} button arrow right */}
                  <Button
                    icon={{
                      content: <HiArrowLeft />,
                      position: "left",
                    }}
                    className="!rounded-full bg-primary !h-8 !w-8 flex items-center justify-center"
                    onClick={() => {
                      if (pagination?.currentPage === 1) return;

                      pagination?.onChange?.(pagination?.currentPage - 1);
                    }}
                  ></Button>
                  <div className="flex items-center justify-center mx-2">
                    <span className="text-base font-bold text-[#727272]">
                      {pagination?.currentPage}/{pagination?.total}
                    </span>
                  </div>
                  <Button
                    icon={{
                      content: <HiArrowRight />,
                      position: "right",
                    }}
                    className="!rounded-full bg-primary !h-8 !w-8 flex items-center justify-center"
                    onClick={() => {
                      if (pagination?.currentPage === pagination?.total) return;
                      pagination?.onChange?.(pagination?.currentPage + 1);
                    }}
                  ></Button>
                </div>
              </td>
            </tr>
          </tfoot>
        ) : null}
      </>
      {showAddBtn ? (
        <tfoot className={`${classNames?.tfoot ?? ""}`}>
          <tr className={`rounded-b-2xl bg-white ${classNames?.tr ?? ""}`}>
            <td
              colSpan={head.length + (actionBtns?.length ? 1 : 0)}
              className={`px-6 py-4 text-base font-bold text-[#727272] ${
                classNames?.td ?? ""
              }`}
            >
              <div className="flex items-center justify-center">
                <Button
                  icon={{
                    content: <HiPlus />,
                    position: "left",
                  }}
                  className="!rounded-full !h-8 !w-8 flex items-center justify-center"
                  onClick={onAddRow}
                ></Button>
              </div>
            </td>
          </tr>
        </tfoot>
      ) : null}
    </table>
  );
};

export default Table;
