import { deleteIndexes } from "../../../utils/deleteIndexes";
import { useMemo } from "react";

export const getRowCol = (columns: number) => (index: number) => {
  const rowNumber = Math.floor(index / columns);
  const colNumber = index % columns;

  return { row: rowNumber, col: colNumber };
};

const getIndex = (columns: number) => (row: number, col: number) => {
  return row * columns + col;
};

const useDoctorCardAnimate = ({
  selectedDoctorIndex,
  doctors,
  enable,
  columns,
  rows,
}: {
  selectedDoctorIndex: number;
  doctors?: any[];
  enable: boolean;
  columns: number;
  rows?: number;
}): {
  finalDoctors: any[];
  profileColPos: number;
  profileRowPos: number;
  type: { y: "top" | "bottom"; x: "left" | "right" };
} => {
  const { row: doctorRow, col: doctorCol } =
    getRowCol(columns)(selectedDoctorIndex);

  const profileRowPos = (() => {
    if (!rows) {
      return doctorRow + 1;
    }
    return doctorRow < 4 ? doctorRow + 1 : doctorRow - 3;
  })();

  const profileColPos = (() => {
    return doctorCol === columns - 1 ? columns - 2 : doctorCol;
  })();

  const finalDoctors = useMemo(() => {
    if (!doctors) {
      return [];
    }
    const paddedDoctors = (() => {
      if (doctors && rows) {
        return rows * columns - doctors.length;
      } else if (doctors && !rows) {
        const rows_ = Math.floor(doctors.length - 1 / columns) + 1;
        return (rows_ + 2) * columns - doctors.length;
      }
      return 0;
    })();

    const getI = getIndex(columns);
    if (enable) {
      // The first 5 arg needs to be replaced with the map or the formula
      return deleteIndexes(
        doctors.concat(Array(paddedDoctors).fill(undefined)),
        [
          getI(profileRowPos, profileColPos) + 1,
          getI(profileRowPos + 1, profileColPos),
          getI(profileRowPos + 1, profileColPos) + 1,
          getI(profileRowPos + 2, profileColPos),
          getI(profileRowPos + 2, profileColPos) + 1,
        ],
      );
    }
    return doctors;
  }, [doctors, rows, columns, enable, profileRowPos, profileColPos]);

  const type: { y: "top" | "bottom"; x: "left" | "right" } = {
    y: doctorRow < 4 || !rows ? "top" : "bottom",
    x: doctorCol === columns - 1 ? "left" : "right",
  };

  return { finalDoctors, profileColPos, profileRowPos, type };
};

export default useDoctorCardAnimate;
