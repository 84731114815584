import { create } from "zustand";
import { ApiState, getInitialState } from ".";
import { axiosApi } from "./axios";

type OpenRequestDays = ApiState<
  { locationId: string; dates: string[]; seniority: number },
  any,
  any
>;

const useOpenRequestDays = create<OpenRequestDays>((set) => ({
  ...getInitialState<
    { locationId: string; dates: string[]; seniority: number },
    any,
    any
  >({}, set),
  fetchFn: async (body) => {
    let data: { calendar: any[] } = null as unknown as { calendar: any[] };
    let error: any = null;
    let isError = false;
    let isLoading = false;
    let isSuccess = false;

    try {
      set({
        isLoading: true,
        isError: false,
        isSuccess: false,
        data: null,
        error: null,
      });
      isLoading = true;
      const res = await axiosApi({
        url: `/api/location/${body?.data?.locationId}/open-request`,
        method: "POST",
        data: {
          dates: body?.data?.dates,
          seniority: body?.data?.seniority,
        },
      });
      set({ data: res.data, isSuccess: true });
      data = res.data;
      isSuccess = true;
    } catch (err: any) {
      set({ isError: true, error: err, isSuccess: false, data: null });
      isError = true;
      error = err;
      isSuccess = false;
    } finally {
      set({ isLoading: false });
      isLoading = false;
    }

    return {
      unwrap: () => {
        return { data, error, isError, isLoading, isSuccess };
      },
    };
  },
}));

type RemoveLocation = ApiState<{ locationId: string }, any, any>;

const useRemoveLocation = create<RemoveLocation>((set) => ({
  ...getInitialState<{ locationId: string }, any, any>({}, set),
  fetchFn: async (body) => {
    let data: any = null as any;
    let error: any = null;
    let isError = false;
    let isLoading = false;
    let isSuccess = false;

    try {
      set({
        isLoading: true,
        isError: false,
        isSuccess: false,
        data: null,
        error: null,
      });
      isLoading = true;
      const res = await axiosApi({
        url: `/api/location/${body?.data?.locationId}`,
        method: "DELETE",
      });
      set({ data: res.data, isSuccess: true });
      data = res.data;
      isSuccess = true;
    } catch (err: any) {
      set({ isError: true, error: err, isSuccess: false, data: null });
      isError = true;
      error = err;
      isSuccess = false;
    } finally {
      set({ isLoading: false });
      isLoading = false;
    }

    return {
      unwrap: () => {
        return { data, error, isError, isLoading, isSuccess };
      },
    };
  },
}));

type CreateLocation = ApiState<
  {
    label: string;
    seniorities: number[];
    groups: string[];
    closedDates: string[];
  },
  any,
  any
>;

const useCreateLocation = create<CreateLocation>((set) => ({
  ...getInitialState<
    {
      label: string;
      seniorities: number[];
      groups: string[];
      closedDates: string[];
    },
    any,
    any
  >(
    {
      method: "POST",
      url: "/api/location/add",
    },
    set,
  ),
}));

type SetLeaveDates = ApiState<
  { locationId: string; dates: string[] },
  any,
  any
>;

const useSetLeaveDates = create<SetLeaveDates>((set) => ({
  ...getInitialState<{ locationId: string; dates: string[] }, any, any>(
    {},
    set,
  ),
  fetchFn: async (body) => {
    let data: any = null as any;
    let error: any = null;
    let isError = false;
    let isLoading = false;
    let isSuccess = false;

    try {
      set({
        isLoading: true,
        isError: false,
        isSuccess: false,
        data: null,
        error: null,
      });
      isLoading = true;
      const res = await axiosApi({
        url: `/api/location/leave/${body?.data?.locationId}`,
        method: "POST",
        data: {
          dates: body?.data?.dates,
        },
      });
      set({ data: res.data, isSuccess: true });
      data = res.data;
      isSuccess = true;
    } catch (err: any) {
      set({ isError: true, error: err, isSuccess: false, data: null });
      isError = true;
      error = err;
      isSuccess = false;
    } finally {
      set({ isLoading: false });
      isLoading = false;
    }

    return {
      unwrap: () => {
        return { data, error, isError, isLoading, isSuccess };
      },
    };
  },
}));

const location = {
  useOpenRequestDays,
  useRemoveLocation,
  useCreateLocation,
  useSetLeaveDates,
};

export default location;
