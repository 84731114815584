import React from 'react'

const TopToBottomArrow = ({ className, fill, ...props }: any) => {
    return (
        <svg className={className} {...props} width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={fill} d="M9.76531 5.39031L7.51531 7.64031C7.44495 7.71068 7.34951 7.75021 7.25 7.75021C7.15049 7.75021 7.05505 7.71068 6.98469 7.64031C6.91432 7.56995 6.87479 7.47451 6.87479 7.375C6.87479 7.27549 6.91432 7.18005 6.98469 7.10969L8.59484 5.5H5C3.70749 5.49864 2.46831 4.98458 1.55436 4.07064C0.640418 3.15669 0.126365 1.91751 0.125 0.625C0.125 0.525544 0.164509 0.430161 0.234835 0.359835C0.305161 0.289509 0.400544 0.25 0.5 0.25C0.599456 0.25 0.694839 0.289509 0.765165 0.359835C0.835491 0.430161 0.875 0.525544 0.875 0.625C0.876241 1.71864 1.31124 2.76713 2.08455 3.54045C2.85787 4.31376 3.90636 4.74876 5 4.75H8.59484L6.98469 3.14031C6.91432 3.06995 6.87479 2.97451 6.87479 2.875C6.87479 2.77549 6.91432 2.68005 6.98469 2.60969C7.05505 2.53932 7.15049 2.49979 7.25 2.49979C7.34951 2.49979 7.44495 2.53932 7.51531 2.60969L9.76531 4.85969C9.80018 4.89451 9.82784 4.93587 9.84671 4.9814C9.86558 5.02692 9.87529 5.07572 9.87529 5.125C9.87529 5.17428 9.86558 5.22308 9.84671 5.2686C9.82784 5.31413 9.80018 5.35549 9.76531 5.39031Z" fill="white" />
        </svg>
    )
}

export default TopToBottomArrow