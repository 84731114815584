import React, { FC } from "react";

interface shiftPillProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  name?: React.ReactNode;
  children?: React.ReactNode;
  isActiveClassName?: string;
  isInActiveClassName?: string;
  className: string;
  isActive?: boolean;
}
const ShiftPill: FC<shiftPillProps> = (props) => {
  const {
    name,
    children,
    className,
    isActiveClassName,
    isInActiveClassName,
    isActive,
    ...nativeprops
  } = props;
  return (
    <div
      {...nativeprops}
      className={`rounded-lg px-4 py-1 font-medium text-sm ${className} ${
        isActive
          ? isActiveClassName ??
            "bg-primary border border-secondary text-black1"
          : isInActiveClassName ?? "bg-white text-black3"
      }`}
    >
      {name}
      {children}
    </div>
  );
};

export default ShiftPill;
