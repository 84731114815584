import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { toast } from "react-toastify";

const handleResponse = (
  response:
    | {
        data: any;
      }
    | {
        error: FetchBaseQueryError | SerializedError;
      },
  successMessage: string,
  successFunction?: () => any
) => {
  if ((response as { data: any }).data) {
    toast.success(successMessage, {
      toastId: successMessage.toLowerCase().replace(/\s/g, "-"),
    });
    successFunction?.();
    return;
  } else {
    const message = (
      (response as { error: FetchBaseQueryError }).error.data as {
        message: string;
      }
    ).message;

    toast.error(message, {
      toastId: message.toLowerCase().replace(/\s/g, "-"),
    });
  }
};

export default handleResponse;
