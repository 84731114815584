export default function CaretDoubleUp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
    >
      <g id="CaretDoubleUp">
        <path
          id="Vector"
          d="M3.80466 10.1498C3.75085 10.0961 3.70816 10.0322 3.67904 9.96199C3.64992 9.89174 3.63493 9.81644 3.63493 9.74038C3.63493 9.66433 3.64992 9.58903 3.67904 9.51878C3.70816 9.44852 3.75085 9.3847 3.80466 9.33095L9.59169 3.54391C9.64544 3.49011 9.70926 3.44742 9.77952 3.4183C9.84977 3.38918 9.92507 3.37419 10.0011 3.37419C10.0772 3.37419 10.1525 3.38918 10.2227 3.4183C10.293 3.44742 10.3568 3.49011 10.4106 3.54391L16.1976 9.33095C16.3062 9.43954 16.3672 9.58682 16.3672 9.74038C16.3672 9.89395 16.3062 10.0412 16.1976 10.1498C16.089 10.2584 15.9417 10.3194 15.7882 10.3194C15.6346 10.3194 15.4873 10.2584 15.3787 10.1498L10.0011 4.77149L4.62352 10.1498C4.56978 10.2036 4.50595 10.2463 4.4357 10.2754C4.36544 10.3046 4.29014 10.3195 4.21409 10.3195C4.13804 10.3195 4.06273 10.3046 3.99248 10.2754C3.92223 10.2463 3.8584 10.2036 3.80466 10.1498ZM10.4106 9.33095C10.3568 9.27714 10.293 9.23446 10.2227 9.20534C10.1525 9.17621 10.0772 9.16123 10.0011 9.16123C9.92507 9.16123 9.84977 9.17621 9.77952 9.20534C9.70926 9.23446 9.64544 9.27715 9.59169 9.33095L3.80466 15.118C3.69607 15.2266 3.63506 15.3739 3.63506 15.5274C3.63506 15.681 3.69607 15.8283 3.80466 15.9369C3.91324 16.0454 4.06052 16.1064 4.21409 16.1064C4.36766 16.1064 4.51493 16.0454 4.62352 15.9369L10.0011 10.5585L15.3787 15.9369C15.4873 16.0454 15.6346 16.1064 15.7882 16.1064C15.9417 16.1064 16.089 16.0454 16.1976 15.9369C16.3062 15.8283 16.3672 15.681 16.3672 15.5274C16.3672 15.3739 16.3062 15.2266 16.1976 15.118L10.4106 9.33095Z"
          fill="#67823A"
        />
      </g>
    </svg>
  );
}
