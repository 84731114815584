const isStringArrayEqual = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  arr1 = arr1.sort();
  arr2 = arr2.sort();

  return arr1.every((char, index) => {
    return char === arr2[index];
  });
};

export default isStringArrayEqual;
