import { MutableRefObject, useEffect, useState } from "react";

// command key in mac

const useCtrlKey = (ref: MutableRefObject<HTMLElement | null>) => {
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);

  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      e.preventDefault();
      if (e.key === "Control" || e.key === "Meta") {
        setIsCtrlPressed(true);
      }
    };

    const keyUpHandler = (e: KeyboardEvent) => {
      e.preventDefault();
      if (e.key === "Control" || e.key === "Meta") {
        setIsCtrlPressed(false);
      }
    };

    ref.current?.addEventListener("keydown", keyDownHandler);
    ref.current?.addEventListener("keyup", keyUpHandler);
    const cleanUpCopy = ref.current;

    return () => {
      cleanUpCopy?.removeEventListener("keydown", keyDownHandler);
      cleanUpCopy?.removeEventListener("keyup", keyUpHandler);
    };
  }, []);

  return isCtrlPressed;
};

export default useCtrlKey;
