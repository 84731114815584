import { useCallback, useRef, useState } from "react";

const useDebounce = (callback: () => void, wait: number) => {
  const [isBlocked, setIsBlocked] = useState(false);
  const isBlockedRef = useRef(false);
  
  const deboucedCallback = useCallback(() => {
    if (!isBlockedRef.current) {
      callback();
      setIsBlocked(true);
      isBlockedRef.current = true;
      setTimeout(() => {
        setIsBlocked(false);
        isBlockedRef.current = false;
      }, wait);
    }
  }, [callback, wait]);

  return { deboucedCallback, isBlocked };
};

export default useDebounce;
