interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
  options: React.ReactNode;
}
const Select = (prop: SelectProps) => {
  const { className, options, ...nativeProps } = prop;

  return (
    <select
      {...nativeProps}
      className={`bg-gray-50 border-2  border-primary text-gray-900 text-sm rounded-xl h-12 font-semibold focus:ring-primary focus:border-primary block w-full p-2.5  ${
        className ?? ""
      }`}
    >
      {options ? options : null}
    </select>
  );
};

export default Select;
