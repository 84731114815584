import Typography from "../../Typography";

interface GroupCardProps {
    name: string;
    isActive: boolean;
    onClick?: () => void;
  }

  
const GroupCard = (props: GroupCardProps) => {
    const { isActive, name, onClick } = props;
  
    return (
      <div
        onClick={onClick}
        className={`rounded-lg flex cursor-pointer flex-col items-center justify-center p-6 ${
          isActive ? "bg-secondary" : "bg-primary"
        }`}
      >
        <Typography tag="h5" className="text-white uppercase">
          {name}
        </Typography>
      </div>
    );
  };

  export default GroupCard