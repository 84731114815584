import { Splide, SplideSlide } from "@splidejs/react-splide";
import { FC, SetStateAction, Dispatch, useMemo, useRef, useState } from "react";
import useRightClick from "../hooks/useRightClick";
import { useOutsideClick } from "rooks";

const DIGIT_REGEX = new RegExp(/^\d{0,2}$/);
interface verticalSliderprops {
  id: string;
  min?: number;
  max: number;
  number: number;
  setNumber: Dispatch<SetStateAction<number>> | ((number: number) => void);
  cycleList?: number[];
}

const NumberSlider: FC<verticalSliderprops> = ({
  id,
  min = 0,
  max,
  number,
  setNumber,
  cycleList,
}) => {
  const ref = useRef<null | Splide>(null);
  const divRefList = useRef<(HTMLDivElement | null)[]>(
    Array(max - min + 1).fill(null)
  );
  const inputDivRef = useRef<HTMLDivElement | null>(null);
  const [isTextInput, setIsTextInput] = useState<boolean>(false);
  const [numberInput, setNumberInput] = useState<string>("");

  useRightClick(divRefList, () => {
    setIsTextInput(true);
  });

  useOutsideClick(inputDivRef, () => {
    setIsTextInput(false);
    if (numberInput !== "") {
      setNumber(Number(numberInput));
      setNumberInput("");
    }
  });

  const numberList = useMemo(
    () => Array.from({ length: max - min + 1 }).map((_, index) => index + min),
    [max, min]
  );

  const handleMoved = (splide: any) => {
    setNumber(numberList[splide.index]);
  };

  const handlePadStart = (number: number) => {
    return number.toString().padStart(2, "0");
  };

  return isTextInput ? (
    <div
      className="font-medium text-sm text-black1 w-[19.5px] h-[44px]"
      ref={inputDivRef}
    >
      <input
        type="text"
        className="border-box w-full h-full focus:outline-none"
        ref={(element) => element?.focus()}
        onChange={(e) => {
          if (e.target.value.match(DIGIT_REGEX)) {
            const number = Number(e.target.value);
            if (number >= min && number <= max) {
              setNumberInput(e.target.value);
            }
          }
          if (e.target.value === "") {
            setNumberInput("");
          }
        }}
        value={numberInput}
      />
    </div>
  ) : (
    <Splide
      className="slide lg:block hidden bg-white rounded-mda"
      onMove={handleMoved}
      id={id}
      options={{
        gap: 0,
        autoplay: false,
        height: 44,
        direction: "ttb",
        type: "loop",
        perMove: 1,
        perPage: 1,
        focus: "center",
        arrows: true,
        start: numberInput !== "" ? Number(numberInput) - min : number - min,
        pauseOnFocus: false,
        pauseOnHover: false,
      }}
      aria-label="Number Scroll"
      ref={ref}
    >
      {numberList.map((number, index) => (
        <SplideSlide key={index}>
          <div
            className="rounded h-full w-full text-center flex justify-center items-center font-medium text-sm text-black1"
            ref={(elem) => {
              divRefList.current[index] = elem;
            }}
            onClick={() => {
              if (cycleList && !ref.current?.splide?.state.is(4)) {
                const nextNumber: number =
                  cycleList.find((value, index) => {
                    const prevIndex =
                      index - 1 === -1 ? cycleList.length - 1 : index - 1;
                    return number >= cycleList[prevIndex] && number < value;
                  }) ?? cycleList[0];

                ref.current?.splide?.go(nextNumber);
              }
            }}
          >
            {handlePadStart(number)}
          </div>
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default NumberSlider;
