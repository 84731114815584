import React from 'react'

const RoundedTags = ({ className, fillbg, fill, stroke, ...props }: any) => {
    return (
        <svg className={className} {...props} width="48" height="46" viewBox="0 0 48 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 12C0.5 5.64873 5.64873 0.5 12 0.5H36C42.3513 0.5 47.5 5.64873 47.5 12V34C47.5 40.3513 42.3513 45.5 36 45.5H12C5.64873 45.5 0.5 40.3513 0.5 34V12Z" className={fillbg} fill="white" />
            <path d="M0.5 12C0.5 5.64873 5.64873 0.5 12 0.5H36C42.3513 0.5 47.5 5.64873 47.5 12V34C47.5 40.3513 42.3513 45.5 36 45.5H12C5.64873 45.5 0.5 40.3513 0.5 34V12Z" className={stroke} stroke="#E0E0E0" />
            <path d="M22 19.5C22 19.8978 21.842 20.2794 21.5607 20.5607C21.2794 20.842 20.8978 21 20.5 21C20.1022 21 19.7206 20.842 19.4393 20.5607C19.158 20.2794 19 19.8978 19 19.5C19 19.1022 19.158 18.7206 19.4393 18.4393C19.7206 18.158 20.1022 18 20.5 18C20.8978 18 21.2794 18.158 21.5607 18.4393C21.842 18.7206 22 19.1022 22 19.5ZM21 19.5C21 19.3674 20.9473 19.2402 20.8536 19.1464C20.7598 19.0527 20.6326 19 20.5 19C20.3674 19 20.2402 19.0527 20.1464 19.1464C20.0527 19.2402 20 19.3674 20 19.5C20 19.6326 20.0527 19.7598 20.1464 19.8536C20.2402 19.9473 20.3674 20 20.5 20C20.6326 20 20.7598 19.9473 20.8536 19.8536C20.9473 19.7598 21 19.6326 21 19.5Z" className={fill} fill="#4F4F4F" />
            <path d="M18 16H22.586C22.8512 16.0001 23.1055 16.1055 23.293 16.293L30.293 23.293C30.4805 23.4805 30.5858 23.7348 30.5858 24C30.5858 24.2652 30.4805 24.5195 30.293 24.707L25.707 29.293C25.5195 29.4805 25.2652 29.5858 25 29.5858C24.7348 29.5858 24.4805 29.4805 24.293 29.293L17.293 22.293C17.1055 22.1055 17.0001 21.8512 17 21.586V17C17 16.7348 17.1054 16.4804 17.2929 16.2929C17.4804 16.1054 17.7348 16 18 16ZM18 21.586L25 28.586L29.586 24L22.586 17H18V21.586Z" className={fill} fill="#4F4F4F" />
        </svg>

    )
}

export default RoundedTags