import { TIME_ZONE } from "../constants";
import { DateTime } from "luxon";

export const shiftFindFn =
  ({
    date,
    activeSeniorityId,
    activeLocationId,
  }: {
    date: Date;
    activeSeniorityId: number;
    activeLocationId: string;
  }) =>
  (
    shiftArray: {
      seniority: { id: number };
      date: Date;
      location: string;
    }[],
  ) => {
    return shiftArray.length > 0
      ? DateTime.fromISO(shiftArray[0].date as unknown as string)
          .setZone(TIME_ZONE)
          .toJSDate()
          .getTime() === date.getTime() &&
          shiftArray[0].seniority.id === activeSeniorityId &&
          shiftArray[0].location === activeLocationId
      : false;
  };
