export default function AddUser() {
  return (
    <svg
      width="17"
      height={16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 8.5H16"
        stroke="#67823A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 7V10"
        stroke="#67823A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.25 10C9.32107 10 11 8.32107 11 6.25C11 4.17893 9.32107 2.5 7.25 2.5C5.17893 2.5 3.5 4.17893 3.5 6.25C3.5 8.32107 5.17893 10 7.25 10Z"
        stroke="#67823A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.88867 12.4999C2.54576 11.717 3.36629 11.0875 4.29263 10.6557C5.21897 10.2238 6.22864 10 7.2507 10C8.27276 10 9.28243 10.2238 10.2088 10.6557C11.1351 11.0876 11.9556 11.7171 12.6127 12.4999"
        stroke="#67823A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
