import React, { FC } from "react";
interface shiftPillProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  name: React.ReactNode;
  children?: React.ReactNode;
  className: string;
  isActive?: boolean;
  activeBgColor: string;
}
const TagPill: FC<shiftPillProps> = (props) => {
  const { name, children, className, isActive, activeBgColor, ...nativeprops } =
    props;
  return (
    <div
      {...nativeprops}
      className={`rounded-lg px-4 py-1 font-medium text-sm ${className} ${
        isActive
          ? `${activeBgColor} text-white`
          : "bg-gray2 text-black3"
      }`}
    >
      {name}
      {children}
    </div>
  );
};

export default TagPill;
