import { BG_SENIORITY } from "../../constants";

const SmallDoctorCard = ({
  location,
  name,
  nickName,
  seniority,
}: {
  location?: string;
  name: string;
  nickName?:string
  seniority?: number;
}) => {
  return (
    <div className="bg-white shadow-xl h-fit rounded-lg text-xs font-semibold p-2 flex items-center relative transition-[width] w-full">
      <div
        className={`relative -left-2.5 rounded-l-md ${
          BG_SENIORITY[seniority?.toString() as "1" | "2" | "3"] ?? 'bg-black3'
        } w-1 h-4`}
      ></div>
      <div className="text-ellipsis w-[calc(70%)] overflow-hidden whitespace-nowrap">
        {nickName ?? name}
      </div>
      {location && (
        <div
          className={`rounded-sm w-5 break-words leading-none ${
            location.length > 2 ? "text-[8.5px]" : ""
          } text-white bg-secondary p-[2px] text-center rounded-[6.5px] ml-auto`}
        >
          {location.slice(0, 4)}
        </div>
      )}
    </div>
  );
};

export default SmallDoctorCard;
