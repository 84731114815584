import { FC, useEffect, useState } from "react";
import GuacLoader from "../components/GuacLoader";
import { Outlet, useNavigate } from "react-router-dom";
import { getRedirectUrl } from "../utils/getRedirectUrl";
import { axiosApi } from "../store/axios";

const AntiAuth: FC = () => {
  const [isNotAuth, setNotAuth] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axiosApi({
        url: "/api/auth/me",
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((res) => navigate(getRedirectUrl(res.data.role.label)))
        .catch(() => {
          setNotAuth(true);
        });
    } else {
      setNotAuth(true);
    }
  }, []);

  if (isNotAuth) {
    return <Outlet />;
  }

  return <GuacLoader />;
};

export default AntiAuth;
