const UserCancel = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9167 4.33398C9.53333 4.33398 7.58333 6.28398 7.58333 8.66732C7.58333 11.0507 9.53333 13.0007 11.9167 13.0007C14.3 13.0007 16.25 11.0507 16.25 8.66732C16.25 6.28398 14.3 4.33398 11.9167 4.33398ZM11.9167 15.1673C7.15 15.1673 3.25 17.1173 3.25 19.5007V21.6673H13.5417C13.2167 20.8006 13 19.934 13 18.959C13 17.659 13.325 16.4673 13.975 15.2757C13.325 15.2757 12.675 15.1673 11.9167 15.1673Z"
        fill="white"
      />
      <path
        d="M22.571 15L17 20.571"
        stroke="white"
        stroke-width="1.46253"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.571 20.571L17 15"
        stroke="white"
        stroke-width="1.46253"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default UserCancel
