import React, { FC, PropsWithChildren, useEffect } from "react"
import { useModal } from "../hooks/useModal"
import { useOutsideClick } from "rooks"

const ManagementLayout: FC<PropsWithChildren> = ({ children }) => {
  const { modalsJsx, closeModal } = useModal()
  const modalRef = React.useRef<HTMLDivElement>(null)
  useOutsideClick(modalRef, () => {
    closeModal()
  })
  useEffect(() => {
    // if child element height is greater than parent element height
    // then add top bottom margin
    const modal = modalRef.current
    if (modal) {
      const modalHeight = modal.clientHeight
      const parentHeight = modal.parentElement?.clientHeight ?? 0
      if (modalHeight > parentHeight) {
        modal.style.marginTop = "20rem"
        modal.style.marginBottom = "2rem"
      }
    }
  }, [modalsJsx])

  return (
    <main className="flex h-screen">
      {children}
      {modalsJsx.map((jsx, i) => (
        <div
          key={i}
          className="flex flex-col h-screen overflow-y-auto w-full justify-center items-center absolute z-[999] top-0 left-0 bg-black bg-opacity-50"
        >
          <div ref={modalRef} className="mb-10">
            {jsx}
          </div>
        </div>
      ))}
    </main>
  )
}

export default ManagementLayout
