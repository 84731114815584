import { create } from "zustand";
import { INavTab } from "../components/NavTabs";
import SeniorityIcon from "../components/Icons/SeniorityIcon";
import Typography from "../components/Typography";

interface SeniorityState {
  activeId: number;
  setActiveId: (id: number) => void;
  seniorities: INavTab[];
  setDisabled: (id: number, bool: boolean) => void;
  getSeniorityColor: () => void;
  colors: Array<{
    bgColor: string;
    countColor: string;
    activeColor: string;
    activeCountColor: string;
  }>;
}

export const useSeniority = create<SeniorityState>((set) => ({
  activeId: 1,
  getSeniorityColor() {
    switch (this.activeId) {
      case 1:
        return "senior";
        break;
      case 2:
        return "midlevel";
        break;
      case 3:
        return "junior";
        break;
      default:
        return "blue";
    }
  },
  colors: [
    {
      bgColor: "bg-[#ffdebb] text-[#df9c28]",
      countColor: "bg-[#df9c28] text-[#ffdebb]",
      activeColor: "bg-[#df9c28] text-white ",
      activeCountColor: "bg-[#FFDEBB] text-[#df9c28]",
    },
    {
      bgColor: "bg-[#ffdebb] text-[#df9c28]",
      countColor: "bg-[#df9c28] text-[#ffdebb]",
      activeColor: "bg-[#df9c28] text-white ",
      activeCountColor: "bg-[#FFDEBB] text-[#df9c28]",
    },
    {
      bgColor: "bg-[#ffdebb] text-[#df9c28]",
      countColor: "bg-[#df9c28] text-[#ffdebb]",
      activeColor: "bg-[#df9c28] text-white ",
      activeCountColor: "bg-[#FFDEBB] text-[#df9c28]",
    },
  ],
  seniorities: [
    {
      id: 1,
      icon: <SeniorityIcon color={"#E57041"} />,
      label: () => (
        <Typography tag="span" className="text-[#E57041]">
          Senior
        </Typography>
      ),
    },
    {
      id: 2,
      icon: <SeniorityIcon color={"#84ACE2"} />,
      label: () => (
        <Typography tag="span" className="text-[#84ACE2] whitespace-nowrap">
          Mid Level
        </Typography>
      ),
    },
    {
      id: 3,
      icon: <SeniorityIcon color={"#DF9C28"} />,
      label: () => (
        <Typography tag="span" className="text-[#DF9C28]">
          Junior
        </Typography>
      ),
    },
  ],
  setActiveId: (id) => set({ activeId: id }),
  setDisabled: (id, bool) =>
    set((state) => ({
      seniorities: state.seniorities.map((seniority) => {
        if (seniority.id == id) {
          return {
            ...seniority,
            disabled: bool,
          };
        }
        return seniority;
      }),
    })),
}));
