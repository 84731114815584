import { create } from "zustand";

interface ProfileState {
  username: string;
  setUsername: (username: string) => void;
  role: string;
  setRole: (role: string) => void;
  profilePhoto: string;
  setProfilePhoto: (profilePhoto: string) => void;
  userId: string;
  setUserId: (userId: string) => void;
}

export const useProfile = create<ProfileState>((set) => ({
  username: "",
  setUsername: (username: string) => set({ username }),
  role: "",
  setRole: (role: string) => set({ role }),
  profilePhoto: "",
  setProfilePhoto: (profilePhoto: string) => set({ profilePhoto }),
  userId: "",
  setUserId: (userId: string) => set({ userId }),
}));
