import { HiOutlineTrash } from "react-icons/hi"
import { FaRegEdit } from "react-icons/fa"
import EditRound from "../Icons/EditRound"

interface LeaveQuotaCardProps extends React.HTMLAttributes<HTMLDivElement> {
  quota: any
  onRemove?: () => void
  onEdit?: () => void
}

const LeaveQuotaCard = (props: LeaveQuotaCardProps) => {
  const { onRemove, onEdit, quota } = props

  return (
    <div className="px-5  h-14 border-2 border-primary rounded-2xl">
      <div className="flex justify-between items-center h-full my-auto">
        <div className="font-semibold text-black1 text-sm">
          {quota.label} <span className="text-black3">({quota.leaveDays})</span>
        </div>
        <div className="flex justify-between items-center gap-4">
          <div
            onClick={() => onEdit?.()}
            className="cursor-pointer rounded-full"
          >
            <EditRound />
            {/* <FaRegEdit className="text-white w-5 h-5 pl-0.5" /> */}
          </div>
          <div
            onClick={() => onRemove?.()}
            className="cursor-pointer p-2 bg-primary rounded-full"
          >
            <HiOutlineTrash className="text-secondary w-4 h-4" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeaveQuotaCard
