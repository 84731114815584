export default function CaretDoubleDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
    >
      <g id="CaretDoubleDown">
        <path
          id="Vector"
          d="M16.1953 8.85018C16.2492 8.90393 16.2918 8.96775 16.321 9.03801C16.3501 9.10826 16.3651 9.18357 16.3651 9.25962C16.3651 9.33567 16.3501 9.41097 16.321 9.48123C16.2918 9.55148 16.2492 9.6153 16.1953 9.66905L10.4083 15.4561C10.3546 15.5099 10.2907 15.5526 10.2205 15.5817C10.1502 15.6108 10.0749 15.6258 9.99887 15.6258C9.92282 15.6258 9.84752 15.6108 9.77727 15.5817C9.70701 15.5526 9.64319 15.5099 9.58944 15.4561L3.80241 9.66905C3.69382 9.56046 3.63281 9.41318 3.63281 9.25962C3.63281 9.10605 3.69382 8.95877 3.80241 8.85018C3.91099 8.7416 4.05827 8.68059 4.21184 8.68059C4.3654 8.68059 4.51268 8.7416 4.62127 8.85018L9.99887 14.2285L15.3765 8.85018C15.4302 8.79638 15.494 8.75369 15.5643 8.72457C15.6346 8.69545 15.7099 8.68046 15.7859 8.68046C15.862 8.68046 15.9373 8.69545 16.0075 8.72457C16.0778 8.75369 16.1416 8.79638 16.1953 8.85018ZM9.58944 9.66905C9.64319 9.72286 9.70701 9.76554 9.77727 9.79466C9.84752 9.82379 9.92282 9.83877 9.99887 9.83877C10.0749 9.83877 10.1502 9.82379 10.2205 9.79466C10.2907 9.76554 10.3546 9.72286 10.4083 9.66905L16.1953 3.88201C16.3039 3.77342 16.3649 3.62615 16.3649 3.47258C16.3649 3.31901 16.3039 3.17174 16.1953 3.06315C16.0868 2.95456 15.9395 2.89355 15.7859 2.89355C15.6323 2.89355 15.4851 2.95456 15.3765 3.06315L9.99887 8.44147L4.62127 3.06315C4.51268 2.95456 4.3654 2.89355 4.21184 2.89355C4.05827 2.89355 3.91099 2.95456 3.80241 3.06315C3.69382 3.17174 3.63281 3.31901 3.63281 3.47258C3.63281 3.62615 3.69382 3.77342 3.80241 3.88201L9.58944 9.66905Z"
          fill="#67823A"
        />
      </g>
    </svg>
  );
}
