export const TIME_ZONE = "Asia/Kolkata";
export const DISPLAY_DATE_FORMAT = "ccc, dd MMM";

export const DISPLAY_DATE_FORMAT_LONG = "ccc, MMMM yyyy";
export const API_DATE_FORMAT = "dd/MM/yyyy";
export const API_TIME_FORMAT = "h:mm a";

export const DATE_RANGE = 1;

export const CACHE_LIFE = 4 * 3600;

export const SENIORITY_COLOR_CODE = {
  "3": "#DF9C28",
  "2": "#84ACE2",
  "1": "#E57041",
};

export const BG_SENIORITY = Object.fromEntries(
  Object.entries(SENIORITY_COLOR_CODE).map(([key, value]) => {
    return [key, `bg-[${value}]`];
  })
);
