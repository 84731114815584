interface RulesCardProps {
  isChecked: boolean
  id: string | number
  name: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  label: {
    name: React.ReactNode
    content: React.ReactNode
  }
}
const RulesCard = (props: RulesCardProps) => {
  const { isChecked, name, label, id, onChange } = props

  return (
    <div
      className={`${isChecked
        ? "outer-shadow border-[3px] border-secondary"
        : "border-[3px] border-transparent  outer-shadow"
        } p-5 rounded-xl w-full flex flex-col gap-y-2`}
    >
      <div className="flex items-center">
        <input
          checked={isChecked}
          id={`checkbox-${id}`}
          onChange={onChange}
          type="checkbox"
          value=""
          className="w-5 h-5 !rounded-lg text-blue-600 bg-gray-100 border-gray-300  "
        />
        <label
          htmlFor={`checkbox-${id}`}
          className="ml-3 text-sm font-semibold text-black1 "
        >
          {name}
        </label>
      </div>
      <div className="flex items-center justify-between">
        <div className=" text-xs font-semibold text-black1 ">{label.name}</div>
        {label.content}
      </div>
    </div>
  )
}

export default RulesCard
