import { create } from "zustand"
import { ApiState, getInitialState } from "."
import { axiosApi } from "./axios"

type GetRulesByGroupId = ApiState<
  { groupId: string; seniority: number },
  any,
  any
>

const useGetRulesByGroupId = create<GetRulesByGroupId>((set) => ({
  ...getInitialState<{ groupId: string; seniority: number }, any, any>(
    {
      method: "GET",
      url: "/api/rule",
    },
    set
  ),
}))

// {
//   "seniority": 1,
//   "forGroups": ["id1", "id2"],
//   "rule": {
//       "maxHoursPerWeek": {
//           "enabled": true,
//           "value": 42
//       },
//       "minRestBetweenShifts": {
//           "enabled": false,
//           "value": 12
//       },
//       "offsPerWeek": {
//           "enabled": true,
//           "value": 2
//       }
//   }
// }
type ApplyRule = ApiState<
  {
    seniority: number
    forGroups: string[]
    rule: {
      maxHoursPerWeek: {
        enabled: boolean
        value: number
      }
      minRestBetweenShifts: {
        enabled: boolean
        value: number
      }
      offsPerWeek: {
        enabled: boolean
        value: number
      }
    }
  },
  any,
  any
>

const useApplyRule = create<ApplyRule>((set) => ({
  ...getInitialState<
    {
      seniority: number
      forGroups: string[]
      rule: {
        maxHoursPerWeek: {
          enabled: boolean
          value: number
        }
        minRestBetweenShifts: {
          enabled: boolean
          value: number
        }
        offsPerWeek: {
          enabled: boolean
          value: number
        }
      }
    },
    any,
    any
  >(
    {
      method: "POST",
      url: "/api/rule/apply",
    },
    set
  ),
}))

type GetAllRules = ApiState<any, any, any>

const useGetAllRules = create<GetAllRules>((set) => ({
  ...getInitialState<any, any, any>(
    {
      method: "GET",
      url: "/api/rule/all",
    },
    set
  ),
}))

const rules = {
  useGetRulesByGroupId,
  useApplyRule,
  useGetAllRules,
}

export default rules
