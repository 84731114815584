import { HiX } from "react-icons/hi";
import CardWithIcon from "../Cards/CardWithIcon";

interface GroupPillProp {
  name: string;
  className?: string;
  onDelete?: () => void;
  onClick?: () => void;
  selected?: boolean;
}

const Pill = (prop: GroupPillProp) => {
  const { name, onDelete, onClick, className, selected } = prop;
  return (
    <div onClick={() => onClick?.()}>
      <CardWithIcon
        className={`text-white rounded-full flex items-center py-1 justify-between px-2 font-semibold w-fit gap-1 bg-secondary ${
          className ?? ""
        } ${selected ? "border-2" : ""}`}
        name={name}
        icon={{
          content: (
            <>
              {onDelete ? (
                <button onClick={() => onDelete?.()} className="!text-white">
                  <HiX
                    size={20}
                    color="#ffffff"
                    style={{ color: "#ffffff" }}
                    className="!text-white"
                  />
                </button>
              ) : null}
            </>
          ),
          position: "right",
        }}
      />
    </div>
  );
};

export default Pill;
