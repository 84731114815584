import { useSelector } from "react-redux";

const useGlobalLoading = () => {
  const apiSlices = [
    "rosterApi",
    "organisationApi",
    "shiftsApi",
    "groupApi",
    "doctorApi",
    "locationApi",
    "requestsApi",
  ];
  return useSelector<any, boolean>((state: any) =>
    apiSlices.some((slice) =>
      Object.values(state[slice].queries).some(
        (query: any) => query.status === "pending"
      )
    )
  );
};

export default useGlobalLoading;
