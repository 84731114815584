const RoundedTick = ({ active }: { active: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className="group/tick"
    >
      <path
        d="M14.6693 7.99998C14.6693 4.31808 11.6845 1.33331 8.0026 1.33331C4.3207 1.33331 1.33594 4.31808 1.33594 7.99998C1.33594 11.6818 4.3207 14.6666 8.0026 14.6666C11.6845 14.6666 14.6693 11.6818 14.6693 7.99998Z"
        fill={active ? "#27AE60" : "#E0E0E0"}
        className={!active ? "group-hover/tick:fill-[#27AE60]" : ""}
      />
      <path
        d="M5.33594 8.33333L7.0026 10L10.6693 6"
        stroke={active ? "#FFFFFF" : "#4F4F4F"}
        className={!active ? "group-hover/tick:stroke-[#FFFFFF]" : ""}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RoundedTick;
