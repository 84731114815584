const RoundedCancelOutline = ({
  expand = false,
  size = { width: "14", height: "13" },
}: {
  expand?: boolean;
  size?: { width: string; height: string };
}) => {
  return (
    <div className="group/cancel-outline">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size.width}
        height={size.height}
        viewBox="0 0 10 10"
        fill="none"
        className={
          expand
            ? "group-hover/cancel-outline:h-[20px] group-hover/cancel-outline:w-[20px] transition-all"
            : ""
        }
      >
        <circle
          cx="5"
          cy="5"
          r="4.7"
          fill="white"
          stroke="#838383"
          stroke-width="0.6"
          className={
            "group-hover/cancel-outline:fill-[#FE7862] group-hover/cancel-outline:stroke-white"
          }
        />
        <path
          d="M6.66732 3.40137L3.40234 6.66634"
          stroke="#838383"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
          className={"group-hover/cancel-outline:stroke-white"}
        />
        <path
          d="M6.66732 6.66634L3.40234 3.40137"
          stroke="#838383"
          stroke-width="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
          className={"group-hover/cancel-outline:stroke-white"}
        />
      </svg>
    </div>
  );
};

export default RoundedCancelOutline;
