const addOrRemove = <T>(array: T[], element: T) => {
  if (array.includes(element)) {
    return array.filter((item) => item !== element);
  } else {
    array.push(element);
    return [...array];
  }
};

export default addOrRemove;
