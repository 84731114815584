import React from "react";

const SearchBox = (props: React.InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <div className="flex relative">
      <input
        {...props}
        type="search"
        className="py-2 px-5 outline-none focus:outline-none h-11 rounded-full placeholder:font-semibold w-full 3xl:text-base text-sm"
        style={{
          boxShadow: "0px 4px 16px 0px #53535333",
        }}
      />
      <div className="bg-secondary h-11 w-11 flex justify-center items-center rounded-full absolute right-0 top-0" >
        <svg
          className="3xl:w-4 3xl:h-4 w-4 h-4"
          width="48"
          height={48}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.25 12.5C10.1495 12.5 12.5 10.1495 12.5 7.25C12.5 4.35051 10.1495 2 7.25 2C4.35051 2 2 4.35051 2 7.25C2 10.1495 4.35051 12.5 7.25 12.5Z"
            stroke="#FFFEF8"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.9629 10.9624L14.0004 13.9999"
            stroke="#FFFEF8"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default SearchBox;
