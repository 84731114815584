import { AnimatePresence, motion } from "framer-motion";
import Delete from "../Icons/Delete";
import { Add } from "../Icons/Add";
import { useEstimationContext } from "../../hooks/context/useEstimationContext";
import RivetTwo from "../Icons/RivetTwo";
import InputSlot, { InputSlotMode } from "./InputSlot";
import { sortLabels } from "../../utils/sortLabels";
import { useRef, useState } from "react";
import Undo from "../Icons/Undo";

type SlotProps = {
  index: number;
  totalSlots: number;
  shiftId: string;
  slot: { schedule?: any; filter?: any; labels?: any[] };
  isLoading: boolean;
  deleteSchedule: () => Promise<void>;
  onScheduleAdd: () => Promise<void>;
  triggerOnEnter: (index: number) => void;
  mode: InputSlotMode;
  setMode: (mode: InputSlotMode) => void;
  type: "normal" | "standBy" | "onCall";
};

const Slot = ({
  index,
  totalSlots,
  slot,
  shiftId,
  isLoading,
  deleteSchedule,
  onScheduleAdd,
  mode,
  setMode,
  triggerOnEnter,
  type,
}: SlotProps) => {
  const { editShiftId } = useEstimationContext();

  const [canUndo, setCanUndo] = useState<boolean>(false);
  const timeoutRef = useRef<null | NodeJS.Timeout>(null);

  return (
    <motion.div
      key={index}
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.1 }}
      className={`group relative flex items-center justify-center ${
        index === totalSlots - 1
          ? "py-1.5"
          : "border-b-[0.4px] border-b-[#BDBDBD] py-1.5"
      }`}
    >
      <AnimatePresence>
        {mode !== "close" ? (
          <InputSlot
            setMode={setMode}
            mode={mode}
            shiftId={shiftId}
            index={index}
            onScheduleAdd={onScheduleAdd}
            triggerOnEnter={triggerOnEnter}
            schedule={slot.schedule}
            filter={slot.filter}
            labels={slot.labels}
          />
        ) : (
          <div
            className={`flex justify-center w-full items-center relative h-[30px]`}
          >
            <div className="flex gap-1 absolute left-2">
              {type === "onCall" ? (
                <div className="bg-orange4 text-white font-semibold text-xs p-1 rounded-lg">
                  OC
                </div>
              ) : (
                type === "standBy" && (
                  <div className="bg-blue5 text-white font-semibold text-xs p-1 rounded-lg">
                    SB
                  </div>
                )
              )}
              {slot.filter && (
                <div
                  className={`cursor-pointer`}
                  onClick={() => {
                    setMode("filter");
                  }}
                >
                  <RivetTwo />
                </div>
              )}
            </div>
            {slot.schedule ? (
              <div
                className={`font-medium text-sm text-black2 text-center cursor-pointer`}
                onClick={() => {
                  setMode("doctor");
                }}
              >
                {slot.schedule.doctor.user.nickName ??
                  slot.schedule.doctor.user.name}
              </div>
            ) : (
              <button
                onClick={() => {
                  setMode("doctor");
                }}
              >
                <Add stroke="stroke-black2" className="h-4 w-4" />
              </button>
            )}
            <div className="flex absolute right-0">
              <div className="grid grid-cols-3 grid-rows-2 grid-flow-col gap-[1px] w-[57px] h-[24px]">
                {sortLabels([...(slot.labels ?? [])]).map(
                  (label: {
                    shortLabel: string;
                    colorCode: string;
                    _id: string;
                  }) => (
                    <div
                      key={label._id}
                      className="row-span-1 col-span-1 font-medium text-white text-[7px] rounded-sm flex items-center justify-center leading-none"
                      style={{ backgroundColor: label.colorCode }}
                    >
                      <div>{label.shortLabel.toUpperCase()}</div>
                    </div>
                  )
                )}
              </div>
              {editShiftId === shiftId &&
                !isLoading &&
                slot.schedule &&
                (canUndo ? (
                  <div
                    className="bg-primary rounded-full h-6 w-6 flex justify-center items-center my-auto animate-pulseFast cursor-pointer"
                    onClick={() => {
                      if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                        setCanUndo(false);
                      }
                    }}
                  >
                    <Undo />
                  </div>
                ) : (
                  <div
                    className={`rounded my-auto cursor-pointer ml-1`}
                    onClick={() => {
                      setCanUndo(true);
                      timeoutRef.current = setTimeout(async () => {
                        await deleteSchedule();
                      }, 3000);
                    }}
                  >
                    <Delete
                      className="h-3.5 w-3.5 group-hover:block hidden"
                      stroke="stroke-pink1 transition duration-500"
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Slot;
