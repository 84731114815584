import React, { FC } from "react";

interface progressCard {
  shiftTiming: string;
  loacation: string;
  totalNumberSlot: string | number;
  slotFilled: string | number;
}
const ProgressCard: FC<progressCard> = ({
  totalNumberSlot,
  slotFilled,
  loacation,
  shiftTiming,
}) => {
  function calculatePercentageFilled() {
    const percentage = (Number(slotFilled) / Number(totalNumberSlot)) * 100;
    return `${percentage}%`;
  }

  return (
    <div className="w-full h-[88px] rounded-2xl relative ring-2 ring-primary overflow-hidden">
      <div
        className="h-full bg-green3  absolute"
        style={{ width: calculatePercentageFilled() }}
      ></div>
      <div className="flex justify-between absolute items-center w-full top-0 bottom-0   h-fit my-auto p-4">
        <div className="">
          <div className="gap-y-4 font-bold text-base text-black1">
            <div className="">{shiftTiming}</div>
            <div className="">{loacation}</div>
          </div>
        </div>
        <div className="text-black3 text-xs font-bold">
          {slotFilled} / {totalNumberSlot}
        </div>
      </div>
    </div>
  );
};

export default ProgressCard;
