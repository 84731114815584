import React, { FC } from 'react'
interface editroundI {
  className?: string;
  bgColor?: string;
  textColor?: string;
}
const DeleteRound: FC<editroundI> = ({ bgColor = "#D1DBAF", className, textColor = "#67823A" }) => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="16" fill={bgColor} />
        <path
          d="M21.5 11.5L10.5 11.5"
          stroke={textColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 14.5V18.5"
          stroke={textColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5 14.5V18.5"
          stroke={textColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5 11.5V21C20.5 21.1326 20.4473 21.2598 20.3536 21.3536C20.2598 21.4473 20.1326 21.5 20 21.5H12C11.8674 21.5 11.7402 21.4473 11.6464 21.3536C11.5527 21.2598 11.5 21.1326 11.5 21V11.5"
          stroke={textColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.5 11.5V10.5C18.5 10.2348 18.3946 9.98043 18.2071 9.79289C18.0196 9.60536 17.7652 9.5 17.5 9.5H14.5C14.2348 9.5 13.9804 9.60536 13.7929 9.79289C13.6054 9.98043 13.5 10.2348 13.5 10.5V11.5"
          stroke={textColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default DeleteRound