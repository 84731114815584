import React from "react";
import { useSeniority } from "../../store/seniority.state";

interface SubGroupPillProps {
  name: string;
  className?: string;
  children?: React.ReactNode;
  isActive?: boolean;
}

const SubGroupPill = (props: SubGroupPillProps) => {
  const { activeId: activeSeniorityId, colors } = useSeniority();

  const { name, className, children, isActive } = props;
  return (
    <div
      className={` rounded-full px-9 py-2  font-semibold uppercase   ${className} ${
        isActive
          ? colors[activeSeniorityId - 1].activeColor
          : colors[activeSeniorityId - 1].bgColor
      }`}
    >
      {name}
      <div
        className={`rounded-full ${
          isActive
            ? colors[activeSeniorityId - 1].activeCountColor
            : colors[activeSeniorityId - 1].countColor
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default SubGroupPill;
