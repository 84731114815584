import { FC, PropsWithChildren, createContext, useState } from "react";

type RefetchMap = Record<
  "shiftRequests" | "doctorSeniority" | "doctorSuggestion",
  Record<string, boolean>
>;

export const RefetchContext = createContext<{
  refetchMap: RefetchMap;
  setRefetchMap: React.Dispatch<React.SetStateAction<RefetchMap>>;
}>({
  refetchMap: { shiftRequests: {}, doctorSeniority: {}, doctorSuggestion: {} },
  setRefetchMap: () => undefined,
});

export const RefetchContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [refetchMap, setRefetchMap] = useState<RefetchMap>({
    shiftRequests: {},
    doctorSeniority: {},
    doctorSuggestion: {},
  });

  return (
    <RefetchContext.Provider value={{ refetchMap, setRefetchMap }}>
      {children}
    </RefetchContext.Provider>
  );
};
