const RoundedTickLarge = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <circle
        cx="13"
        cy="13"
        r="12"
        fill="white"
        stroke="white"
        stroke-width="2"
      />
      <path d="M19 10L11.6666 17L8 13.5" fill="white" />
      <path
        d="M19 10L11.6666 17L8 13.5"
        stroke="#67823A"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default RoundedTickLarge;
