
interface EfficiencyProps extends React.HTMLAttributes<HTMLDivElement> {
    height: number;
    width: number;
}

const Efficiency = (props: EfficiencyProps) => {
    const { height, width } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 10C9.38071 10 10.5 8.88071 10.5 7.5C10.5 6.11929 9.38071 5 8 5C6.61929 5 5.5 6.11929 5.5 7.5C5.5 8.88071 6.61929 10 8 10Z" stroke="#838383" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.98828 12.4609C4.36491 11.7204 4.93911 11.0986 5.6473 10.6642C6.35549 10.2299 7.17006 10 8.00083 10C8.8316 10 9.64617 10.2299 10.3544 10.6642C11.0626 11.0985 11.6368 11.7204 12.0134 12.4609" stroke="#838383" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.5 4.5C13.0523 4.5 13.5 4.05228 13.5 3.5C13.5 2.94772 13.0523 2.5 12.5 2.5C11.9477 2.5 11.5 2.94772 11.5 3.5C11.5 4.05228 11.9477 4.5 12.5 4.5Z" stroke="#838383" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.5 2.5V1.75" stroke="#838383" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.6339 3L10.9844 2.625" stroke="#838383" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.6339 4L10.9844 4.375" stroke="#838383" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.5 4.5V5.25" stroke="#838383" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.3652 4L14.0148 4.375" stroke="#838383" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.3652 3L14.0148 2.625" stroke="#838383" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.9574 7.28126C13.9858 7.51978 14.0001 7.75979 14 8C14 9.18669 13.6481 10.3467 12.9888 11.3334C12.3295 12.3201 11.3925 13.0892 10.2961 13.5433C9.19975 13.9974 7.99335 14.1162 6.82946 13.8847C5.66558 13.6532 4.59648 13.0818 3.75736 12.2426C2.91825 11.4035 2.3468 10.3344 2.11529 9.17054C1.88378 8.00666 2.0026 6.80026 2.45673 5.7039C2.91085 4.60754 3.67989 3.67047 4.66658 3.01118C5.65328 2.35189 6.81331 2 8 2C8.18773 2 8.37333 2.0085 8.55678 2.02549" stroke="#838383" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default Efficiency