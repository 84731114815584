import { FC } from "react";
import Plus from "../Icons/Plus";
import Trash from "../Icons/Trash";

export interface DoctorCardProps {
  name: string;
  doctor: any;
  doctorId: string;
  isActive: boolean;
  canAssign?: boolean;
  isFetching: boolean;
  plusClickHandler?: () => Promise<void>;
  onClickHandler: () => void;
  onDoubleClickHandler?: () => void;
  deleteHandler?: () => void;
  status?: "available" | "warning" | "unavailable";
  seniority: "3" | "2" | "1";
  className?: string;
  isDisabled?: boolean;
  assignedShiftType?: "onCall" | "standBy" | "normal" | "none";
  type:
    | "doctor-filter"
    | "doctor-filter-no-shift"
    | "user-management"
    | "group-management";
  extraLeftCardProps?: any;
}

export const borderColors = {
  "3": "border-[#DF9C28]",
  "2": "border-[#84ACE2]",
  "1": "border-[#E57041]",
};

const bgColors = {
  "3": "bg-[#DF9C28] ",
  "2": "bg-[#84ACE2] ",
  "1": "bg-[#E57041] ",
};

const bgHover = {
  "3": "hover:bg-[#DF9C28] hover:bg-opacity-50 ",
  "2": "hover:bg-[#84ACE2] hover:bg-opacity-50 ",
  "1": "hover:bg-[#E57041] hover:bg-opacity-50 ",
};

const assignedShiftsMap = {
  onCall: {
    label: "OC",
    bgColor: "bg-orange4",
    textColor: "text-orange4",
    hoverTextColor: "group-hover:text-orange4",
  },
  standBy: {
    label: "SB",
    bgColor: "bg-blue5",
    textColor: "text-blue5",
    hoverTextColor: "group-hover:text-blue5",
  },
};

// const textColors = {
//   "3": "text-[#DF9C28]",
//   "2": "text-[#84ACE2]",
//   "1": "text-[#E57041]",
// };

const activeColor = "text-white";

const DoctorCard: FC<DoctorCardProps> = (props) => {
  const {
    name,
    onClickHandler,
    onDoubleClickHandler,
    isActive,
    seniority,
    status,
    deleteHandler,
    plusClickHandler,
    canAssign,
    isFetching,
    className,
    type,
    isDisabled = false,
    assignedShiftType,
    extraLeftCardProps = {},
  } = props;

  const statusColor = {
    available: "bg-secondary",
    unavailable: "bg-[#D6764C] ",
    warning: "bg-[#F4C94E] ",
  };

  return (
    <div
      className={`relative flex h-14 shadow-1 rounded-xl group bg-white ${
        isFetching ? "animate-pulseFast" : ""
      } ${className ?? ""}`}
    >
      {isDisabled && (
        <div className=" absolute bg-black rounded-xl opacity-55 w-full h-full"></div>
      )}
      <div
        className={`${
          type === "doctor-filter" || type === "group-management"
            ? "rounded-l-xl"
            : "rounded-xl"
        } flex-grow border-l-[3px] transition-all duration-500 cursor-pointer px-2 flex justify-between group ${
          isActive ? bgColors[seniority] : bgHover[seniority]
        } ${borderColors[seniority]}`}
        onClick={onClickHandler}
        onDoubleClick={onDoubleClickHandler}
        {...extraLeftCardProps}
      >
        <div
          className={`font-bold text-base text-black1 h-full flex items-center ${
            isActive ? activeColor : ""
          }`}
        >
          {name}
        </div>
        {type === "doctor-filter" &&
          assignedShiftType &&
          ["onCall", "standBy"].includes(assignedShiftType) && (
            <div
              className={`${
                isActive
                  ? assignedShiftsMap[assignedShiftType as "onCall" | "standBy"]
                      .textColor
                  : `text-white ${
                      assignedShiftsMap[
                        assignedShiftType as "onCall" | "standBy"
                      ].hoverTextColor
                    }`
              } font-medium text-base ${
                isActive
                  ? "bg-white"
                  : `${
                      assignedShiftsMap[
                        assignedShiftType as "onCall" | "standBy"
                      ].bgColor
                    } group-hover:bg-white`
              } h-[50%] m-1 p-1 rounded-md flex items-center`}
            >
              {
                assignedShiftsMap[assignedShiftType as "onCall" | "standBy"]
                  .label
              }
            </div>
          )}
      </div>
      {type === "doctor-filter" && status && (
        <div className="w-8 grid grid-cols-1 divide-y divide-gray6 h-full shadow-1 rounded-r-xl">
          <div className="col-span-1 flex justify-center items-center ">
            <div
              className={`${statusColor[status]} bg-opacity-50 rounded-full p-0.5 w-fit`}
            >
              <div
                className={`w-2 h-2 rounded-full ${statusColor[status]} `}
              ></div>
            </div>
          </div>
          <div
            className={`col-span-1 flex justify-center items-center ${
              !canAssign || isFetching ? "bg-gray2 opacity-100 z-10" : ""
            }`}
          >
            <button
              disabled={!canAssign || isFetching}
              onClick={async () => {
                await plusClickHandler?.();
              }}
            >
              <Plus
                className="w-4 h-4 cursor-pointer"
                color={!canAssign ? "gray" : "black"}
              />
            </button>
          </div>
        </div>
      )}
      {type === "group-management" && deleteHandler && (
        <div
          className={`w-8 divide-y divide-gray6 h-full shadow-1 rounded-r-xl flex justify-center items-center ${
            isFetching ? "bg-gray2 opacity-100 z-10" : ""
          }`}
        >
          <button
            disabled={isFetching}
            onClick={async () => {
              deleteHandler?.();
            }}
          >
            <Trash />
          </button>
        </div>
      )}
    </div>
  );
};

export default DoctorCard;
