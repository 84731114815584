import { useModal } from "../../hooks/useModal";
import Button from "../Button/index";
import Typography from "../Typography/index";

interface PasswordProps {
  password: string;
}

const Password = (props: PasswordProps) => {
  const { password } = props;
  const { closeModal } = useModal();

  const close = () => {
    closeModal();
  };

  return (
    <div className="outer-shadow bg-white z-50 w-full max-w-sm px-8 py-6 mx-auto rounded-xl">
      <Typography
        tag="div"
        className="font-semibold text-base text-black3 mb-8"
      >
        Your generated password is - {password} (Please save, this cannot be
        retrieved once closed)
      </Typography>
      <div className="flex items-center justify-center gap-8">
        <Button
          className="flex justify-center text-center !rounded-xl"
          onClick={close}
        >
          Password Copied !
        </Button>
      </div>
    </div>
  );
};

export default Password;
