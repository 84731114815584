export default function UserMany() {
  return (
    <svg
      width="16"
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 11.25C9.38071 11.25 10.5 10.1307 10.5 8.75C10.5 7.36929 9.38071 6.25 8 6.25C6.61929 6.25 5.5 7.36929 5.5 8.75C5.5 10.1307 6.61929 11.25 8 11.25Z"
        stroke="#67823A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.25 7.25C12.8322 7.24952 13.4066 7.38482 13.9274 7.64516C14.4481 7.9055 14.901 8.28371 15.2501 8.74973"
        stroke="#67823A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.75 8.74987C1.09903 8.28381 1.55194 7.90557 2.07275 7.6452C2.59356 7.38483 3.16791 7.24952 3.75018 7.25"
        stroke="#67823A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.40234 13.4999C4.73079 12.8253 5.2423 12.2566 5.87847 11.8588C6.51465 11.461 7.24986 11.25 8.00019 11.25C8.75052 11.25 9.48573 11.461 10.1219 11.8588C10.7581 12.2566 11.2696 12.8253 11.598 13.4999"
        stroke="#67823A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.74963 7.25C3.37015 7.24993 2.99853 7.1419 2.67815 6.93853C2.35777 6.73516 2.10186 6.44484 1.9403 6.10147C1.77875 5.7581 1.71821 5.37586 1.76577 4.99937C1.81332 4.62289 1.967 4.2677 2.20886 3.97529C2.45072 3.68287 2.77079 3.4653 3.13167 3.34798C3.49256 3.23066 3.87937 3.21843 4.24695 3.31272C4.61452 3.40701 4.94769 3.60393 5.20754 3.88048C5.46739 4.15703 5.6432 4.5018 5.71444 4.87453"
        stroke="#67823A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2852 4.87441C10.3564 4.50162 10.5322 4.15679 10.792 3.88018C11.0519 3.60357 11.3851 3.4066 11.7527 3.31227C12.1203 3.21795 12.5072 3.23016 12.8681 3.34748C13.2291 3.4648 13.5492 3.6824 13.7911 3.97485C14.033 4.2673 14.1867 4.62253 14.2342 4.99907C14.2818 5.3756 14.2212 5.7579 14.0596 6.10131C13.898 6.44471 13.6421 6.73506 13.3216 6.93843C13.0012 7.14181 12.6295 7.24982 12.25 7.24985"
        stroke="#67823A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
