import React, { useMemo, useRef } from "react";
import { TIME_ZONE } from "../constants";
import { DateTime } from "luxon";
import useRightClick from "../hooks/useRightClick";
import useCtrlKey from "../hooks/useCtrlKey";
interface CalendarProps extends React.HTMLAttributes<HTMLDivElement> {
  month: number;
  year: number;
  dayClass?: string;
  onDayRender?: (args: {
    day: Date;
    isCtrlPressed: boolean;
    menuLoc: "right" | "left";
  }) => JSX.Element;
  onClick?: (e: any) => void;
  resetDates?: () => void;
}

const Calendar = ({
  month,
  year,
  dayClass,
  onClick,
  onDayRender,
  resetDates,
  ...props
}: CalendarProps) => {
  const calendarDays = useMemo(() => {
    const startOfMonth = DateTime.fromObject(
      { month, year, day: 1 },
      { zone: TIME_ZONE }
    );
    const daysInMonth = startOfMonth.daysInMonth;
    const days = [...Array(daysInMonth).keys()].map((v) => v + 1);

    const val = startOfMonth.weekday === 7 ? 0 : startOfMonth.weekday;

    const calendar = [...Array(daysInMonth! + val).keys()].map((v) => {
      if (v < val) {
        return null;
      }
      if (v > daysInMonth! + val - 1) {
        return null;
      }
      return days[v - val];
    });

    return calendar;
  }, [month, year]);

  const calendarRef = useRef<HTMLDivElement | null>(null);

  useRightClick(calendarRef, () => {
    resetDates?.();
  });
  const isCtrlPressed = useCtrlKey(calendarRef);

  return (
    <div
      {...props}
      className={`${
        props?.className ?? ""
      } h-full w-full p-3 gap-6 grid grid-cols-7`}
      ref={calendarRef}
    >
      {calendarDays.map((day, indx) => {
        if (day) {
          const date = DateTime.fromObject(
            { day, month, year },
            { zone: TIME_ZONE }
          ).toJSDate();

          return onDayRender ? (
            onDayRender({
              day: date,
              isCtrlPressed,
              menuLoc: indx % 7 < 5 ? "left" : "right",
            })
          ) : (
            <div
              key={indx}
              className={dayClass}
              onClick={(e: any) => onClick?.(e)}
            >
              {day}
            </div>
          );
        }
        return (
          <div key={indx} className="flex justify-center items-center">
            {day}
          </div>
        );
      })}
    </div>
  );
};

export default Calendar;
