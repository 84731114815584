import { useEffect, useRef, useState } from "react";
import deepEqual from "deep-equal";

/**
 * @description To only be used for internal states, non dom manipulation and non external comms
 * @param value
 * @param callback
 */
export const useRunOnChange = (value: any, callback: () => any) => {
  const [prevValue, setPrevValue] = useState(value);
  if (!deepEqual(value, prevValue, { strict: true })) {
    callback();
    setPrevValue(value);
  }
};

export const useRunOnChangeExt = (value: any, callback: () => any) => {
  const callbackRef = useRef<() => any>(callback);
  callbackRef.current = callback;
  useEffect(() => {
    callbackRef.current();
  }, [value]);
};
