import { useEffect, useState } from "react";
import { INavTab } from "../../components/NavTabs/index";
import { useSeniority } from "../../store/seniority.state";
import ManagementLayout from "../../layouts/Management";
import ScheduleLabelItem from "../../components/PreferencesTabItems/ScheduleLabelItem";
import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const Preferences = () => {
  const { seniorities, setDisabled } = useSeniority();
  const navigate = useNavigate();

  useEffect(() => {
    for (const seniority of seniorities) {
      setDisabled(seniority.id, false);
    }
  }, []);

  const preferencesTabs: INavTab[] = [
    {
      id: 0,
      icon: null,
      label: ({ isActive }) => (
        <div
          className={`font-medium ${
            isActive ? "text-white" : "text-black3"
          } font-base`}
        >
          Labels
        </div>
      ),
      children: <ScheduleLabelItem />,
    },
  ];

  const [activePreferenceTab, setActivePreferenceTab] = useState(
    preferencesTabs[0].id ?? 0
  );

  const handlePreferenceActiveTab = (tabId: INavTab["id"]) => {
    setActivePreferenceTab(tabId);
  };

  return (
    <ManagementLayout>
      <div className="flex w-full">
        <div className="p-5 w-[20%] relative z-[5] bg-white">
          <div className="flex">
            <div
              className="flex items-center bg-gray2 border-[1px] border-black3 cursor-pointer rounded-lg h-[30px] w-[30px]"
              onClick={() => {
                navigate(`/dashboard`);
              }}
            >
              <IoChevronBackOutline size={15} className="text-black mx-auto" />
            </div>
            <div className="my-auto ml-3 font-medium text-base text-black2">
              Back To Home
            </div>
          </div>
          <div className="my-2 text-base font-bold">
            Preferences
          </div>
          {preferencesTabs.map((tab) => (
            <div
              key={tab.id}
              className="bg-secondary p-2 rounded-md my-2"
              onClick={() => {
                handlePreferenceActiveTab(tab.id);
              }}
            >
              <tab.label isActive={tab.id === activePreferenceTab} />
            </div>
          ))}
        </div>
        <div className="w-[80%] p-4 flex-grow overflow-auto relative z-[5] bg-white">
          {preferencesTabs[activePreferenceTab].children}
        </div>
      </div>
    </ManagementLayout>
  );
};

export default Preferences;
