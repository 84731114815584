import Button from "../components/Button";
import SearchBox from "../components/Input/SearchBox";
import ManagementLayout from "../layouts/Management";
import { useEffect, useState } from "react";
import {
  useDeleteOrganisationMutation,
  useForceUpdateMyShiftsCacheMutation,
  useForceUpdateSplitByCacheMutation,
  useGetOrganisationsMutation,
} from "../store/organisationApi";
import { useModal } from "../hooks/useModal";
import CreateOrganisation from "../components/Modals/CreateOrganisation";
import Edit from "../components/Icons/Edit";
import Delete from "../components/Icons/Delete";
import EditOrganisation from "../components/Modals/EditOrganisation";
import SideBar from "../components/SuperAdminSideBar";
import SuperAdminAccordian from "../components/SuperAdminAccordian";
import { SiWorldhealthorganization } from "react-icons/si";
import { BsEye, BsPerson } from "react-icons/bs";
import OrganizationModal from "../components/Modals/OrganizationModal";
import { useProfile } from "../store/profile.state";
import { Dna } from "react-loader-spinner";
import { toast } from "react-toastify";
import useHandleSuccessErrors from "../hooks/handleSuccessErrors";

// display some statistics for an organisation in the table

const SuperAdmin = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { openModal, closeModal } = useModal();
  const { username } = useProfile();

  const [
    getOrganisations,
    {
      isLoading: isOrganisationsLoading,
      data: organisationsData,
      error: organisationsError,
      isSuccess: isOrganisationsSuccess,
    },
  ] = useGetOrganisationsMutation();

  const [
    deleteOrganisation,
    {
      isLoading: isDeleteLoading,
      data: deleteData,
      error: deleteError,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteOrganisationMutation();

  const [
    updateMyShiftsCache,
    {
      isLoading: isUpdateMyShiftsLocating,
      error: myShiftsError,
      isError: isMyShiftError,
      isSuccess: isMyShiftSuccess,
    },
  ] = useForceUpdateMyShiftsCacheMutation();

  const [
    updateSplitByCache,
    {
      isLoading: isUpdateSplitByLoading,
      error: splitByError,
      isError: isSplitByError,
      isSuccess: isSplitBySuccess,
    },
  ] = useForceUpdateSplitByCacheMutation();

  useHandleSuccessErrors({
    isSuccess: isMyShiftSuccess,
    isError: isMyShiftError,
    error: myShiftsError,
    successMessage: "My shifts cache populating.",
  });

  useHandleSuccessErrors({
    isSuccess: isSplitBySuccess,
    isError: isSplitByError,
    error: splitByError,
    successMessage: "Split by shifts cache populating.",
  });

  useEffect(() => {
    getOrganisations({});
  }, []);

  useEffect(() => {
    if (isDeleteSuccess) {
      getOrganisations({});
      toast.success("Organisation Deleted !");
    }
    if (deleteError) {
      toast.error((deleteError as any).data.message ?? "Something went wrong");
    }
  }, [isDeleteSuccess, deleteError]);

  return (
    <ManagementLayout>
      <SideBar />
      <div className="fixed p-2 outer-shadow bg-white w-[100%] mx-auto z-40 px-20 h-fit py-5 border-b">
        <div className="mx-auto flex items-center justify-between h-full max-w-screen-2xl">
          <div className="font-medium text-base text-black ml-16">
            <Button
              variant="bordered"
              className="w-min 3xl:px-6 px-3 !py-1 !rounded-full h-min whitespace-nowrap 3xl:text-base text-sm"
              onClick={() => {
                openModal(
                  <CreateOrganisation
                    onSubmit={() => {
                      getOrganisations({});
                    }}
                  />
                );
              }}
            >
              Add Organisation
            </Button>
          </div>
          <SearchBox
            placeholder={"Search Organisation"}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
          />
          <div className="font-medium text-base text-black flex items-center gap-x-2">
            <div className="font-bold border-2 rounded-full p-1.5 border-green3">
              <BsPerson className="h-5 w-5 text-green3" />
            </div>
            <div>{username}</div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-8 p-3 overflow-y-scroll w-screen m-[100px]">
        {isOrganisationsLoading ? (
          <>
            <div></div>
            <div className="flex items-center justify-center h-full">
              <Dna height={100} width={100} />
            </div>
          </>
        ) : (
          organisationsData
            ?.filter(
              (org: { name: string }) =>
                searchQuery === "" ||
                org.name.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .map((org: any, i: number) => (
              <div className="col-span-1" key={i}>
                <SuperAdminAccordian
                  className="w-full space-x-2  border-green3 overflow-hidden"
                  headerClass="space-x-4  w-full border-b  overflow-hidden"
                  title={
                    <div className=" grid grid-cols-2  w-full py-3.5  ">
                      <div className="text-base-text-black font-medium  flex  items-center justify-bet gap-2">
                        <div className="font-bold border-2 rounded-full p-1.5 border-green3">
                          <SiWorldhealthorganization className="h-6 w-6 text-green3" />
                        </div>
                        <div className="text-secondary font-semibold">
                          {org.name}
                        </div>
                      </div>
                      <div className="flex items-center justify-end gap-x-2">
                        <span
                          className="border-2 rounded-full text-xs font-semibold text-green3 border-green3 p-1.5 cursor-pointer"
                          onClick={async () =>
                            await updateSplitByCache({
                              orgId: org._id,
                              month: 2,
                              year: 2024,
                            })}
                        >
                          SB
                        </span>
                        <span
                          className="border-2 rounded-full text-xs font-semibold text-green3 border-green3 p-1.5 cursor-pointer"
                          onClick={async () =>
                            await updateMyShiftsCache({
                              orgId: org._id,
                              month: 2,
                              year: 2024,
                            })
                          }
                        >
                          MS
                        </span>
                        <span
                          className="border-2 rounded-full border-green3 p-1.5 cursor-pointer"
                          onClick={async () => {
                            openModal(
                              <OrganizationModal
                                onClose={() => closeModal()}
                                organizationName={org.name}
                                city={org.city}
                                country={org.country}
                                isActive={org.isActive}
                                orgId={org._id.toString()}
                                onCreateUserSumbit={async () => {
                                  await getOrganisations({});
                                }}
                                onToggleActiveSubmit={async () => {
                                  await getOrganisations({});
                                  closeModal();
                                }}
                              />
                            );
                          }}
                        >
                          <BsEye className={"text-secondary"} />
                        </span>
                        <span
                          className="border-2 rounded-full border-green3 p-1.5 cursor-pointer"
                          onClick={() => {
                            openModal(
                              <EditOrganisation
                                orgId={org._id.toString()}
                                initialValues={{
                                  name: org.name,
                                  city: org.city,
                                  country: org.country,
                                }}
                                onSubmit={() => {
                                  getOrganisations({});
                                }}
                              />
                            );
                          }}
                        >
                          <Edit />
                        </span>
                        <span
                          className="border-2 rounded-full border-green3 p-1.5 cursor-pointer"
                          onClick={async () => {
                            await deleteOrganisation({ orgId: org._id });
                          }}
                        >
                          <Delete />
                        </span>
                      </div>
                    </div>
                  }
                  orgId={org._id}
                  organizationName={org.name}
                  city={org.city}
                  country={org.country}
                  totalUsers={org.adminCount}
                  isActive={org.isActive}
                  toggleActiveSubmit={async () => {
                    await getOrganisations({});
                  }}
                />
              </div>
            ))
        )}
      </div>
    </ManagementLayout>
  );
};

export default SuperAdmin;
