import {
  TagDescription,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { CACHE_LIFE } from "../constants";
import { shiftsApi } from "./shiftsApi";

const API_URL = import.meta.env.VITE_API_URL;

const invalidateTagsForShifts = async (
  arg: any,
  api: {
    queryFulfilled: any;
    dispatch: (arg0: {
      payload: TagDescription<"ShiftRequests" | "ShiftsByTiming" | "Shifts">[];
      type: string;
    }) => void;
  }
) => {
  try {
    await api.queryFulfilled;
    api.dispatch(shiftsApi.util.invalidateTags(["ShiftsByTiming"]));
  } catch {
    /* empty */
  }
};

export const rosterApi = createApi({
  reducerPath: "rosterApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/api` }),
  keepUnusedDataFor: CACHE_LIFE,
  tagTypes: ["DoctorSuggestions", "DoctorSeniority"],
  endpoints: (builder) => ({
    getDoctorsSuggestion: builder.query<any, any>({
      query: ({ shiftId }) => ({
        url: `roster/${shiftId}/generate-suggestions`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
      providesTags: ["DoctorSuggestions"],
    }),
    getDoctorsFilter: builder.query<any, any>({
      query: ({ shiftId, seniority }) => ({
        url: `roster/generate-doctors?seniority=${seniority}${
          shiftId ? `&shiftId=${shiftId}` : ""
        }`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
      providesTags: ["DoctorSeniority"],
    }),
    getDoctorStats: builder.query<any, any>({
      query: ({ month, year, seniority }) => ({
        url: `roster/generate-doctor-stats?month=${month}&year=${year}&seniority=${seniority}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    assignRandomn: builder.mutation<any, any>({
      query: ({ shiftId, forceSeniority }) => ({
        url: `roster/${shiftId}/auto-assign/shift`,
        method: "POST",
        body: forceSeniority ? { forceSeniority } : undefined,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
      onQueryStarted: invalidateTagsForShifts,
    }),
    createSchedule: builder.mutation<any, any>({
      query: ({ shiftId, doctorId, slotIndex }) => ({
        url: `roster/assign`,
        method: "post",
        body: {
          shiftId,
          doctorId,
          ...(slotIndex !== undefined ? { slotIndex } : {}),
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
      onQueryStarted: invalidateTagsForShifts,
    }),
    updateSchedule: builder.mutation<any, any>({
      query: ({ scheduleId, doctorId, slotFilterId }) => ({
        url: `roster/update`,
        method: "post",
        body: {
          ...(slotFilterId ? { slotFilterId } : {}),
          scheduleId,
          doctorId,
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
      onQueryStarted: invalidateTagsForShifts,
    }),
    deleteSchedule: builder.mutation<any, any>({
      query: ({ scheduleId }) => ({
        url: `roster/remove`,
        method: "DELETE",
        body: { scheduleId },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
      onQueryStarted: invalidateTagsForShifts,
    }),
    openRequestDays: builder.mutation<
      any,
      {
        locationId: string;
        dates: string[];
        deadline?: string;
        seniority: number;
        isFinalized: boolean;
      }
    >({
      query: (body) => {
        const { locationId, ...payload } = body;
        return {
          url: `/location/${locationId}/open-request`,
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
          body: payload,
        };
      },
    }),
    autoAssignmentMonth: builder.mutation<
      any,
      {
        location: string;
        month: number;
        year: number;
        seniority: number;
      }
    >({
      query: ({ location, month, year, seniority }) => ({
        url: `/roster/auto-assign/month`,
        method: "POST",
        body: { location, month, year, seniority },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
        timeout: 500 * 1000,
      }),
      onQueryStarted: invalidateTagsForShifts,
    }),
    autoAssignmentDate: builder.mutation<
      any,
      {
        location: string;
        date: string;
        seniority: number;
        forceSeniority?: number;
      }
    >({
      query: ({ location, date, seniority, forceSeniority }) => ({
        url: `/roster/auto-assign/date`,
        method: "POST",
        body: {
          location,
          date,
          seniority,
          ...(forceSeniority ? { forceSeniority } : {}),
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
      onQueryStarted: invalidateTagsForShifts,
    }),
    importDoctors: builder.mutation<void, FormData>({
      query: (formData) => ({
        url: `/doctor/import`,
        method: "PUT",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    importShifts: builder.mutation<void, FormData>({
      query: (formData) => ({
        url: `/roster/import`,
        method: "PUT",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
      onQueryStarted: invalidateTagsForShifts,
    }),
    exportDoctors: builder.mutation<any, void>({
      query: () => ({
        url: `/doctor/export`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
        responseHandler: async (response: any) => {
          const body = await response.json();
          const url = window.URL.createObjectURL(
            new Blob([body.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const tempLinkElem = document.createElement("a");
          tempLinkElem.href = url;
          tempLinkElem.download = body.fileName;
          tempLinkElem.click();
          tempLinkElem.remove();
        },
        cache: "no-cache",
      }),
    }),
    sendEmails: builder.mutation<
      any,
      {
        emails: string[];
      }
    >({
      query: ({ emails }) => ({
        url: `/doctor/send-emails`,
        method: "POST",
        body: { emails },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    getDoctorShifts: builder.query<any, any>({
      query: ({ doctorId, month, year, locationId }) => ({
        url: `/roster/doctor?doctorId=${doctorId}&month=${month}&year=${year}${
          locationId ? "&locationId=" + locationId : ""
        }`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    changePassword: builder.mutation<
      { message: string },
      {
        currentPassword: string;
        newPassword: string;
      }
    >({
      query: ({ currentPassword, newPassword }) => ({
        url: `/auth/change-password`,
        method: "POST",
        body: { currentPassword, newPassword },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetDoctorsFilterQuery,
  useGetDoctorsSuggestionQuery,
  useGetDoctorShiftsQuery,
  useGetDoctorStatsQuery,
  useCreateScheduleMutation,
  useUpdateScheduleMutation,
  useDeleteScheduleMutation,
  useImportDoctorsMutation,
  useOpenRequestDaysMutation,
  useChangePasswordMutation,
  useAutoAssignmentMonthMutation,
  useAutoAssignmentDateMutation,
  useImportShiftsMutation,
  useExportDoctorsMutation,
  useSendEmailsMutation,
  useAssignRandomnMutation,
  usePrefetch,
} = rosterApi;
