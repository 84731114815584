import { FC } from "react";

const UserArrow: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
    >
      <path
        d="M21.6667 17.7767H15.8889V20.6656H21.6667V23.5545L26 19.2211L21.6667 14.8878V17.7767ZM11.5556 0.443359C8.37778 0.443359 5.77778 3.04336 5.77778 6.22114C5.77778 9.39891 8.37778 11.9989 11.5556 11.9989C14.7333 11.9989 17.3333 9.39891 17.3333 6.22114C17.3333 3.04336 14.7333 0.443359 11.5556 0.443359ZM11.5556 14.8878C5.2 14.8878 0 17.4878 0 20.6656V23.5545H13.7222C13.2889 22.3989 13 21.2434 13 19.9434C13 18.21 13.4333 16.6211 14.3 15.0322C13.4333 15.0322 12.5667 14.8878 11.5556 14.8878Z"
        fill="white"
      />
    </svg>
  );
};

export default UserArrow;
