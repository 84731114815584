import { FC } from "react";
type subtractProps = {
  className?: string
  stroke?: string
}
export const Minus: FC<subtractProps> = ({ className, stroke }) => {
  return (
    <>
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <g id="Frame">
          <path
            id="Vector"
            d="M5 10H15"
            className={stroke}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </>
  );
}
