import { useEffect, useRef, useState } from "react";
import { db } from "../firebase";
import { ref, onValue, update, child } from "firebase/database";
import { useProfile } from "../store/profile.state";

// Get Args -> replace admin_reload with ... add callback here
const useReloadFirebase = () => {
  const [reload, setReload] = useState(false);
  const reloadRef = useRef<null | (() => void)>(null);
  const { userId } = useProfile();

  useEffect(() => {
    const adminReloadRef = ref(db, `admin_reload`);
    reloadRef.current = () => {
      update(ref(db, "admin_reload"), { [userId]: false });
      setReload(false);
    };
    const unSubscribe = onValue(child(adminReloadRef, userId), (snapshot) => {
      const data = snapshot.val();
      if (data === true) {
        setReload(true);
      }
    });

    return () => {
      unSubscribe();
    };
  }, []);

  return {
    reload,
    setFalse: () => {
      reloadRef.current?.();
    },
  };
};

export default useReloadFirebase;
