import { MutableRefObject, useEffect, useState } from "react";

// command key in mac

const useKey = (
  ref: MutableRefObject<HTMLElement | null>,
  keys: string[]
): {
  isKeyPressed: Record<string, boolean>;
  setKeyPressed: React.Dispatch<
    React.SetStateAction<{
      [k: string]: boolean;
    }>
  >;
} => {
  const [isKeyPressed, setKeyPressed] = useState(
    Object.fromEntries(keys.map((key) => [key, false]))
  );

  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if (keys.includes(e.key)) {
        e.preventDefault();
        setKeyPressed({ ...isKeyPressed, [e.key]: true });
      }
    };

    const keyUpHandler = (e: KeyboardEvent) => {
      if (keys.includes(e.key)) {
        e.preventDefault();
        setKeyPressed({ ...isKeyPressed, [e.key]: false });
      }
    };

    ref.current?.addEventListener("keydown", keyDownHandler);
    ref.current?.addEventListener("keyup", keyUpHandler);


    const cleanUpCopy = ref.current;

    return () => {
      cleanUpCopy?.removeEventListener("keydown", keyDownHandler);
      cleanUpCopy?.removeEventListener("keyup", keyUpHandler);
    };
  }, [ref.current]);

  return { isKeyPressed, setKeyPressed };
};

export default useKey;
