import { Dispatch, SetStateAction, useRef } from "react";
import NumberSlider from "./NumberScroll";

type IosTimePickerProps = {
  time: string;
  setTime: Dispatch<SetStateAction<string>>;
};

const parseTime = (
  time: string
): { hour: number; minute: number; timeType: "am" | "pm" } => {

  const [hour, secondPart] = time.split(":");
  const [minute, timeType] = secondPart.split(" ");
  return {
    hour: Number(hour),
    minute: Number(minute),
    timeType: timeType.toLowerCase() as "am" | "pm",
  };
};

const renderTime = (hour: number, minute: number, timeType: "am" | "pm") =>
  `${hour}:${minute.toString().padStart(2, "0")} ${timeType.toUpperCase()}`;

export const IosTimePicker = ({ time, setTime }: IosTimePickerProps) => {
  const timeRef = useRef(time)
  timeRef.current = time

  return (
    <div className="w-fit gap-1 flex items-center justify-between border-2 border-secondary rounded-lg px-2">
      <NumberSlider
        id={"hour-slider"}
        min={1}
        max={12}
        number={parseTime(time).hour}
        setNumber={(number: number) => {
          setTime(
            renderTime(number, parseTime(timeRef.current).minute, parseTime(timeRef.current).timeType)
          );
        }}
      />
      <div className="flex justify-center items-center w-3 text-black font-bold text-sm">
        :
      </div>
      <NumberSlider
        id={"min-slider"}
        min={0}
        max={59}
        number={parseTime(time).minute}
        cycleList={[0, 15, 30, 45]}
        setNumber={(number: number) => {
          setTime(
            renderTime(parseTime(timeRef.current).hour, number, parseTime(timeRef.current).timeType)
          );
        }}
      />
      <button
        onClick={() =>
          setTime(
            renderTime(parseTime(time).hour, parseTime(time).minute, "am")
          )
        }
        className={`${
          parseTime(time).timeType == "am"
            ? "bg-secondary text-white"
            : "bg-gray6 text-black3"
        } h-fit rounded-[4px] font-medium text-[8px] px-1 py-[3px]`}
      >
        AM
      </button>
      <button
        onClick={() =>
          setTime(
            renderTime(parseTime(time).hour, parseTime(time).minute, "pm")
          )
        }
        className={`${
          parseTime(time).timeType == "pm"
            ? "bg-[#84ACE2] text-white"
            : "bg-gray6 text-black3 "
        } h-fit rounded-[4px] font-medium text-[8px]  px-1 py-[3px]`}
      >
        PM
      </button>
    </div>
  );
};
