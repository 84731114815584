import { useEffect, useState } from "react";
import Button from "../Button/index";
import Input from "../Input/index";
import Typography from "../Typography/index";
import { useModal } from "../../hooks/useModal";
import api from "../../store/api";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { z } from "zod";
import { useImportDoctorsMutation } from "../../store/rosterApi";
import TagInput from "../TagInput/TagInput";
import { useSeniority } from "../../store/seniority.state";
import NavTab from "../NavTabs";
import Slider from "rc-slider";

import { useUserSettingsStore } from "../../store/userSettings.state";
import { removeNullishValue } from "../../utils/stripNullishValue";
interface CreateDoctorModalProps {
  onSubmit?: (doctor: any) => void;
  initialSeniority: number;
}

export interface CreateDoctorForm {
  name: string;
  email: string;
  nickName: string;
  phoneNumber?: string;
  experience: number;
  seniority: number;
  efficiencyScore: number;
  tags?: string[];
}

const CreateDoctor = (props: CreateDoctorModalProps) => {
  const { onSubmit } = props;

  const { seniorities } = useSeniority();
  const [selectedSeniority, setSelectedSeniority] = useState<number>(
    props.initialSeniority
  );
  const settings = useUserSettingsStore();

  useEffect(() => {
    formik.setFieldValue("seniority", selectedSeniority);
  }, [selectedSeniority]);

  const { closeModal } = useModal();

  const formik = useFormik<CreateDoctorForm>({
    initialValues: {
      name: "",
      email: "",
      nickName: "",
      phoneNumber: "",
      experience: 0,
      seniority: 1,
      efficiencyScore: 0,
      tags: [],
    },
    validate: (values) => {
      const schema = z.object({
        name: z.string().nonempty("Name is required"),
        nickName: z.string().nonempty("Nick Name is required"),
        email: z.string().email("Invalid email").nonempty("Email is required"),
        phoneNumber: z.string().optional(),
        experience: z
          .number({
            required_error: "Experience is required",
            invalid_type_error: "Experience must be a number",
          })
          .min(0)
          .max(100),
        seniority: z.number({ required_error: "Seniority is required" }),
        efficiencyScore: z
          .number({
            required_error: "Efficiency score is required",
            invalid_type_error: "Efficiency score must be a number",
          })
          .min(0)
          .max(10),
        tags: z.array(z.string()),
      });

      const result = schema.safeParse(values);

      if (!result.success) {
        return Object.fromEntries(
          result.error.errors.map((err) => [err.path[0], err.message])
        );
      }
      return {};
    },
    onSubmit: async (values) => {
      const addDoc = await createDoctor({
        data: {
          ...(removeNullishValue(values) as unknown as CreateDoctorForm),
        },
      });

      const { isSuccess, data, isError, error } = addDoc.unwrap();
      if (isSuccess) {
        toast.success("Create doctor successfully");
        onSubmit?.(data);
        closeModal();
      }

      if (isError) {
        toast.error(error?.response?.data?.message ?? "Something went wrong");
      }
    },
  });

  const [
    importExcel,
    {
      isLoading: isImportExcelLoading,
      isSuccess: isImportExcelSuccess,
      isError: isImportExcelError,
      error: importExcelError,
    },
  ] = useImportDoctorsMutation();

  useEffect(() => {
    if (isImportExcelSuccess) {
      toast.success("Doctors imported successfully");
      closeModal();
    }

    if (isImportExcelError) {
      toast.error(
        (importExcelError as any)?.data?.message ?? "Something went wrong"
      );
    }
  }, [isImportExcelSuccess, isImportExcelError]);

  const { fetchFn: createDoctor, isLoading } = api.doctor.useAddDoctor();

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="outer-shadow bg-white w-full max-w-2xl px-8 py-6 mx-auto rounded-xl"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          return;
        }
        if (e.key === "Escape") {
          closeModal();
        }
      }}
    >
      <div className="">
        <Typography
          tag="div"
          className="font-semibold text-xl text-black2 mb-4"
        >
          Add New Doctor
        </Typography>
      </div>

      <div className="grid grid-cols-2 gap-x-8 gap-4 mb-8">
        <div className="col-span-2 grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <Typography
              tag="div"
              className="font-semibold text-base text-black3 mb-2"
            >
              Doctor Name
            </Typography>
            <Input
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              onBlur={formik.handleBlur}
              type="text"
              placeholder="Name"
              className="!text-base !placeholder:text-sm"
              errorMsg={formik.touched.name ? formik.errors.name : ""}
            />
          </div>
          <div className="col-span-1">
            <Typography
              tag="div"
              className="font-semibold text-base text-black3 mb-2"
            >
              Nick Name
            </Typography>
            <Input
              name="nickName"
              onChange={formik.handleChange}
              value={formik.values.nickName}
              onBlur={formik.handleBlur}
              type="text"
              placeholder="Nick Name"
              className="!text-base !placeholder:text-sm"
              errorMsg={formik.touched.nickName ? formik.errors.nickName : ""}
            />
          </div>
          <div className="col-span-1">
            <Typography
              tag="div"
              className="font-semibold text-base text-black3 mb-2"
            >
              Email
            </Typography>
            <Input
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              onBlur={formik.handleBlur}
              errorMsg={formik.touched.email ? formik.errors.email : ""}
              type="email"
              placeholder="name@guac.sg"
            />
          </div>
          <div className="col-span-1">
            <Typography
              tag="div"
              className="font-semibold text-base text-black3 mb-2"
            >
              Phone Number
            </Typography>
            <Input
              name="phoneNumber"
              onChange={formik.handleChange}
              value={formik.values.phoneNumber}
              onBlur={formik.handleBlur}
              errorMsg={
                formik.touched.phoneNumber ? formik.errors.phoneNumber : ""
              }
              type="tel"
              placeholder=""
            />
          </div>
          {settings.experience ? (
            <div className="col-span-1">
              <Typography
                tag="div"
                className="font-semibold text-base text-black3 mb-2"
              >
                Experience ({formik.values.experience} years)
              </Typography>
              <Slider
                min={0}
                max={100}
                styles={{
                  track: {
                    background: "#67823A",
                  },
                  handle: {
                    background: "#67823A",
                  },
                }}
                value={formik.values.experience}
                onChange={(val) => {
                  formik.setFieldValue("experience", val);
                }}
              />
            </div>
          ) : null}
          {settings.efficiency ? (
            <div className="col-span-1 ">
              <Typography
                tag="div"
                className="font-semibold text-base text-black3 mb-2"
              >
                Efficiency Score ({formik.values.efficiencyScore} out of 10)
              </Typography>
              <Slider
                min={0}
                max={10}
                styles={{
                  track: {
                    background: "#67823A",
                  },
                  handle: {
                    background: "#67823A",
                  },
                }}
                value={formik.values.efficiencyScore}
                onChange={(val) => {
                  formik.setFieldValue("efficiencyScore", val);
                }}
              />
            </div>
          ) : null}
          <div className="col-span-2">
            <NavTab
              tabs={seniorities}
              tabType="neumorphism"
              activeId={selectedSeniority}
              onChange={(id) => {
                setSelectedSeniority(id);
              }}
            />
          </div>
          <div className="col-span-2">
            <Typography
              tag="div"
              className="font-semibold text-base text-black3 mb-2"
            >
              Tags
            </Typography>
            <TagInput
              onChange={(tags) => {
                formik.setFieldValue("tags", tags);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-8">
        <Button
          type="button"
          disabled={isLoading || isImportExcelLoading}
          variant="bordered"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading || isImportExcelLoading}
          type="submit"
          className="flex justify-center text-center !rounded-xl"
        >
          {isLoading || isImportExcelLoading ? "Saving..." : "Save"}
        </Button>
      </div>
    </form>
  );
};

export default CreateDoctor;
