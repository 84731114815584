import { AnimatePresence, motion } from "framer-motion";
import CreateNewShiftEditShiftWindow from "./ResponsiveWindowComponents/CreateNewShiftEditShiftWindow";
import DateViewWindow from "./ResponsiveWindowComponents/DateViewWindow";
import ShiftViewWindow from "./ResponsiveWindowComponents/ShiftViewWindow";
import UserDataWindow from "./ResponsiveWindowComponents/UserDataWindow";
import { useEstimationContext } from "../../hooks/context/useEstimationContext";

const ResponsiveWindowContainer = ({
  children,
  divKey,
}: {
  children: any;
  divKey: string;
}) => {
  return (
    <motion.div
      key={divKey}
      initial={{ opacity: 0, zIndex: 0 }}
      animate={{ opacity: 1, zIndex: 10 }}
      exit={{ opacity: 0, zIndex: 0 }}
      transition={{ type: "interia", duration: 0.5 }}
      className="absolute w-full h-full"
    >
      {children}
    </motion.div>
  );
};

const getResponsiveWindow = (
  type: "createShift" | "editShift" | "dateView" | "shiftView" | "userData",
  props: any,
) => {
  switch (type) {
    case "createShift":
      return (
        <ResponsiveWindowContainer key={"createShift"} divKey={"createShift"}>
          <CreateNewShiftEditShiftWindow type={"create"} {...props} />
        </ResponsiveWindowContainer>
      );
    case "editShift":
      return (
        <ResponsiveWindowContainer key={"editShift"} divKey={"editShift"}>
          <CreateNewShiftEditShiftWindow type={"edit"} {...props} />
        </ResponsiveWindowContainer>
      );
    case "dateView":
      return (
        <ResponsiveWindowContainer key={"dateView"} divKey={"dateView"}>
          <DateViewWindow {...props} />
        </ResponsiveWindowContainer>
      );
    case "shiftView":
      return (
        <ResponsiveWindowContainer key={"shiftView"} divKey={"shiftView"}>
          <ShiftViewWindow {...props} />
        </ResponsiveWindowContainer>
      );
    case "userData":
      return (
        <ResponsiveWindowContainer
          key={"userData"}
          divKey={`userData-${JSON.stringify(props)}`}
        >
          <UserDataWindow {...props} />
        </ResponsiveWindowContainer>
      );
  }
};

const ResponsiveWindow = ({
  activeDate,
  nextDate,
  prevDate,
}: {
  activeDate: Date;
  nextDate: Date;
  prevDate: Date;
}) => {
  const { responsiveWindow } = useEstimationContext();

  const getProps = () => {
    if (responsiveWindow.type === "dateView") {
      return { activeDate, nextDate };
    }
    if (responsiveWindow.type === "userData") {
      return { activeDate };
    }
    if (responsiveWindow.type === "shiftView") {
      return { activeDate, nextDate, prevDate };
    }
    return {};
  };

  return (
    <div className="relative h-full">
      <AnimatePresence>
        {getResponsiveWindow(responsiveWindow.type, {
          ...getProps(),
          ...responsiveWindow.props,
        })}
      </AnimatePresence>
    </div>
  );
};

export default ResponsiveWindow;
