export default function EditTwo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g id="Frame">
        <path
          id="Vector"
          d="M9.85069 16.1748H16.7951M13.3229 3.44328C13.6299 3.13632 14.0462 2.96387 14.4803 2.96387C14.6953 2.96387 14.9081 3.0062 15.1067 3.08846C15.3053 3.17072 15.4857 3.29129 15.6377 3.44328C15.7897 3.59527 15.9103 3.77572 15.9925 3.9743C16.0748 4.17289 16.1171 4.38574 16.1171 4.60069C16.1171 4.81564 16.0748 5.02848 15.9925 5.22707C15.9103 5.42566 15.7897 5.6061 15.6377 5.7581L5.99267 15.4032L2.90625 16.1748L3.67785 13.0883L13.3229 3.44328Z"
          stroke="#838383"
          strokeWidth="1.54321"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
