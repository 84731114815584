interface DateCircleProps {
  variant: "primary" | "filled" | "yellow" | "trasparent";
  isDisabled?: boolean;
  name: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const DateCircle = (props: DateCircleProps) => {
  const { variant, isDisabled, name, onClick } = props;
  const variantColors = {
    primary: "bg-[#DAF7E0] border-4 border-[#27726C]  text-[#27726C]",
    filled: "bg-[#27726C] border-4 border-[#27726C] text-[#DAF7E0]",
    yellow: "bg-[#F4C94E] border-4 border-[#F4C94E] text-[#FCEDCA]",
    trasparent: "bg-[#D1DBAF] border-4 border-[#D1DBAF] text-[#838383]",
  };
  return (
    <div>
      <button
        onClick={onClick}
        disabled={isDisabled}
        className={`${
          isDisabled ? variantColors.trasparent : variantColors[variant]
        } w-14 h-14 rounded-full text-xl font-bold`}
      >
        {name}
      </button>
    </div>
  );
};

export default DateCircle;
