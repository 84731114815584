import { GrClose } from "react-icons/gr"

interface TagPillProps {
  tag: string
  index: number
  onDelete?: (index: number) => void
}

const TagPill = (props: TagPillProps) => {
  const { tag, index, onDelete } = props

  return (
    <div
      key={index}
      className="inline-block bg-gray-200 rounded-full px-3 py-1.5 text-sm font-semibold text-gray-700 mr-2"
    >
      <span className="flex items-center gap-4 ">
        {tag}
        {onDelete ? (
          <GrClose
            className="m-auto text-gray-300 cursor-pointer"
            onClick={() => onDelete(index)}
          />
        ) : null}
      </span>
    </div>
  )
}

export default TagPill
