import { useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";

import Typography from "../Typography";
import Calendar from "../Calendar";
import { useCalendar } from "../../store/calendar.state";
import { TIME_ZONE } from "../../constants";
import useRightClick from "../../hooks/useRightClick";

interface DateRangePickerProps {
  onUpdate?: (startDate: Date | null, endDate: Date | null) => void;
  disabledDates?: Date[];
}

const DateRangePicker = (props: DateRangePickerProps) => {
  const { onUpdate, disabledDates } = props;
  const { month, year } = useCalendar();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const areaRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      startDate !== null &&
      endDate !== null &&
      startDate.getTime() > endDate.getTime()
    ) {
      onUpdate?.(endDate, startDate);
      setStartDate(endDate);
      setEndDate(startDate);
    } else {
      onUpdate?.(startDate, endDate);
    }
  }, [startDate, endDate]);

  useRightClick(areaRef, () => {
    setStartDate(null);
    setEndDate(null);
  });

  return (
    <div className="flex flex-col" ref={areaRef}>
      <div className="grid grid-cols-7">
        {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((item, index) => (
          <Typography
            key={index}
            tag="span"
            className="font-semibold text-center"
          >
            {item}
          </Typography>
        ))}
      </div>
      <div>
        <Calendar
          month={month}
          year={year}
          onDayRender={({ day }) => {
            const dateTime = DateTime.fromJSDate(day).setZone(TIME_ZONE);
            const startDateTime = startDate
              ? DateTime.fromJSDate(startDate).setZone(TIME_ZONE)
              : undefined;
            const endDateTime = endDate
              ? DateTime.fromJSDate(endDate).setZone(TIME_ZONE)
              : undefined;

            const d = dateTime.toFormat("d");

            return (
              <div
                key={Math.random().toString()}
                className="w-4 h-4 text-center relative"
              >
                {startDateTime && dateTime.equals(startDateTime) && (
                  <div
                    className={`${"rounded-l-full"} absolute bg-primary -top-[4px]  ${"w-[1.6rem] left-[0px]"} h-8`}
                  ></div>
                )}
                {startDateTime &&
                  endDateTime &&
                  dateTime > startDateTime &&
                  dateTime < endDateTime && (
                    <div
                      className={`absolute bg-primary -top-[4px]  ${"w-[2.6rem] left-[-16px]"} h-8`}
                    ></div>
                  )}
                {startDateTime &&
                  endDateTime &&
                  dateTime > startDateTime &&
                  dateTime.equals(endDateTime) && (
                    <div
                      className={`${"rounded-r-full"} absolute bg-primary -top-[4px]  ${"w-[2.6rem] left-[-16px]"} h-8`}
                    ></div>
                  )}
                <div
                  className={`${
                    (startDateTime && dateTime.equals(startDateTime)) ||
                    (endDateTime && dateTime.equals(endDateTime))
                      ? "absolute z-[2] -top-[4px] left-0 w-[2rem] h-[2rem] rounded-full bg-secondary"
                      : ""
                  }`}
                  style={{
                    transform: `translateX(-${d.length > 1 ? "0.3" : "0.5"}ch)`,
                  }}
                ></div>
                <Typography
                  tag="span"
                  className={`p-1 relative z-20 h-[18px] w-[18px] font-semibold cursor-pointer ${
                    (startDateTime && dateTime.equals(startDateTime)) ||
                    (endDateTime && dateTime.equals(endDateTime))
                      ? "text-white"
                      : ""
                  } ${
                    day.getDay() === 0 ? "text-[#E57041]" : "text-secondary"
                  } ${
                    disabledDates?.find((d) =>
                      DateTime.fromJSDate(d).setZone(TIME_ZONE).equals(dateTime)
                    )
                      ? "!text-gray-400"
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      disabledDates?.find((d) =>
                        DateTime.fromJSDate(d)
                          .setZone(TIME_ZONE)
                          .equals(dateTime)
                      )
                    ) {
                      return;
                    }

                    if (startDate && endDate) {
                      setStartDate(day);
                      setEndDate(null);
                    } else if (startDateTime && !endDate) {
                      if (dateTime.equals(startDateTime)) {
                        setStartDate(null);
                      } else if (
                        dateTime.equals(startDateTime.plus({ day: -1 }))
                      ) {
                        setStartDate(day);
                        setEndDate(startDate);
                      } else {
                        setEndDate(day);
                      }
                    } else {
                      setStartDate(day);
                    }
                  }}
                >
                  {d}
                </Typography>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
