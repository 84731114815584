/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ApiState, getInitialState } from "."
import { axiosApi } from "./axios"
import { create } from "zustand"

type GetLeaveQuotaByDocId = ApiState<any, any, any>

const useGetLeaveQuotaByDoctorId = create<GetLeaveQuotaByDocId>((set) => ({
  ...getInitialState<any, any, any>({}, set),
  fetchFn: async (body) => {
    let data = null as unknown as any
    let error: unknown = null
    let isError = false
    let isLoading = false
    let isSuccess = false

    try {
      set({
        isLoading: true,
        isError: false,
        isSuccess: false,
        data: null,
        error: null,
      })
      isLoading = true
      const res = await axiosApi({
        url: `/api/leaveQuota/doctor/${body?.data?.doctorId}`,
        method: "GET",
      })
      set({ data: res.data, isSuccess: true })
      data = res.data
      isSuccess = true
    } catch (err: unknown) {
      set({ isError: true, error: err, isSuccess: false, data: null })
      isError = true
      error = err
      isSuccess = false
    } finally {
      set({ isLoading: false })
      isLoading = false
    }

    return {
      unwrap: () => {
        return { data, error, isError, isLoading, isSuccess }
      },
    }
  },
}))

type CreateLeaveQuota = ApiState<
  {
    label: string
    leaveDays: number
    subGroups: string[]
  },
  any,
  any
>

const useCreateLeaveQuota = create<CreateLeaveQuota>((set) => ({
  ...getInitialState<
    {
      label: string
      leaveDays: number
      subGroups: string[]
    },
    any,
    any
  >(
    {
      method: "POST",
      url: "/api/leaveQuota/create",
    },
    set
  ),
}))

type GetLeaveQuotaBySubGroupId = ApiState<
  {
    subGroupId: string
  },
  any,
  any
>

const useGetLeaveQuotaBySubGroupId = create<GetLeaveQuotaBySubGroupId>(
  (set) => ({
    ...getInitialState<
      {
        subGroupId: string
      },
      any,
      any
    >({}, set),
    fetchFn: async (body) => {
      let data = null as unknown as any
      let error: unknown = null
      let isError = false
      let isLoading = false
      let isSuccess = false

      try {
        set({
          isLoading: true,
          isError: false,
          isSuccess: false,
          data: null,
          error: null,
        })

        isLoading = true
        const res = await axiosApi({
          url: `/api/leaveQuota/subgroup/${body?.data?.subGroupId}`,
          method: "GET",
        })
        set({ data: res.data, isSuccess: true })
        data = res.data
        isSuccess = true
      } catch (err: unknown) {
        set({ isError: true, error: err, isSuccess: false, data: null })
        isError = true
        error = err
        isSuccess = false
      } finally {
        set({ isLoading: false })
        isLoading = false
      }

      return {
        unwrap: () => {
          return { data, error, isError, isLoading, isSuccess }
        },
      }
    },
  })
)

type DeleteLeaveQuota = ApiState<
  {
    leaveQuotaId: string
  },
  any,
  any
>

const DeleteLeaveQuota = create<DeleteLeaveQuota>((set) => ({
  ...getInitialState<
    {
      leaveQuotaId: string
    },
    any,
    any
  >({}, set),
  fetchFn: async (body) => {
    let data = null as unknown as any
    let error: unknown = null
    let isError = false
    let isLoading = false
    let isSuccess = false

    try {
      set({
        isLoading: true,
        isError: false,
        isSuccess: false,
        data: null,
        error: null,
      })

      isLoading = true

      const res = await axiosApi({
        url: `/api/leaveQuota/${body?.data?.leaveQuotaId}`,
        method: "DELETE",
      })
      set({ data: res.data, isSuccess: true })
      data = res.data
      isSuccess = true
    } catch (err: unknown) {
      set({ isError: true, error: err, isSuccess: false, data: null })
      isError = true
      error = err
      isSuccess = false
    } finally {
      set({ isLoading: false })
      isLoading = false
    }

    return {
      unwrap: () => {
        return { data, error, isError, isLoading, isSuccess }
      },
    }
  },
}))

type UpdateLeaveQuota = ApiState<
  Partial<{
    leaveQuotaId: string
    label: string
    leaveDays: number
  }>,
  any,
  any
>

const useUpdateLeaveQuota = create<UpdateLeaveQuota>((set) => ({
  ...getInitialState<
    Partial<{
      leaveQuotaId: string
      label: string
      leaveDays: number
    }>,
    any,
    any
  >({}, set),
  fetchFn: async (body) => {
    let data = null as unknown as any
    let error: unknown = null
    let isError = false
    let isLoading = false
    let isSuccess = false

    try {
      set({
        isLoading: true,
        isError: false,
        isSuccess: false,
        data: null,
        error: null,
      })

      isLoading = true

      const res = await axiosApi({
        url: `/api/leaveQuota/${body?.data?.leaveQuotaId}`,
        method: "PATCH",
        data: {
          label: body?.data?.label,
          leaveDays: body?.data?.leaveDays,
        },
      })
      set({ data: res.data, isSuccess: true })
      data = res.data
      isSuccess = true
    } catch (err: unknown) {
      set({ isError: true, error: err, isSuccess: false, data: null })
      isError = true
      error = err
      isSuccess = false
    } finally {
      set({ isLoading: false })
      isLoading = false
    }

    return {
      unwrap: () => {
        return { data, error, isError, isLoading, isSuccess }
      },
    }
  },
}))

const leaveQuota = {
  useGetLeaveQuotaByDoctorId,
  useCreateLeaveQuota,
  useGetLeaveQuotaBySubGroupId,
  DeleteLeaveQuota,
  useUpdateLeaveQuota,
}

export default leaveQuota

// ...getInitialState<any, any, any>(body, set),
// fetchFn: async (body) => {
//   let data = null as unknown as any
//   let error: any = null
//   let isError = false
//   let isLoading = false
//   let isSuccess = false

//   try {
//     set({
//       isLoading: true,
//       isError: false,
//       isSuccess: false,
//       data: null,
//       error: null,
//     })
//     isLoading = true
//     const res = await axiosApi({
//       url: `/api/leaveQuota/doctor/${body?.data?.doctorId}`,
//       method: "GET",
//     })
//     set({ data: res.data, isSuccess: true })
//     data = res.data
//     isSuccess = true
//   } catch (err: any) {
//     set({ isError: true, error: err, isSuccess: false, data: null })
//     isError = true
//     error = err
//     isSuccess = false
//   } finally {
//     set({ isLoading: false })
//     isLoading = false
//   }

//   return {
//     unwrap: () => {
//       return { data, error, isError, isLoading, isSuccess }
//     },
//   }
// },
