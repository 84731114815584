import { DateTime } from "luxon";
import { TIME_ZONE } from "../constants";

export const getDateFromHash = (dateHash: string): Date =>
  new Date(Number(window.atob(dateHash)));

export const getHashFromDate = (date: Date): string =>
  window.btoa(date.getTime().toString());

export const incrementMonth = (month: number, year: number) => {
  let newMonth = month;
  let newYear = year;
  if (month === 12) {
    newMonth = 1;
    newYear += 1;
  } else {
    newMonth += 1;
  }
  return { month: newMonth, year: newYear };
};

export const decrementMonth = (month: number, year: number) => {
  let newMonth = month;
  let newYear = year;
  if (month === 1) {
    newMonth = 12;
    newYear -= 1;
  } else {
    newMonth -= 1;
  }
  return { month: newMonth, year: newYear };
};

export function convertToAmPm(dateString: string): string {
  const date = new Date(dateString);

  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
}

export const getDateRange = (date: Date) => {
  const nextDate = DateTime.fromJSDate(date)
    .setZone(TIME_ZONE)
    .plus({ day: 1 })
    .toJSDate();
  const prevDate = DateTime.fromJSDate(date)
    .setZone(TIME_ZONE)
    .plus({ day: -1 })
    .toJSDate();

  return { prevDate, activeDate: date, nextDate };
};
