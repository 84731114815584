import React, { FC } from "react";
interface editroundI {
  className?: string;
  bgColor?: string;
  textColor?: string;
}
const EditRound: FC<editroundI> = ({ bgColor = "#D1DBAF", className, textColor = "#67823A" }) => {
  return (
    <div>
      <svg
        className={className}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="16" fill={bgColor} />
        <path
          d="M14 21.4999H11C10.8674 21.4999 10.7402 21.4472 10.6464 21.3535C10.5527 21.2597 10.5 21.1325 10.5 20.9999V18.207C10.5 18.1414 10.5129 18.0764 10.5381 18.0157C10.5632 17.955 10.6 17.8999 10.6464 17.8535L18.1464 10.3535C18.2402 10.2597 18.3674 10.207 18.5 10.207C18.6326 10.207 18.7598 10.2597 18.8536 10.3535L21.6464 13.1464C21.7402 13.2401 21.7929 13.3673 21.7929 13.4999C21.7929 13.6325 21.7402 13.7597 21.6464 13.8535L14 21.4999Z"
          stroke={textColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5 12L20 15.5"
          stroke={textColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default EditRound;
