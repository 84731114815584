const RivetTwo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M6 1.01172C5.37935 1.01139 4.77387 1.20357 4.26702 1.56178C3.76016 1.91998 3.37688 2.42656 3.17 3.01172H0V5.01172H3.17C3.3766 5.59725 3.75974 6.10429 4.2666 6.46293C4.77346 6.82157 5.37909 7.01417 6 7.01417C6.62091 7.01417 7.22654 6.82157 7.7334 6.46293C8.24026 6.10429 8.6234 5.59725 8.83 5.01172H16V3.01172H8.83C8.62312 2.42656 8.23984 1.91998 7.73298 1.56178C7.22613 1.20357 6.62065 1.01139 6 1.01172ZM5 4.01172C5 3.7465 5.10536 3.49215 5.29289 3.30461C5.48043 3.11708 5.73478 3.01172 6 3.01172C6.26522 3.01172 6.51957 3.11708 6.70711 3.30461C6.89464 3.49215 7 3.7465 7 4.01172C7 4.27694 6.89464 4.53129 6.70711 4.71883C6.51957 4.90636 6.26522 5.01172 6 5.01172C5.73478 5.01172 5.48043 4.90636 5.29289 4.71883C5.10536 4.53129 5 4.27694 5 4.01172ZM10 9.01172C9.37935 9.01139 8.77387 9.20357 8.26702 9.56178C7.76016 9.91998 7.37688 10.4266 7.17 11.0117H0V13.0117H7.17C7.3766 13.5973 7.75974 14.1043 8.2666 14.4629C8.77346 14.8216 9.37909 15.0142 10 15.0142C10.6209 15.0142 11.2265 14.8216 11.7334 14.4629C12.2403 14.1043 12.6234 13.5973 12.83 13.0117H16V11.0117H12.83C12.6231 10.4266 12.2398 9.91998 11.733 9.56178C11.2261 9.20357 10.6207 9.01139 10 9.01172ZM9 12.0117C9 11.7465 9.10536 11.4921 9.29289 11.3046C9.48043 11.1171 9.73478 11.0117 10 11.0117C10.2652 11.0117 10.5196 11.1171 10.7071 11.3046C10.8946 11.4921 11 11.7465 11 12.0117C11 12.2769 10.8946 12.5313 10.7071 12.7188C10.5196 12.9064 10.2652 13.0117 10 13.0117C9.73478 13.0117 9.48043 12.9064 9.29289 12.7188C9.10536 12.5313 9 12.2769 9 12.0117Z"
        fill="#67823A"
      />
      <circle cx="6" cy="4" r="3" fill="#E57041" />
      <circle cx="10" cy="12" r="3" fill="#E57041" />
    </svg>
  );
};

export default RivetTwo;
