import { useEffect, useState } from "react";
import { BsCalendar2 } from "react-icons/bs";
import { toast } from "react-toastify";
import { DateTime } from "luxon";

import { useOpenRequestDaysMutation } from "../../store/rosterApi";
import Cross from "../Icons/Cross";
import Input from "../Input";
import Button from "../Button";

import DateRangePicker from "../Calender/DateRangePicker";
import { useModal } from "../../hooks/useModal";
import { useSeniority } from "../../store/seniority.state";
import { useLocation } from "../../store/location.store";
import { TIME_ZONE } from "../../constants";
import { addDay, formatDateAPI } from "../../utils/formatTime";

interface FinalisedRosterReleaseProps {
  onSubmit?: () => void;
  calendarData: any;
}

const FinalisedRosterRelease = (props: FinalisedRosterReleaseProps) => {
  const { onSubmit, calendarData } = props;
  const { closeModal } = useModal();

  const { activeId: activeSeniorityId } = useSeniority();
  const { activeId: activeLocationId } = useLocation();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [
    setOpenRequestDates,
    {
      isLoading: isOpenRequestDaysLoading,
      data: openRequestDatesData,
      error: openRequestDateError,
      isSuccess: isOpenRequestDaysSuccess,
    },
  ] = useOpenRequestDaysMutation();

  useEffect(() => {
    if (openRequestDateError) {
      toast.error((openRequestDateError as any).data.message);
    } else if (isOpenRequestDaysSuccess && openRequestDatesData) {
      onSubmit?.();
      toast.success("Successfully released shift request");
      closeModal();
    }
  }, [openRequestDatesData, openRequestDateError, isOpenRequestDaysSuccess]);

  return (
    <div className="rounded-2xl bg-white p-8 w-[658px] ">
      <div className="flex justify-between items-center py-4">
        <div></div>
        <div className="text-[#303030] col-span-11 text-center text-xl font-bold leading-8">
          Finalized Roster Release
        </div>
        <button
          className="col-span-1 flex justify-end"
          onClick={() => {
            closeModal();
          }}
        >
          <Cross />
        </button>
      </div>
      <div className="grid grid-cols-12 gap-x-6">
        <div className="col-span-6">
          <div className="text-[#595959] font-bold leading-5 text-sm py-4">
            Choose Date Range
          </div>
          <div className="flex flex-col">
            <DateRangePicker
              onUpdate={(startDate, endDate) => {
                setStartDate(startDate);
                setEndDate(endDate);
              }}
              disabledDates={calendarData
                ?.filter((item: any) => item.isClosed)
                .map(
                  (v: any) =>
                    DateTime.fromISO(v.date).setZone(TIME_ZONE).toJSDate,
                )}
            />
          </div>
        </div>
        <div className="col-span-6">
          <div className="grid gap-y-5">
            <Input
              placeholder="Start Date"
              value={startDate ? formatDateAPI(startDate) : ""}
              icon={{
                content: <BsCalendar2 size={20} color="#67823A" />,
                position: "left",
              }}
              className="!rounded-xl"
            />
            <Input
              placeholder="End Date"
              value={endDate ? formatDateAPI(endDate) : ""}
              icon={{
                content: <BsCalendar2 size={20} color="#67823A" />,
                position: "left",
              }}
              className="!rounded-xl"
            />
          </div>
          <div className="py-5 flex justify-between items-center gap-x-4"></div>
        </div>
      </div>
      <div className="flex justify-center">
        <Button
          onClick={() => {
            const dates = [];
            if (startDate && endDate) {
              let currentDate = startDate;
              while (currentDate <= endDate) {
                dates.push(formatDateAPI(currentDate));
                currentDate = addDay(currentDate, 1);
              }
            } else if (startDate && !endDate) {
              dates.push(formatDateAPI(startDate));
            }
            setOpenRequestDates({
              dates,
              locationId: activeLocationId,
              seniority: activeSeniorityId,
              isFinalized: true,
            });
          }}
          variant="primary"
          className="!h-10 !w-[140px] !justify-center !rounded-2xl"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default FinalisedRosterRelease;
