import React from "react";
import { useDebounce } from "use-debounce";
import { IoEarthOutline } from "react-icons/io5";
import { MdLocationSearching } from "react-icons/md";
import { useSetOrganisationActiveInactiveMutation } from "../store/organisationApi";

interface AccordionProps {
  title?: React.ReactNode;
  content?: React.ReactNode;
  headerClass?: string;
  contentClass?: string;
  className?: string;
  organizationName: string;
  city: string;
  country: string;
  totalUsers: number;
  orgId: string;
  isActive: boolean;
  toggleActiveSubmit?: () => Promise<void>;
}
const SuperAdminAccordian: React.FC<AccordionProps> = ({
  className,
  title,
  headerClass,
  city,
  country,
  isActive,
  orgId,
  totalUsers,
  toggleActiveSubmit,
}) => {
  const [toggleOrganisationActive, { isLoading, data, error, isSuccess }] =
    useSetOrganisationActiveInactiveMutation();

  const [debouncedToggle] = useDebounce(async () => {
    await toggleOrganisationActive({ orgId });
    toggleActiveSubmit ? await toggleActiveSubmit() : undefined;
  }, 100);

  return (
    <div className={`outer-shadow  rounded-md   mr-3 ${className}`}>
      <div className={`flex justify-between items-center px-4 ${headerClass}`}>
        {title}
      </div>
      <div className="w-full relative">
        <div className=" grid grid-cols-2  pr-8 pl-4   py-4 gap-4">
          <div className="text-base border-0 h-fit py-4 gap-y-0 outer-shadow border-green3  rounded-md p-1.5 text-black font-medium  flex flex-col items-center justify-center  ">
            <div className="font-bold border-0 rounded-full mb-0.5 border-green3">
              <MdLocationSearching className="h-7 w-7 text-green3" />
            </div>
            <div className="text-secondary font-semibold">{city}</div>
          </div>
          <div className="text-base border-0 h-fit py-4 gap-y-0 outer-shadow border-green3 p-1.5 rounded-md text-black font-medium  flex flex-col items-center justify-center  ">
            <div className="font-bold border-0 rounded-full mb-0.5 border-green3">
              <IoEarthOutline className="h-7 w-7 text-green3" />
            </div>
            <div className="text-secondary font-semibold">{country}</div>
          </div>
          <div className="text-base border-0 h-fit py-4 gap-y-1 outer-shadow border-green3 p-1.5 rounded-md text-black font-medium  flex flex-col items-center justify-center  ">
            <div className="h-7 w-7 flex justify-center items-center text-sm bg-green3 rounded-full text-white">
              {totalUsers}
            </div>
            <div className="text-secondary font-semibold">Total Admins</div>
          </div>
          <div
            className="text-base-text-black font-medium space-x-2 cursor-pointer h-full w-full"
            onClick={debouncedToggle}
          >
            <div
              className={`${
                isActive ? "bg-green-500" : "bg-red-500"
              } h-full w-full rounded-lg outer-shadow text-white text-lg flex items-center justify-center font-semibold`}
              title={`${isActive}?"Active":"In Active"`}
            >
              {isActive ? "Active" : "In Active"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SuperAdminAccordian;
