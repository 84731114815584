interface ExperienceProps extends React.HTMLAttributes<HTMLDivElement> {
    height: number;
    width: number;
}

const Experience = (props: ExperienceProps) => {
    const { height, width } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 6L9 2L16.5 6L9 10L1.5 6Z" stroke="#838383" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.75 15V8L9 6" stroke="#838383" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.75 6.93311V10.3407C14.7502 10.4485 14.7153 10.5535 14.6507 10.6398C14.2296 11.2001 12.4533 13.2498 9 13.2498C5.54669 13.2498 3.77039 11.2001 3.34935 10.6398C3.28466 10.5535 3.24979 10.4485 3.25 10.3407V6.93311" stroke="#838383" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default Experience