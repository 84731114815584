import { DateTime } from "luxon";
import { TIME_ZONE } from "../constants";

export const generateDates = (date: Date, offset: number) => {
  return Array(offset * 2 + 1)
    .fill(0)
    .map((_, index) =>
      DateTime.fromJSDate(date)
        .setZone(TIME_ZONE)
        .plus({ day: -1 * offset + index })
        .toJSDate(),
    );
};
