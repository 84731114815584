import { FC } from "react";

const ClockTwo: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.25"
      height="12.25"
      viewBox="0 0 17.5 18.5"
      fill="none"
      version="1.1"
      id="svg2"
    >
      <path
        d="m 8.75,16.75 c 4.4183,0 8,-3.5817 8,-7.9999998 C 16.75,4.33172 13.1683,0.75 8.75,0.75 c -4.41828,0 -8,3.58172 -8,8.0000002 C 0.75,13.1683 4.33172,16.75 8.75,16.75 Z"
        stroke="#595959"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        id="path1"
      />
      <path
        d="M 8.75,4.08398 V 8.7507 h 4.6667"
        stroke="#595959"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        id="path2"
      />
    </svg>
  );
};

export default ClockTwo;
