import React from "react";

const RoundedRightArrowButton = ({
  className,
  isActive,
}: {
  className: string;
  isActive: boolean;
}) => {
  return (
    <svg
      width="24"
      className={className}
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="24"
        height="24"
        rx="6"
        fill="white"
        fillOpacity={isActive ? "1" : "0.3"}
      />
      <path
        d="M9.75 6.375L15.375 12L9.75 17.625"
        stroke="#67823A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RoundedRightArrowButton;
